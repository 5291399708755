import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Table, Row, Col, Card, CardBody } from "reactstrap"

import "../senders/datatables.scss"
import accessToken from "../../helpers/jwt-token-access/accessToken"
import { Redirect } from "react-router-dom"

import ReactPaginate from "react-paginate"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import baseURL from "../../utils"
import { FaArrowDown, FaArrowUp } from "react-icons/fa"

const ActivityLogs = () => {
  const [activityLogs, setActivityLogs] = useState([])
  const [loading, setLoading] = useState(true)
  const [redirect, setRedirect] = useState("")
  const [next, setNext] = useState(1)
  const [lastPage, setLastPage] = useState({})
  const [perPage, setPerPage] = useState(50)
  const [sort, setSort] = useState("desc")


  const handlePageClick = data => {
    setNext(data.selected + 1)
  }

  // Fetch activity logs
  useEffect(() => {
    const fetchActivityLogs = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/activity-logs?perPage=${perPage}&page=${next}&sort=${sort}`,
          options
        )
        const activityLogs = await response.json()
        console.log(activityLogs.data.activity_logs)
        setActivityLogs(activityLogs.data.activity_logs)

        setLoading(false)
        setLastPage(activityLogs.data.meta)
      } catch (error) {
        setLoading(false)
        toast.error(error.message)
      }
    }

    fetchActivityLogs()
  }, [perPage, next, sort])

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Activity Logs | Dilivva Admin</title>
        </MetaTags>
        <Row className="mx-1 my-4">
          <Col md={6}>
            <h4 className="dashboard-head">Activity Logs</h4>
          </Col>
        </Row>
        <div className="container-fluid">
          <ToastContainer />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {loading ? (
                    <div className="container">
                      <div className="row">
                        <div className="col-md-2 mb-5 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <Row className="mt-2">
                        <div className="d-flex justify-content-end align-items-center gap-3 w-full">
                          <Col md={1} xs={4}>
                            <select
                              className="form-select form-select-sm"
                              aria-label=".form-select-sm example"
                              value={perPage}
                              onChange={e => {
                                setLoading(true)
                                setPerPage(e.target.value)
                              }}
                            >
                              <option defaultValue>50</option>
                              <option defaultValue="25">25</option>
                              <option defaultValue="10">10</option>
                              <option defaultValue="5">5</option>
                            </select>
                          </Col>
                          <Col md={1} xs={4}>
                            <FaArrowUp
                              className="sort"
                              onClick={() => setSort("asc")}
                            />{" "}
                            <FaArrowDown
                              className="sort"
                              onClick={() => setSort("desc")}
                            />{" "}
                            Sort
                          </Col>
                        </div>
                      </Row>
                      <div className="table-responsive">
                        <Table className="table table-hover mt-2 mb-0">
                          <thead>
                            <tr>
                              <th>Causer</th>
                              <th>Description</th>
                              <th>Created</th>
                              <th>Roles</th>
                            </tr>
                          </thead>
                          <tbody>
                            {activityLogs?.map(
                              ({ causer, description, created_at, id }) => (
                                <tr
                                  key={id}
                                  onClick={() =>
                                    setRedirect(`activity-logs/${id}`)
                                  }
                                  className="table-row"
                                >
                                  <td>
                                    {causer?.full_name}
                                    <br />
                                    <span className="text-green">
                                      {causer?.email}
                                    </span>
                                  </td>

                                  <td>{description}</td>
                                  <td>{created_at}</td>
                                  <td
                                    className={`${
                                      causer?.roles[0].name === "admin"
                                        ? "text-green"
                                        : causer?.roles[0].name ===
                                          "super-admin"
                                        ? "text-warning"
                                        : ""
                                    }`}
                                  >
                                    {(causer?.roles[0].name).toUpperCase()}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      </div>
                      <div className="float-end mt-3">
                        <ReactPaginate
                          previousLabel={"Prev"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={Math.ceil(
                            lastPage?.total / lastPage?.per_page
                          )}
                          marginPagesDisplayed={4}
                          pageRangeDisplayed={6}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ActivityLogs
