import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Table, Row, Col, Card, CardBody } from "reactstrap"
import "../senders/Users.css"

import "../senders/datatables.scss"
import accessToken from "../../helpers/jwt-token-access/accessToken"
import { Redirect } from "react-router-dom"
import "../senders/UserProfile.css"

import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Map from "./Map"
import OrderMap from "./OrderMap"
import baseURL from "../../utils"

const Tracking = () => {
  const [loading, setLoading] = useState(true)
  const [track, setTrack] = useState([])
  const [redirect, setRedirect] = useState("")
  const [partnerLocation, setPartnerLocation] = useState([])
  const [orderLocation, setOrderLocation] = useState([])
  const [pendingOrders, setPendingOrders] = useState([])

  // Fetch partner location
  const fetchPartnerLocation = async () => {
    try {
      const options = {
        method: "GET",
        headers: new Headers({ Authorization: accessToken }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/partners/live-locations`,
        options
      )
      const partnerLocation = await response.json()
      console.log(partnerLocation.data)
      console.log(partnerLocation.data.partner_locations)
      setLoading(false)
      setPartnerLocation(partnerLocation.data.partner_locations)
      // let partnerId = partnerLocation.data.partner_locations?.map(
      //   ({ id }) => id
      // )
      // console.log(partnerId, "partnerId")
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }
  useEffect(() => {
    fetchPartnerLocation()
  }, [])

  // Fetch order in transit location
  const fetchOrderLocation = async () => {
    try {
      const options = {
        method: "GET",
        headers: new Headers({ Authorization: accessToken }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/orders?status=transit`,
        options
      )
      const orderLocation = await response.json()
      setLoading(false)
      setOrderLocation(orderLocation.data.orders)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchOrderLocation()
  }, [])

  // Fetch pending order location
  const fetchPendingOrders = async () => {
    try {
      const options = {
        method: "GET",
        headers: new Headers({ Authorization: accessToken }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/orders?status=pending`,
        options
      )
      const pendingOrders = await response.json()
      setLoading(false)
      console.log(pendingOrders.data, "pending")
      setPendingOrders(pendingOrders.data.orders)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchPendingOrders()
  }, [])

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <React.Fragment>
      =
      <div className="page-content">
        <MetaTags>
          <title>Tracking | Dilivva Admin</title>
        </MetaTags>
        <Row className="mx-5 mb-2">
          <Col md={6}>
            <h4 className="dashboard-head">Tracking</h4>
          </Col>
          {/* <Col md={6}>
            <input
              type="text"
              placeholder="Search sender by name or email address"
              value={search}
              onChange={e => setSearch(e.target.value)}
              onKeyUp={() => searchUser()}
            />
            <div className="list-group rounded">
              {searchResult.map(search => {
                const { full_name, uuid } = search
                return (
                  <button
                    type="button"
                    className="list-group-item list-group-item-action"
                    onClick={() => setRedirect("/user/" + uuid)}
                  >
                    {full_name}
                  </button>
                )
              })}
            </div>
          </Col> */}
        </Row>
        <div className="container-fluid">
          <ToastContainer />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {loading ? (
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12 skeleton skeleton-search py-5"></div>
                      </div>
                    </div>
                  ) : (
                    <div className="">
                      <Row className="mt-2">
                        <Col md={9}>
                          {/* <h4 className="title-header">Track</h4> */}
                        </Col>
                      </Row>
                      <Row>
                        <ul className="nav nav-pills mb-3 mx-4">
                          <li>
                            <a
                              className="nav-link active"
                              data-bs-toggle="pill"
                              href="#tab1"
                            >
                              Pending Packages
                            </a>
                          </li>
                          <li>
                            <a
                              className="nav-link"
                              data-bs-toggle="pill"
                              href="#tab2"
                            >
                              Orders in transit
                            </a>
                          </li>
                        </ul>
                        <div className="tab-content">
                          <div className="tab-pane fade show active" id="tab1">
                            <Row>
                              <Col lg={12}>
                                <Map
                                  partnerLocation={partnerLocation}
                                  pendingOrders={pendingOrders}
                                />
                              </Col>
                            </Row>
                          </div>

                          <div className="tab-pane fade show" id="tab2">
                            <Row>
                              <OrderMap orderLocation={orderLocation} />
                            </Row>
                          </div>
                        </div>
                        <Col></Col>
                      </Row>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Tracking
