import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import {
  FaHome,
  FaSyncAlt,
  FaUsers,
  FaMoneyCheck,
  FaBell,
  FaArrowCircleDown,
  FaBox,
  FaUser,
  FaBuilding,
} from "react-icons/fa"
import { PiPlugsConnectedFill } from "react-icons/pi"
import { MdCorporateFare } from "react-icons/md"
import { BsFillPeopleFill } from "react-icons/bs"
import { IoPricetagsOutline } from "react-icons/io5"
import { LuActivity } from "react-icons/lu"
import { MdLocationPin } from "react-icons/md"
import { TbBuildingArch } from "react-icons/tb"

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/dashboard" className="waves-effect">
                <FaHome style={{ marginRight: "10px" }} />
                <span>{props.t("DASHBOARD")}</span>
              </Link>
            </li>
            <li>
              <Link to="/all-orders">
                <FaMoneyCheck style={{ marginRight: "10px" }} />
                <span>{props.t("ORDERS")}</span>
              </Link>
            </li>
            <li>
              <Link to="/centers">
                <FaBuilding style={{ marginRight: "10px" }} />
                <span>{props.t("CENTERS")}</span>
              </Link>
            </li>
            <li>
              <Link to="/hubs">
                <TbBuildingArch style={{ marginRight: "10px" }} />
                <span>{props.t("HUBS")}</span>
              </Link>
            </li>
            <li>
              <Link to="/senders">
                <FaUser style={{ marginRight: "10px" }} />
                <span>{props.t("SENDERS")}</span>
              </Link>
            </li>
            <li>
              <Link to="/partners">
                <BsFillPeopleFill style={{ marginRight: "10px" }} />
                <span>{props.t("PARTNERS")}</span>
              </Link>
            </li>
            <li>
              <Link to="/sweepers">
                <FaUsers style={{ marginRight: "10px" }} />
                <span>{props.t("SWEEPERS")}</span>
              </Link>
            </li>
            <li>
              <Link to="/corporates">
                <MdCorporateFare style={{ marginRight: "10px" }} />
                <span>{props.t("CORPORATE")}</span>
              </Link>
            </li>
            <li>
              <Link to="/couriers">
                <FaBox style={{ marginRight: "10px" }} />
                <span>{props.t("COURIERS")}</span>
              </Link>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <FaSyncAlt style={{ marginRight: "10px" }} />
                <span>{props.t("TRANSACTIONS")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/all-Transactions">
                    {props.t("All Transactions")}
                  </Link>
                </li>
                <li>
                  <Link to="/refund">{props.t("Refund")}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/suggested-locations">
                <MdLocationPin style={{ marginRight: "10px" }} />
                <span>{props.t("LOCATIONS")}</span>
              </Link>
            </li>
            <li>
              <Link to="/tiers">
                <FaBuilding style={{ marginRight: "10px" }} />
                <span>{props.t("TIER")}</span>
              </Link>
            </li>
            <li>
              <Link to="/connection-fee">
                <PiPlugsConnectedFill style={{ marginRight: "10px" }} />
                <span>{props.t("CONNECTION FEE")}</span>
              </Link>
            </li>
            <li>
              <Link to="/determinant">
                <IoPricetagsOutline style={{ marginRight: "10px" }} />
                <span>{props.t("DETERMINANT")}</span>
              </Link>
            </li>
            <li>
              <Link to="/activity-logs">
                <LuActivity style={{ marginRight: "10px" }} />
                <span>{props.t("ACTIVITY LOGS")}</span>
              </Link>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <FaBell style={{ marginRight: "10px" }} />
                <span>{props.t("NOTIFICATIONS")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/push-notifications">
                    {props.t("Push Notifications")}
                  </Link>
                </li>
                <li>
                  <Link to="/emails">{props.t("Emails")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <FaBox style={{ marginRight: "10px" }} />
                <span>{props.t("DILIVVA STORE")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/pending-verifications">
                    {props.t("Pending Verifications")}
                  </Link>
                </li>
                <li>
                  <Link to="/bulk-delivery">{props.t("Dilivva Store")}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/track">
                <FaArrowCircleDown style={{ marginRight: "10px" }} />
                <span>{props.t("TRACK")}</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
