import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Row, Col, Card, CardBody } from "reactstrap"

import accessToken from "../../helpers/jwt-token-access/accessToken"

import "react-toastify/dist/ReactToastify.css"
import { ToastContainer, toast } from "react-toastify"
import baseURL from "../../utils"

const Emails = () => {
  const [loading, setLoading] = useState(false)
  const [subject, setSubject] = useState("")
  const [body, setBody] = useState("")
  const [role, setRole] = useState("")
  const [all, setAll] = useState(0)
  const [showSelect, setShowSelect] = useState(false)
  const [recipients, setRecipeints] = useState([])
  const [search, setSearch] = useState("")
  const [searchUsers, setSearchUsers] = useState([])
  const [searchPartners, setSearchPartners] = useState([])
  const [disableButton, setDisableButton] = useState(true)

  // Search Users
  const searchUser = async () => {
    console.log("searching a user")
    try {
      const options = {
        method: "POST",
        headers: new Headers({
          Authorization: accessToken,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          search,
        }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/users/search`,
        options
      )
      const data = await response.json()
      setSearchUsers(data["data"]["users"])
      if (data.status == "success") {
      } else if (data.status == "error") {
        toast.error(data["message"])
      }
    } catch (error) {
      // do what
    }
  }

  // Search Partners
  const searchPartner = async () => {
    console.log("searching a partner")
    try {
      const options = {
        method: "POST",
        headers: new Headers({
          Authorization: accessToken,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          search,
        }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/partners/search`,
        options
      )
      const data = await response.json()
      console.log(data["data"])
      setSearchPartners(data["data"]["partners"])
      if (data.status == "success") {
      } else if (data.status == "error") {
        toast.error(data["message"])
      }
    } catch (error) {
      // do what
    }
  }

  // Send Emails
  const sendEmails = async e => {
    e.preventDefault()
    setLoading(true)
    // do stuff
    try {
      const options = {
        method: "POST",
        headers: new Headers({
          Authorization: accessToken,
          accept: "application/json",
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          subject,
          body,
          role,
          recipients,
          all,
        }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/broadcast/email`,
        options
      )
      const data = await response.json()
      if (data.status == "success") {
        toast.success(data["message"])
        setDisableButton(true)
        setLoading(false)
        setTimeout(() => {
          location.reload(1)
        }, 5000)
      } else if (data.status == "error") {
        toast.error(data["message"])
        setDisableButton(false)
        setLoading(false)
      }
    } catch (error) {
      // do what
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Emails | Dilivva Admin</title>
        </MetaTags>
        <ToastContainer />
        <Row className="mx-1 my-4">
          <Col md={6}>
            <h4 className="dashboard-head">Emails</h4>
          </Col>
        </Row>
        <Card>
          <CardBody>
            <Col>
              <h4 className="profile-head">Send Emails</h4>
              <Row>
                <Col md={6}>
                  <form onSubmit={sendEmails}>
                    <div className="mb-3">
                      <input
                        type="text"
                        placeholder="Subject"
                        required
                        value={subject}
                        onChange={e => setSubject(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <textarea
                        className="form-control"
                        rows={5}
                        type="textarea"
                        placeholder="Body"
                        required
                        value={body}
                        onChange={e => setBody(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <select
                        className="form-control"
                        value={role}
                        onChange={e => setRole(e.target.value)}
                      >
                        <option defaultValue>--Select--</option>
                        <option value="user">User</option>
                        <option value="partner">Partner</option>
                      </select>
                    </div>
                    {role === "user" && (
                      <Col className="my-3">
                        {!showSelect && (
                          <input
                            type="text"
                            placeholder="Search sender by name or email address"
                            value={search}
                            onChange={e => setSearch(e.target.value)}
                            onKeyUp={() => searchUser()}
                          />
                        )}
                        <div className="my-3">
                          <input
                            type="checkbox"
                            value={all}
                            onChange={e => {
                              setAll(1)
                              setRecipeints(["1"])
                              setShowSelect(!showSelect)
                              setDisableButton(false)
                            }}
                          />{" "}
                          send to all
                        </div>
                        {!showSelect && (
                          <div className="list-group rounded">
                            {searchUsers.map(search => {
                              const { full_name, uuid } = search
                              return (
                                <button
                                  type="button"
                                  className="list-group-item list-group-item-action"
                                  key={uuid}
                                >
                                  <input
                                    type="checkbox"
                                    value={uuid}
                                    onChange={e => {
                                      let new_recipient = recipients
                                      new_recipient.push(e.target.value)
                                      setRecipeints(new_recipient)
                                      setDisableButton(false)
                                    }}
                                  />
                                  {full_name}
                                </button>
                              )
                            })}
                          </div>
                        )}
                      </Col>
                    )}
                    {role === "partner" && (
                      <Col className="my-3">
                        {!showSelect && (
                          <input
                            type="text"
                            placeholder="Search partner by name or email address"
                            value={search}
                            onChange={e => setSearch(e.target.value)}
                            onKeyUp={() => searchPartner()}
                          />
                        )}
                        <div className="my-3">
                          <input
                            type="checkbox"
                            value={all}
                            onChange={e => {
                              setAll(1)
                              setRecipeints(["1"])
                              setShowSelect(!showSelect)
                              setDisableButton(false)
                            }}
                          />{" "}
                          send to all
                        </div>
                        {!showSelect && (
                          <div className="list-group rounded">
                            {searchPartners.map(search => {
                              const { first_name, last_name, uuid } = search
                              return (
                                <button
                                  type="button"
                                  className="list-group-item list-group-item-action"
                                  key={uuid}
                                >
                                  <input
                                    type="checkbox"
                                    value={uuid}
                                    onChange={e => {
                                      let new_recipient = recipients
                                      new_recipient.push(e.target.value)
                                      setRecipeints(new_recipient)
                                      setDisableButton(false)
                                    }}
                                  />
                                  <span>
                                    {first_name} {last_name}
                                  </span>
                                </button>
                              )
                            })}
                          </div>
                        )}
                      </Col>
                    )}
                    <div className="float-end">
                      <button
                        type="submit"
                        disabled={disableButton}
                        className="send"
                      >
                        Send{" "}
                        {loading && (
                          <div
                            className="spinner-border text-light mx-3"
                            style={{
                              width: "20px",
                              height: "20px",
                            }}
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        )}
                      </button>
                    </div>
                  </form>
                </Col>
              </Row>
            </Col>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}

export default Emails
