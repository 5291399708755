import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Table, Row, Col, Card, CardBody } from "reactstrap"

import accessToken from "../../helpers/jwt-token-access/accessToken"

import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import ReactPaginate from "react-paginate"
import "./Partner.css"
import { Link, Redirect } from "react-router-dom"
import {
  FaArrowDown,
  FaArrowLeft,
  FaArrowUp,
  FaChevronLeft,
} from "react-icons/fa"
import baseURL from "../../utils"

const PartnerDetails = props => {
  const [profile, setProfile] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address: "",
    delivery_method_id: "",
    state_id: "",
    local_government_id: "",
    photo: "",
    date_of_birth: "",
    signature: "",
  })
  const [loading, setLoading] = useState(true)
  const [transactions, setTransactions] = useState([])
  const [orders, setOrders] = useState([])
  const [cancellations, setCancellations] = useState([])
  const [next, setNext] = useState(1)
  const [page, setPage] = useState({})
  const [subject, setSubject] = useState("")
  const [body, setBody] = useState("")
  const [attachment, setAttachment] = useState([])
  const [disableButton, setDisableButton] = useState(false)
  const [buttonLoadEmail, setbuttonLoadEmail] = useState(false)
  const [comment, setComment] = useState("")
  const [expire, setExpire] = useState("")
  const [buttonLoadBan, setbuttonLoadBan] = useState(false)
  const [buttonLoad, setButtonLoad] = useState(false)
  const [buttonLoadUnban, setButtonLoadUnban] = useState(false)
  const [singleOrder, setSingleOrder] = useState({})
  const [redirect, setRedirect] = useState("")
  const [reason, setReason] = useState("reason")
  const [perPage, setPerPage] = useState(50)
  const [sort, setSort] = useState("asc")

  const handlePageClick = data => {
    setNext(data.selected + 1)
  }

  const showModal = singleOrder => {
    setSingleOrder(singleOrder)
  }

  // Partner Profile
  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/partners/${props?.match?.params?.id}`,
          options
        )
        const profile = await response.json()
        console.log(profile.data)
        setLoading(false)
        setProfile(profile.data)
      } catch (error) {
        console.log("error", error)
        toast.error(error.message)
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  // Partner Transactions
  useEffect(() => {
    const fetchTransaction = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/partners/${props?.match?.params?.id}/transactions?page=${next}&perPage=${perPage}&sort=${sort}`,
          options
        )
        const transactions = await response.json()
        console.log(transactions.data.meta)
        setLoading(false)
        setTransactions(transactions.data.transactions)
        setPage(transactions.data.meta)
      } catch (error) {
        console.log("error", error)
      }
    }

    fetchTransaction()
  }, [next, perPage, sort])

  // Partner Orders
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/partners/${props?.match?.params?.id}/orders?page=${next}&perPage=${perPage}&sort=${sort}`,
          options
        )
        const orders = await response.json()
        console.log(orders.data)
        setLoading(false)
        setOrders(orders.data.orders)
        setPage(orders.data.meta)
      } catch (error) {
        console.log("error", error)
      }
    }

    fetchOrders()
  }, [next, perPage, sort])

  // Partnerllations
  useEffect(() => {
    const fetchCancellations = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/partners/${props?.match?.params?.id}/cancellations`,
          options
        )
        const cancellations = await response.json()
        console.log(cancellations.data.cancellations, "cancellations")
        setLoading(false)
        setCancellations(cancellations.data.cancellations)
      } catch (error) {
        console.log("error", error)
      }
    }

    fetchCancellations()
  }, [next])

  // Ban Partner
  const banPartner = async e => {
    e.preventDefault()
    // do stuff
    setbuttonLoadBan(true)
    setDisableButton(true)
    try {
      const options = {
        method: "POST",
        headers: new Headers({
          Authorization: accessToken,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          comment,
          expired_at: expire,
        }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/partners/${props?.match?.params?.id}/ban`,
        options
      )
      const data = await response.json()
      if (data.status === "success") {
        toast.success(data["message"])
        setbuttonLoadBan(false)
        setTimeout(() => {
          location.reload()
        }, 5000)
      } else if (data.status === "error") {
        toast.error(data["message"])
        setbuttonLoadBan(false)
        setDisableButton(false)
      }
    } catch (error) {
      // do what
    }
  }

  // Unban Partner
  const unbanPartner = async () => {
    // do stuff
    setButtonLoadUnban(true)
    setDisableButton(true)
    try {
      const options = {
        method: "POST",
        headers: new Headers({
          Authorization: accessToken,
          "Content-Type": "application/json",
        }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/partners/${props?.match?.params?.id}/unban`,
        options
      )
      const data = await response.json()
      console.log(data)
      console.log(data.message)
      if (data.status === "success") {
        toast.success(data["message"])
        setButtonLoadUnban(false)
        setTimeout(() => {
          location.reload()
        }, 5000)
      } else if (data.status === "error") {
        toast.error(data["message"])
        setButtonLoadUnban(false)
        setDisableButton(false)
      }
    } catch (error) {
      // do what
    }
  }

  // Send Email
  const sendEmail = async e => {
    e.preventDefault()
    // do stuff
    setbuttonLoadEmail(true)
    setDisableButton(true)
    const formData = new FormData()
    formData.append("body", body)
    formData.append("subject", subject)
    formData.append("attachments", attachment)
    console.log(formData, "data inputed")

    const options = {
      method: "POST",
      headers: new Headers({
        Authorization: accessToken,
        // "Content-Type": "multipart/form-data",
      }),
    }
    const response = await fetch(
      `${baseURL}/api/v1/admin/partners/${props?.match?.params?.id}/send-email`,
      formData,
      options
    )
    const data = await response.json()
    console.log(data)
    if (data.status === "success") {
      toast.success(data["message"])
      setbuttonLoadEmail(false)
      setTimeout(() => {
        location.reload()
      }, 5000)
    } else if (data.status === "error") {
      toast.error(data["message"])
      setbuttonLoadEmail(false)
      setDisableButton(false)
    }
  }

  // Verify Partner
  const verifyPartner = async verification_status => {
    // do stuff
    setButtonLoad(true)
    setDisableButton(true)
    try {
      const options = {
        method: "PUT",
        headers: new Headers({
          Authorization: accessToken,
          accept: "application/json",
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          verification_status,
          reason,
        }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/partners/${props?.match?.params?.id}/update-verification`,
        options
      )
      const data = await response.json()
      console.log(data)
      console.log(data.message)
      setButtonLoad(false)
      setDisableButton(false)
      if (data.status === "success") {
        toast.success(data["message"])
        setButtonLoad(false)
        setDisableButton(true)
        setTimeout(() => {
          location.reload(1)
        }, 5000)
      } else if (data.status === "error") {
        toast.error(data["message"])
        setButtonLoad(false)
        setDisableButton(false)
      } else {
        toast.warning(data.message)
        setButtonLoad(false)
        setDisableButton(false)
      }
    } catch (error) {
      // do what
    }
  }

  // Update Partner
  const updatePartner = async () => {
    // do stuff
    try {
      const options = {
        method: "PUT",
        headers: new Headers({
          Authorization: accessToken,
          accept: "application/json",
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          first_name: profile.first_name,
          last_name: profile.last_name,
          email: profile.email,
          phone: profile.phone,
          address: profile.address,
          date_of_birth: profile.date_of_birth,
          delivery_method_id: profile?.delivery_method?.uuid,
          state_id: "31",
          local_government_id: "651",
          photo: profile?.signature,
          signature: profile?.signature,
        }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/partners/${props?.match?.params?.id}`,
        options
      )
      const data = await response.json()
      console.log(data)
      console.log(data.message)
      if (data.status === "success") {
        toast.success(data["message"])
        setbuttonLoadBan(false)
        setTimeout(() => {
          location.reload(1)
        }, 5000)
      } else if (data.status === "error") {
        toast.error(data["message"])
        setbuttonLoadBan(false)
        setDisableButton(false)
      }
    } catch (error) {
      // do what
    }
  }

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Partner Details | Dilivva Admin</title>
        </MetaTags>
        <div className="container-fluid">
          <ToastContainer draggable={false} />
          <div
            className="mb-4 d-flex justify-content-center align-items-center"
            style={{
              gap: "4px",
              padding: "7px 18px 7px 12px",
              width: "76px",
              borderRadius: "32px",
              border: "1px solid #F0F0F0",
              background: "#FFF",
              boxShadow: "1px 1px 4px 0px rgba(0, 0, 0, 0.04)",
              marginBottom: "8px",
            }}
          >
            <Link
              to="/partners"
              className="text-black d-flex align-items-center"
            >
              <FaChevronLeft size={16} />
              <div
                style={{
                  color: "#000",
                  fontSize: "14px",
                  fontWeight: "600",
                  marginLeft: "4px",
                }}
              >
                Back
              </div>
            </Link>
          </div>
          <h2 className="profile-head mb-4">Partner Details</h2>
          {loading ? (
            <Row className="d-flex justify-content-center">
              <Col lg={11}>
                <Row>
                  <Col>
                    <Card className="profile-card">
                      <CardBody>
                        <Col md={12}>
                          <Row className="">
                            <Col md={3}>
                              <div className="skel">
                                <div className="col-md-8 skeleton skeleton-circle"></div>
                              </div>
                            </Col>
                            <Col md={7} className="mx-3">
                              <div className="col-md-8 skeleton skeleton-head"></div>
                              <div className="col-md-10 skeleton skeleton-p"></div>
                              <div className="col-md-7 skeleton skeleton-p"></div>
                              <div className="col-md-6 skeleton skeleton-p"></div>
                            </Col>
                          </Row>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="profile-wallet">
                      <CardBody>
                        <Col md={12}>
                          <Row className="p-2">
                            <Col>
                              <div className="col-md-2 skeleton skeleton-p mb-4"></div>
                              <div className="col-md-4 skeleton skeleton-head p-3"></div>
                            </Col>
                          </Row>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card className="profile-card">
                      <CardBody>
                        <Row>
                          <Col md={9}>
                            <div className="col-md-12 skeleton skeleton-p"></div>
                            <div className="col-md-6 skeleton skeleton-p"></div>
                            <div className="col-md-6 skeleton skeleton-p"></div>
                            <div className="col-md-5 skeleton skeleton-p"></div>
                            <div className="price-head">
                              {profile?.updated_at}
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div className="skel">
                              <div className="col-md-3 skeleton skeleton-circle"></div>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="profile-wallet">
                      <CardBody>
                        <Col>
                          <Row className="p-2">
                            <Col lg={8}>
                              <div className="col-md-7 skeleton skeleton-p"></div>
                              <div className="skel">
                                <div className="col-md-8 skeleton skeleton-circle"></div>
                              </div>
                            </Col>
                            <Col lg={3}>
                              <div className="col-md-12 skeleton skeleton-head py-3 px-5"></div>
                            </Col>
                          </Row>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <Row className="d-flex justify-content-center">
              <Col lg={11}>
                <Row>
                  <Col>
                    <Card className="profile-card">
                      <CardBody>
                        <Col md={12}>
                          <Row className="">
                            <Col md={3}>
                              {profile.photo ? (
                                <div className="profile">
                                  <img
                                    className="img-fluid"
                                    src={profile?.photo}
                                    alt="profile"
                                  />
                                </div>
                              ) : (
                                <img
                                  src="https://cdn.iconscout.com/icon/free/png-256/avatar-370-456322.png"
                                  className="img-fluid"
                                  alt={profile?.first_name}
                                />
                              )}
                            </Col>
                            <Col md={7} className="mx-3">
                              <h3 className="profile-name">
                                {profile?.first_name} {profile?.last_name}
                              </h3>
                              <h6 className="details">{profile?.email}</h6>
                              <h6 className="details">{profile?.phone}</h6>
                              <h6 className="details">{profile?.age} years</h6>
                            </Col>
                            <Col>
                              <div className="d-flex justify-content-end mt-4">
                                <div className="dropdown">
                                  <a
                                    className="dropdown-toggle change"
                                    href="#"
                                    role="button"
                                    id="dropdownMenuLink"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    disabled={disableButton}
                                  >
                                    Verification Status
                                    {buttonLoad && (
                                      <div
                                        className="spinner-border text-light mx-3"
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                        }}
                                        role="status"
                                      >
                                        <span className="visually-hidden">
                                          Loading...
                                        </span>
                                      </div>
                                    )}
                                  </a>

                                  <ul
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuLink"
                                  >
                                    <li>
                                      <a
                                        className="dropdown-item text-green"
                                        onClick={() => {
                                          verifyPartner("approved")
                                        }}
                                      >
                                        {profile?.verification_status !==
                                        "approved"
                                          ? "Approve"
                                          : ""}
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item text-red"
                                        data-bs-toggle="modal"
                                        data-bs-target="#exampleModal3"
                                      >
                                        {profile?.verification_status !==
                                        "rejected"
                                          ? "Reject"
                                          : ""}
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div
                                className="modal fade"
                                id="exampleModal3"
                                tabIndex="-1"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true"
                              >
                                <div className="modal-dialog">
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <h1
                                        className="modal-title fs-5"
                                        id="exampleModalLabel"
                                      >
                                        Modal title
                                      </h1>
                                      <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                    <div className="modal-body">
                                      <textarea
                                        type="text"
                                        className="form-control"
                                        placeholder="Add reason for rejection"
                                        value={reason}
                                        onChange={e =>
                                          setReason(e.target.value)
                                        }
                                      />
                                    </div>
                                    <div className="modal-footer">
                                      <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                      >
                                        Close
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-danger"
                                        onClick={() => {
                                          verifyPartner("rejected")
                                        }}
                                      >
                                        Reject
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="profile-wallet">
                      <CardBody>
                        <Col md={12}>
                          <Row className="p-2">
                            <Col>
                              <p className="wallet">Wallet</p>
                              <h2 className="balance">
                                ₦ {profile?.wallet?.balance}
                              </h2>
                            </Col>
                          </Row>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card className="profile-card">
                      <CardBody>
                        <Row>
                          <Col md={9}>
                            <h6 className="details">{profile?.address}</h6>
                            <h6 className="details">
                              {profile?.local_government}, {profile?.state}
                            </h6>
                            <h6
                              className="price-head"
                              style={{
                                color:
                                  profile?.verification_status === "approved"
                                    ? "#059669"
                                    : profile?.verification_status === "pending"
                                    ? "#FEC400"
                                    : profile?.verification_status ===
                                      "rejected"
                                    ? "#DC1515"
                                    : "#031736",
                              }}
                            >
                              {profile?.verification_status
                                ? profile?.verification_status
                                : "null"}
                            </h6>
                            <p>
                              {profile?.device_type} {profile?.device_version}
                            </p>
                            <div className="price-head">
                              {profile?.updated_at}
                            </div>
                          </Col>
                          <Col lg={3}>
                            {profile?.signature ? (
                              <img
                                src={profile?.signature}
                                className="img-fluid signature"
                                alt="signature"
                              />
                            ) : (
                              "No Signature"
                            )}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="profile-wallet">
                      <CardBody>
                        <Col>
                          <Row className="p-2">
                            <Col lg={8}>
                              <p className="wallet">
                                Delivery Method -{" "}
                                {profile?.delivery_method?.name}{" "}
                              </p>
                              <img
                                src={profile?.delivery_method?.image}
                                className="img-fluid"
                                alt="signature"
                                width="100"
                              />
                            </Col>
                          </Row>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <button
                  className="send"
                  onClick={() => setRedirect(`/onboard/${profile?.uuid}`)}
                >
                  view onboarding
                </button>
                <div className="float-end mb-3">
                  <button
                    className="change"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Update Partner
                  </button>
                  <div
                    className="modal fade"
                    id="exampleModal"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">
                            Update Partner Details
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className="container">
                            <div className="row">
                              <div className="col-md-6 mb-4">
                                <span>First Name</span>
                                <input
                                  type="text"
                                  value={profile.first_name}
                                  onChange={e =>
                                    setProfile({
                                      ...profile,
                                      first_name: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div className="col-md-6 mb-4">
                                <span>Last Name</span>
                                <input
                                  type="text"
                                  value={profile.last_name}
                                  onChange={e =>
                                    setProfile({
                                      ...profile,
                                      last_name: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div className="col-md-6 mb-4">
                                <span>Email</span>
                                <input
                                  type="text"
                                  value={profile.email}
                                  onChange={e =>
                                    setProfile({
                                      ...profile,
                                      email: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div className="col-md-6 mb-4">
                                <span>Phone</span>
                                <input
                                  type="text"
                                  value={profile.phone}
                                  onChange={e =>
                                    setProfile({
                                      ...profile,
                                      phone: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div className="col-md-6 mb-4">
                                <span>Address</span>
                                <input
                                  type="text"
                                  value={profile.address}
                                  onChange={e =>
                                    setProfile({
                                      ...profile,
                                      address: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div className="col-md-6 mb-4">
                                <span>Date of Birth</span>
                                <input
                                  type="text"
                                  value={profile.date_of_birth}
                                  onChange={e =>
                                    setProfile({
                                      ...profile,
                                      date_of_birth: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                          <button
                            type="submit"
                            className="send"
                            onClick={() => updatePartner()}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <Col>
                    <div className="row feture-tabs" data-aos="fade-up">
                      <div className="col-lg-12">
                        <ul className="nav nav-pills mb-3">
                          <li>
                            <a
                              className="nav-link active"
                              data-bs-toggle="pill"
                              href="#tab1"
                            >
                              Transaction History
                            </a>
                          </li>
                          <li>
                            <a
                              className="nav-link"
                              data-bs-toggle="pill"
                              href="#tab2"
                            >
                              Orders
                            </a>
                          </li>
                          <li>
                            <a
                              className="nav-link"
                              data-bs-toggle="pill"
                              href="#tab3"
                            >
                              Cancellation
                            </a>
                          </li>
                        </ul>
                        <div className="tab-content">
                          <div className="tab-pane fade show active" id="tab1">
                            <div className="container-fluid">
                              <Row>
                                <Col lg={12}>
                                  {loading ? (
                                    <div className="container">
                                      <div className="row">
                                        <div className="col-md-12 skeleton skeleton-search"></div>
                                        <div className="col-md-12 skeleton skeleton-search"></div>
                                        <div className="col-md-12 skeleton skeleton-search"></div>
                                        <div className="col-md-12 skeleton skeleton-search"></div>
                                        <div className="col-md-12 skeleton skeleton-search"></div>
                                        <div className="col-md-12 skeleton skeleton-search"></div>
                                        <div className="col-md-12 skeleton skeleton-search"></div>
                                        <div className="col-md-12 skeleton skeleton-search"></div>
                                        <div className="col-md-12 skeleton skeleton-search"></div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="table-responsive">
                                      {transactions.length !== 0 ? (
                                        <>
                                          <Row className="float-end">
                                            <Col>
                                              <select
                                                className="form-select form-select-sm"
                                                aria-label=".form-select-sm example"
                                                value={perPage}
                                                onChange={e => {
                                                  setLoading(true)
                                                  setPerPage(e.target.value)
                                                }}
                                              >
                                                <option defaultValue>50</option>
                                                <option defaultValue="25">
                                                  25
                                                </option>
                                                <option defaultValue="10">
                                                  10
                                                </option>
                                                <option defaultValue="5">
                                                  5
                                                </option>
                                              </select>
                                            </Col>
                                            <Col>
                                              <FaArrowUp
                                                className="sort"
                                                onClick={() => setSort("asc")}
                                              />{" "}
                                              <FaArrowDown
                                                className="sort"
                                                onClick={() => setSort("desc")}
                                              />{" "}
                                              Sort
                                            </Col>
                                          </Row>
                                          <Table className="table responsive table-hover mt-2 mb-0">
                                            <thead>
                                              <tr>
                                                <th>Amount</th>
                                                <th>Description</th>
                                                <th>Reference</th>
                                                <th>Type</th>
                                                <th>Date</th>
                                                <th>Status</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {transactions?.map(
                                                transaction => {
                                                  const {
                                                    uuid,
                                                    amount,
                                                    created_at,
                                                    description,
                                                    status,
                                                    type,
                                                    reference,
                                                    wallet,
                                                  } = transaction
                                                  return (
                                                    <tr
                                                      key={uuid}
                                                      onClick={() =>
                                                        setRedirect(
                                                          `../transaction/${uuid}`
                                                        )
                                                      }
                                                    >
                                                      <td>₦{amount}</td>
                                                      <td>{description}</td>
                                                      <td>{reference}</td>
                                                      <td
                                                        style={{
                                                          color:
                                                            type === "credit"
                                                              ? "#29CC97"
                                                              : type === "debit"
                                                              ? "#DC1515"
                                                              : "#FEC400",
                                                        }}
                                                      >
                                                        {type}
                                                      </td>
                                                      <td>{created_at}</td>
                                                      <td
                                                        style={{
                                                          color:
                                                            status === "success"
                                                              ? "#29CC97"
                                                              : status ===
                                                                "pending"
                                                              ? "#FEC400"
                                                              : "#DC1515",
                                                        }}
                                                      >
                                                        {status}
                                                      </td>
                                                    </tr>
                                                  )
                                                }
                                              )}
                                            </tbody>
                                          </Table>
                                          <div className="float-end mt-3">
                                            <ReactPaginate
                                              previousLabel={"Prev"}
                                              nextLabel={"Next"}
                                              breakLabel={"..."}
                                              pageCount={Math.ceil(
                                                page.last_page
                                              )}
                                              marginPagesDisplayed={4}
                                              pageRangeDisplayed={6}
                                              onPageChange={handlePageClick}
                                              containerClassName={"pagination"}
                                              pageClassName={"page-item"}
                                              pageLinkClassName={"page-link"}
                                              previousClassName={"page-item"}
                                              previousLinkClassName={
                                                "page-link"
                                              }
                                              nextClassName={"page-item"}
                                              nextLinkClassName={"page-link"}
                                              breakClassName={"page-item"}
                                              breakLinkClassName={"page-link"}
                                              activeClassName={"active"}
                                            />
                                          </div>
                                        </>
                                      ) : (
                                        <h5>No Transactions</h5>
                                      )}
                                    </div>
                                  )}
                                </Col>
                              </Row>
                            </div>
                          </div>

                          <div className="tab-pane fade show" id="tab2">
                            <Row>
                              <Col lg={12}>
                                <div className="table-responsive">
                                  {orders.length !== 0 ? (
                                    <>
                                      <Row className="float-end">
                                        <Col>
                                          <select
                                            className="form-select form-select-sm"
                                            aria-label=".form-select-sm example"
                                            value={perPage}
                                            onChange={e => {
                                              setLoading(true)
                                              setPerPage(e.target.value)
                                            }}
                                          >
                                            <option defaultValue>50</option>
                                            <option defaultValue="25">
                                              25
                                            </option>
                                            <option defaultValue="10">
                                              10
                                            </option>
                                            <option defaultValue="5">5</option>
                                          </select>
                                        </Col>
                                        <Col>
                                          <FaArrowUp
                                            className="sort"
                                            onClick={() => setSort("asc")}
                                          />{" "}
                                          <FaArrowDown
                                            className="sort"
                                            onClick={() => setSort("desc")}
                                          />{" "}
                                          Sort
                                        </Col>
                                      </Row>
                                      <Table className="table responsive table-hover mt-2 mb-0">
                                        <thead>
                                          <tr>
                                            <th>Date Created</th>
                                            <th>Pickup</th>
                                            <th>Reference</th>
                                            <th>Destination</th>
                                            <th>Recepient</th>
                                            <th>Price</th>
                                            <th>Status</th>
                                            <th>View</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {orders?.map(order => {
                                            const {
                                              uuid,
                                              pickup_address,
                                              created_at,
                                              destination_address,
                                              status,
                                              price,
                                              reference,
                                              recipient,
                                              transactions,
                                              partner,
                                            } = order
                                            return (
                                              <tr key={uuid}>
                                                <td>{created_at}</td>
                                                <td>{pickup_address}</td>
                                                <td>{reference}</td>
                                                <td>{destination_address}</td>
                                                <td>{recipient?.name}</td>
                                                <td>₦{price}</td>
                                                <td
                                                  style={{
                                                    color:
                                                      status === "completed"
                                                        ? "#29CC97"
                                                        : status === "cancelled"
                                                        ? "#DC1515"
                                                        : status === "accepted"
                                                        ? "greenyellow"
                                                        : "#FEC400",
                                                  }}
                                                >
                                                  {status}
                                                </td>
                                                <td>
                                                  <button
                                                    type="button"
                                                    className="badge rounded-pill bg-success border-0 p-2"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#exampleModal2"
                                                    onClick={() => {
                                                      showModal(order)
                                                    }}
                                                  >
                                                    View
                                                  </button>
                                                  <div
                                                    className="modal fade"
                                                    id="exampleModal2"
                                                    tabIndex="-1"
                                                    aria-labelledby="exampleModalLabel"
                                                    aria-hidden="true"
                                                  >
                                                    <div className="modal-dialog modal-dialog-scrollable modal-xl">
                                                      <div className="modal-content">
                                                        <div className="modal-header">
                                                          <h5
                                                            className="modal-title"
                                                            id="exampleModalLabel"
                                                          >
                                                            Order Details
                                                          </h5>
                                                          <button
                                                            type="button"
                                                            className="btn-close"
                                                            data-bs-dismiss="modal"
                                                            aria-label="Close"
                                                          ></button>
                                                        </div>
                                                        <div className="modal-body">
                                                          <div className="container">
                                                            {singleOrder?.partner && (
                                                              <div className="row mb-3">
                                                                <h4 className="profile-name mb-3">
                                                                  Partner
                                                                </h4>
                                                                <div className="col-md-6">
                                                                  <div className="card">
                                                                    <div className="card-body">
                                                                      <div className="profile-img">
                                                                        <img
                                                                          className="img-fluid mb-4"
                                                                          src={
                                                                            singleOrder
                                                                              ?.partner
                                                                              ?.photo
                                                                          }
                                                                          alt={
                                                                            singleOrder
                                                                              ?.partner
                                                                              ?.first_name
                                                                          }
                                                                        />
                                                                      </div>
                                                                      <div>
                                                                        <h3 className="profile-name">
                                                                          {
                                                                            singleOrder
                                                                              ?.partner
                                                                              ?.first_name
                                                                          }{" "}
                                                                          {
                                                                            singleOrder
                                                                              ?.partner
                                                                              ?.last_name
                                                                          }
                                                                        </h3>
                                                                      </div>
                                                                      <h6 className="details">
                                                                        {
                                                                          singleOrder
                                                                            ?.partner
                                                                            ?.email
                                                                        }
                                                                      </h6>
                                                                      <h6 className="details">
                                                                        {
                                                                          singleOrder
                                                                            ?.partner
                                                                            ?.phone
                                                                        }
                                                                      </h6>
                                                                      <h6 className="details">
                                                                        {
                                                                          singleOrder
                                                                            ?.partner
                                                                            ?.date_of_birth
                                                                        }
                                                                      </h6>
                                                                      <div className="details">
                                                                        {
                                                                          singleOrder
                                                                            ?.partner
                                                                            ?.age
                                                                        }{" "}
                                                                        years
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                  <div className="card">
                                                                    <div className="card-body">
                                                                      <div className="details">
                                                                        {
                                                                          singleOrder
                                                                            ?.partner
                                                                            ?.address
                                                                        }
                                                                      </div>
                                                                      <div className="details">
                                                                        {
                                                                          singleOrder
                                                                            ?.partner
                                                                            ?.local_government
                                                                        }
                                                                        ,{" "}
                                                                        {
                                                                          singleOrder
                                                                            ?.partner
                                                                            ?.state
                                                                        }
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  <div className="card">
                                                                    <div className="card-body">
                                                                      <img
                                                                        className="img-fluid sign-img mb-4"
                                                                        src={
                                                                          singleOrder
                                                                            ?.partner
                                                                            ?.signature
                                                                        }
                                                                        alt={
                                                                          singleOrder
                                                                            ?.partner
                                                                            ?.first_name
                                                                        }
                                                                      />
                                                                      <h6
                                                                        className="details"
                                                                        style={{
                                                                          color:
                                                                            "#059669",
                                                                        }}
                                                                      >
                                                                        {
                                                                          singleOrder
                                                                            ?.partner
                                                                            ?.verification_status
                                                                        }
                                                                      </h6>
                                                                      <div className="price-head">
                                                                        {
                                                                          singleOrder
                                                                            ?.partner
                                                                            ?.created_at
                                                                        }
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  <div className="card">
                                                                    <div className="card-body">
                                                                      <span className="price-head">
                                                                        Partner
                                                                        Price
                                                                      </span>
                                                                      <h6 className="partner-price mb-2">
                                                                        ₦
                                                                        {
                                                                          singleOrder?.partner_price
                                                                        }
                                                                      </h6>
                                                                      <span className="price-head">
                                                                        Wallet
                                                                        Amount
                                                                      </span>
                                                                      <div
                                                                        className="details"
                                                                        style={{
                                                                          color:
                                                                            "#059669",
                                                                        }}
                                                                      >
                                                                        <h4>
                                                                          ₦
                                                                          {
                                                                            singleOrder
                                                                              ?.partner
                                                                              ?.wallet
                                                                              ?.balance
                                                                          }
                                                                        </h4>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            )}
                                                            <div className="row">
                                                              <h4 className="profile-name mb-4">
                                                                Package
                                                              </h4>
                                                              <div className="container">
                                                                <div className="card">
                                                                  <div className="card-body">
                                                                    <div className="row">
                                                                      <div className="col">
                                                                        <span className="price-head">
                                                                          Label
                                                                        </span>
                                                                        <h6 className="details">
                                                                          {
                                                                            singleOrder
                                                                              ?.package
                                                                              ?.label
                                                                          }
                                                                        </h6>
                                                                        <span className="price-head">
                                                                          Note
                                                                        </span>
                                                                        <h6 className="details">
                                                                          {
                                                                            singleOrder
                                                                              ?.package
                                                                              ?.note
                                                                          }
                                                                        </h6>
                                                                        <span className="price-head">
                                                                          Fragile
                                                                        </span>
                                                                        <h6 className="details">
                                                                          {singleOrder
                                                                            ?.package
                                                                            ?.is_fragile ===
                                                                          0
                                                                            ? "No"
                                                                            : "Yes"}
                                                                        </h6>
                                                                      </div>
                                                                      <div className="col">
                                                                        <span className="price-head">
                                                                          Size
                                                                        </span>
                                                                        <h6 className="details">
                                                                          {
                                                                            singleOrder
                                                                              ?.package
                                                                              ?.size
                                                                          }
                                                                        </h6>
                                                                        <span className="price-head">
                                                                          Reference
                                                                        </span>
                                                                        <h6 className="details">
                                                                          {
                                                                            singleOrder
                                                                              ?.package
                                                                              ?.reference
                                                                          }
                                                                        </h6>
                                                                        <span className="price-head">
                                                                          Date
                                                                          Created
                                                                        </span>
                                                                        <h6 className="details">
                                                                          {
                                                                            singleOrder
                                                                              ?.package
                                                                              ?.created_at
                                                                          }
                                                                        </h6>
                                                                        <span className="price-head">
                                                                          Date
                                                                          Created
                                                                        </span>
                                                                        <h6
                                                                          className="details"
                                                                          style={{
                                                                            color:
                                                                              status ===
                                                                              "completed"
                                                                                ? "#29CC97"
                                                                                : status ===
                                                                                  "cancelled"
                                                                                ? "#DC1515"
                                                                                : "#FEC400",
                                                                          }}
                                                                        >
                                                                          {
                                                                            singleOrder?.status
                                                                          }
                                                                        </h6>
                                                                      </div>
                                                                    </div>
                                                                    <div className="row my-4">
                                                                      <div className="col mb-2">
                                                                        <img
                                                                          className="img-fluid package-img"
                                                                          src={
                                                                            singleOrder
                                                                              ?.package
                                                                              ?.images[0]
                                                                          }
                                                                          width={
                                                                            70
                                                                          }
                                                                        />
                                                                      </div>
                                                                      <div className="col mb-2">
                                                                        <img
                                                                          className="img-fluid package-img"
                                                                          src={
                                                                            singleOrder
                                                                              ?.package
                                                                              ?.images[1]
                                                                          }
                                                                          width={
                                                                            70
                                                                          }
                                                                        />
                                                                        <img
                                                                          className="img-fluid package-img"
                                                                          src={
                                                                            singleOrder
                                                                              ?.package
                                                                              ?.images[2]
                                                                          }
                                                                          width={
                                                                            70
                                                                          }
                                                                        />
                                                                      </div>
                                                                      <div className="col mb-2">
                                                                        <img
                                                                          className="img-fluid package-img"
                                                                          src={
                                                                            singleOrder
                                                                              ?.package
                                                                              ?.images[3]
                                                                          }
                                                                          width={
                                                                            70
                                                                          }
                                                                        />
                                                                        <img
                                                                          className="img-fluid package-img"
                                                                          src={
                                                                            singleOrder
                                                                              ?.package
                                                                              ?.images[4]
                                                                          }
                                                                          width={
                                                                            70
                                                                          }
                                                                        />
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div className="row">
                                                              <h4 className="profile-name mb-4">
                                                                Recepient
                                                              </h4>
                                                              <div className="container">
                                                                <div className="card">
                                                                  <div className="card-body">
                                                                    <div className="row">
                                                                      <div className="col">
                                                                        <span className="price-head">
                                                                          Name
                                                                        </span>
                                                                        <h6 className="details">
                                                                          {
                                                                            singleOrder
                                                                              ?.recipient
                                                                              ?.name
                                                                          }
                                                                        </h6>
                                                                      </div>
                                                                      <div className="col">
                                                                        <span className="price-head">
                                                                          Email
                                                                        </span>
                                                                        <h6 className="details">
                                                                          {
                                                                            singleOrder
                                                                              ?.recipient
                                                                              ?.email
                                                                          }
                                                                        </h6>
                                                                      </div>
                                                                      <div className="col">
                                                                        <span className="price-head">
                                                                          Phone
                                                                        </span>
                                                                        <h6 className="details">
                                                                          {
                                                                            singleOrder
                                                                              ?.recipient
                                                                              ?.phone
                                                                          }
                                                                        </h6>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div className="row">
                                                              <h4 className="profile-name mb-4">
                                                                Transactions
                                                              </h4>
                                                              <div className="container">
                                                                <div className="card">
                                                                  <div className="card-body">
                                                                    <div className="table-responsive">
                                                                      <Table className="table responsive table-hover mt-2 mb-0">
                                                                        <thead>
                                                                          <tr>
                                                                            <th>
                                                                              Amount
                                                                            </th>
                                                                            <th>
                                                                              Description
                                                                            </th>
                                                                            <th>
                                                                              Reference
                                                                            </th>
                                                                            <th>
                                                                              Type
                                                                            </th>
                                                                            <th>
                                                                              Mode
                                                                            </th>
                                                                            <th>
                                                                              Date
                                                                            </th>
                                                                            <th>
                                                                              Status
                                                                            </th>
                                                                          </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                          {singleOrder?.transactions?.map(
                                                                            transaction => {
                                                                              const {
                                                                                uuid,
                                                                                amount,
                                                                                created_at,
                                                                                description,
                                                                                status,
                                                                                type,
                                                                                reference,
                                                                                mode,
                                                                              } =
                                                                                transaction
                                                                              return (
                                                                                <tr
                                                                                  key={
                                                                                    uuid
                                                                                  }
                                                                                >
                                                                                  <td>
                                                                                    ₦
                                                                                    {
                                                                                      amount
                                                                                    }
                                                                                  </td>
                                                                                  <td>
                                                                                    {
                                                                                      description
                                                                                    }
                                                                                  </td>
                                                                                  <td>
                                                                                    {
                                                                                      reference
                                                                                    }
                                                                                  </td>
                                                                                  <td
                                                                                    style={{
                                                                                      color:
                                                                                        type ===
                                                                                        "credit"
                                                                                          ? "#29CC97"
                                                                                          : type ===
                                                                                            "debit"
                                                                                          ? "#DC1515"
                                                                                          : "#FEC400",
                                                                                    }}
                                                                                  >
                                                                                    {
                                                                                      type
                                                                                    }
                                                                                  </td>
                                                                                  <td>
                                                                                    {
                                                                                      mode
                                                                                    }
                                                                                  </td>
                                                                                  <td>
                                                                                    {
                                                                                      created_at
                                                                                    }
                                                                                  </td>
                                                                                  <td
                                                                                    style={{
                                                                                      color:
                                                                                        status ===
                                                                                        "success"
                                                                                          ? "#29CC97"
                                                                                          : status ===
                                                                                            "pending"
                                                                                          ? "#FEC400"
                                                                                          : "#DC1515",
                                                                                    }}
                                                                                  >
                                                                                    {
                                                                                      status
                                                                                    }
                                                                                  </td>
                                                                                </tr>
                                                                              )
                                                                            }
                                                                          )}
                                                                        </tbody>
                                                                      </Table>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="modal-footer">
                                                          <button
                                                            type="button"
                                                            className="btn btn-secondary"
                                                            data-bs-dismiss="modal"
                                                          >
                                                            Close
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </td>
                                              </tr>

                                              // Modal
                                            )
                                          })}
                                        </tbody>
                                      </Table>
                                      <div className="float-end mt-3">
                                        <ReactPaginate
                                          previousLabel={"Prev"}
                                          nextLabel={"Next"}
                                          breakLabel={"..."}
                                          pageCount={Math.ceil(page.last_page)}
                                          marginPagesDisplayed={4}
                                          pageRangeDisplayed={6}
                                          onPageChange={handlePageClick}
                                          containerClassName={"pagination"}
                                          pageClassName={"page-item"}
                                          pageLinkClassName={"page-link"}
                                          previousClassName={"page-item"}
                                          previousLinkClassName={"page-link"}
                                          nextClassName={"page-item"}
                                          nextLinkClassName={"page-link"}
                                          breakClassName={"page-item"}
                                          breakLinkClassName={"page-link"}
                                          activeClassName={"active"}
                                        />
                                      </div>
                                    </>
                                  ) : (
                                    <h5 className="mx-3">No orders</h5>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </div>

                          <div className="tab-pane fade show" id="tab3">
                            <div className="container-fluid">
                              <Row>
                                <Col lg={12}>
                                  <div className="table-responsive">
                                    <>
                                      <Table className="table responsive table-hover mt-2 mb-0">
                                        <thead>
                                          <tr>
                                            <th>Name</th>
                                            <th></th>
                                            <th>Reference</th>
                                            <th>Created At</th>
                                            <th>Cancelled By</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {cancellations?.map(cancellation => {
                                            const {
                                              uuid,
                                              cancelled_by,
                                              created_at,
                                              partner,
                                              order,
                                            } = cancellation
                                            return (
                                              <tr
                                                key={uuid}
                                                onClick={() =>
                                                  setRedirect(
                                                    `../order/${order.uuid}`
                                                  )
                                                }
                                              >
                                                <td>
                                                  <div className="profile-img">
                                                    <img
                                                      src={partner?.photo}
                                                      className="img-fluid"
                                                      alt="partner-photo"
                                                    />
                                                  </div>
                                                </td>
                                                <td>
                                                  {partner?.name} <br />{" "}
                                                  <small className="text-orange">
                                                    {partner?.email}
                                                  </small>{" "}
                                                </td>
                                                <td>{order?.reference}</td>
                                                <td>{created_at}</td>
                                                <td>{cancelled_by}</td>
                                              </tr>
                                            )
                                          })}
                                        </tbody>
                                      </Table>
                                      <div className="float-end mt-3">
                                        <ReactPaginate
                                          previousLabel={"Prev"}
                                          nextLabel={"Next"}
                                          breakLabel={"..."}
                                          pageCount={Math.ceil(page.last_page)}
                                          marginPagesDisplayed={4}
                                          pageRangeDisplayed={6}
                                          onPageChange={handlePageClick}
                                          containerClassName={"pagination"}
                                          pageClassName={"page-item"}
                                          pageLinkClassName={"page-link"}
                                          previousClassName={"page-item"}
                                          previousLinkClassName={"page-link"}
                                          nextClassName={"page-item"}
                                          nextLinkClassName={"page-link"}
                                          breakClassName={"page-item"}
                                          breakLinkClassName={"page-link"}
                                          activeClassName={"active"}
                                        />
                                      </div>
                                    </>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <h4 className="profile-head mb-4">Send Email</h4>
                  <Row>
                    <Col md={6}>
                      <form onSubmit={sendEmail}>
                        <div className="mb-3">
                          <input
                            type="text"
                            placeholder="Subject"
                            required
                            value={subject}
                            onChange={e => setSubject(e.target.value)}
                          />
                        </div>
                        <div className="mb-3">
                          <textarea
                            className="form-control"
                            rows={5}
                            type="textarea"
                            placeholder="Body"
                            required
                            value={body}
                            onChange={e => setBody(e.target.value)}
                          />
                        </div>
                        <div className="mb-3">
                          <input
                            type="file"
                            name="file"
                            onChange={e => setAttachment(e.target.files[0])}
                            multiple
                          />
                        </div>
                        <button
                          type="submit"
                          disabled={disableButton}
                          className="send"
                        >
                          Send{" "}
                          {buttonLoadEmail && (
                            <div
                              className="spinner-border text-light mx-3"
                              style={{
                                width: "20px",
                                height: "20px",
                              }}
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          )}
                        </button>
                      </form>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <h4 className="profile-head mb-4">Ban User</h4>
                      {profile?.is_banned === true ? (
                        <div>
                          <h6 style={{ color: "#D97706" }}>Reasons For Ban</h6>
                          {profile?.ban_history.map(history => {
                            const {
                              banned_at,
                              banned_by,
                              comment,
                              expired_at,
                            } = history
                            return (
                              <div key={banned_at}>
                                <p>
                                  <strong>Banned At:</strong> {banned_at}
                                </p>
                                <p>
                                  <strong>Banned By:</strong>{" "}
                                  {banned_by ? banned_by : "Not specified"}
                                </p>
                                <p>
                                  Comment:{" "}
                                  <em>{comment ? comment : "No comment"}</em>
                                </p>
                                <p>{expired_at}</p>
                              </div>
                            )
                          })}
                          <button
                            disabled={disableButton}
                            className="change"
                            onClick={() => unbanPartner()}
                          >
                            Unban User{" "}
                            {buttonLoadUnban && (
                              <div
                                className="spinner-border text-light mx-3"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                }}
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            )}
                          </button>
                        </div>
                      ) : (
                        <form onSubmit={banPartner}>
                          <div className="mb-3">
                            <textarea
                              className="form-control"
                              rows={5}
                              type="textarea"
                              placeholder="Reason for ban"
                              required
                              value={comment}
                              onChange={e => setComment(e.target.value)}
                            />
                          </div>
                          <div className="mb-3">
                            <input
                              type="date"
                              placeholder="Expiry Date"
                              value={expire}
                              onChange={e => setExpire(e.target.value)}
                            />
                          </div>
                          <button
                            type="submit"
                            disabled={disableButton}
                            className="change"
                          >
                            Ban User{" "}
                            {buttonLoadBan && (
                              <div
                                className="spinner-border text-light mx-3"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                }}
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            )}
                          </button>
                        </form>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PartnerDetails
