import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"

import accessToken from "../../helpers/jwt-token-access/accessToken"

import "react-toastify/dist/ReactToastify.css"
import { Link} from "react-router-dom"
import {  FaChevronLeft } from "react-icons/fa"
import baseURL from "../../utils"
import "../Orders/orders.css"


const ActivityLogDetails = props => {
  const [activityLog, setActivityLog] = useState({})
  const [loading, setLoading] = useState(true)

  // Order Details
  useEffect(() => {
    const FetchActivityLogDetails = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/activity-logs/${props?.match?.params?.id}`,
          options
        )
        const activityLog = await response.json()
        console.log(activityLog.data)
        setActivityLog(activityLog.data)
        console.log(activityLog)
        setLoading(false)
      } catch (error) {
        console.log("error", error)
        setLoading(false)
      }
    }
    FetchActivityLogDetails()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Activity Log Details | Dilivva Admin</title>
        </MetaTags>
        {loading ? (
          <div className="container">
            <div className="row">
              <div className="col-md-2 skeleton skeleton-head p-3 mt-1 mb-3"></div>
              <div className="card">
                <div className="card-body">
                  <div className="row p-2">
                    <div className="col-md-6">
                      <div className="col-md-3 skeleton skeleton-p p-1"></div>
                      <div className="col-md-5 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-6">
                      <div className="col-md-2 skeleton skeleton-p p-1"></div>
                      <div className="col-md-6 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-6">
                      <div className="col-md-3 skeleton skeleton-p p-1"></div>
                      <div className="col-md-6 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-6">
                      <div className="col-md-3 skeleton skeleton-p p-1"></div>
                      <div className="col-md-7 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-6">
                      <div className="col-md-1 skeleton skeleton-p p-1"></div>
                      <div className="col-md-2 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-6">
                      <div className="col-md-1 skeleton skeleton-p p-1"></div>
                      <div className="col-md-3 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-6">
                      <div className="col-md-3 skeleton skeleton-p p-1"></div>
                      <div className="col-md-2 skeleton skeleton-p p-2"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div
              className="mb-4 d-flex justify-content-center align-items-center"
              style={{
                gap: "4px",
                padding: "7px 18px 7px 12px",
                width: "76px",
                borderRadius: "32px",
                border: "1px solid #F0F0F0",
                background: "#FFF",
                boxShadow: "1px 1px 4px 0px rgba(0, 0, 0, 0.04)",
                marginBottom: "8px",
              }}
            >
              <Link
                to="/activity-logs"
                className="text-black d-flex align-items-center"
              >
                <FaChevronLeft size={16} />
                <div
                  style={{
                    color: "#000",
                    fontSize: "14px",
                    fontWeight: "600",
                    marginLeft: "4px",
                  }}
                >
                  Back
                </div>
              </Link>
            </div>

            <div className="tab-pane fade show active" id="tab1">
              <div className="card">
                <h4 className="package-detials">Activity log details</h4>
                <div className="details-variant">
                  <div className="row mb-4">
                    <div className="col-md-4">
                      <h6 className="details-name">Description</h6>
                      <h4 className="details-info">
                        {activityLog?.description}
                      </h4>
                    </div>
                  </div>

                  <div className="details-row mx-0 mb-4">
                    <div className="row">
                      <div className="col-md-4">
                        <h6 className="details-name">Name</h6>
                        <h4 className="details-info">
                          {activityLog?.causer?.full_name}
                        </h4>
                      </div>
                      <div className="col-md-4">
                        <h6 className="details-name">Email</h6>
                        <h4 className="details-info">
                          {activityLog?.causer?.email}
                        </h4>
                      </div>
                      <div className="col-md-4">
                        <h6 className="details-name">Phone</h6>
                        <h4 className="details-info">
                          {activityLog?.causer?.phone}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="details-row mx-0 mb-4">
                    <div className="row">
                      <div className="col-md-4">
                        <h6 className="details-name">Roles</h6>
                        <h4 className="details-info">
                          {activityLog?.causer?.roles[0].name}
                        </h4>
                      </div>
                      <div className="col-md-4">
                        <h6 className="details-name">Created</h6>
                        <h4 className="details-info">
                          {activityLog?.created_at}
                        </h4>
                      </div>
                      <div className="col-md-4">
                        <h6 className="details-name">Device Type</h6>
                        <h4 className="details-info">
                          {activityLog?.causer?.device_type}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  )
}

export default ActivityLogDetails
