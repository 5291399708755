import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Table, Row, Col, Card, CardBody } from "reactstrap"

import "../senders/datatables.scss"
import accessToken from "../../helpers/jwt-token-access/accessToken"

import { Link, Redirect } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import baseURL from "../../utils"

const Tier = () => {
  const [tiers, setTiers] = useState([])
  const [loading, setLoading] = useState(true)
  const [redirect, setRedirect] = useState("")

  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(`${baseURL}/api/v1/admin/tiers`, options)
        const tiers = await response.json()
        console.log(tiers.data)
        setTiers(tiers.data)
        console.log(tiers.data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error)
        toast.error(error.message)
      }
    }

    fetchData()
  }, [])

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Tiers | Dilivva Admin</title>
        </MetaTags>
        <Row className="mx-1 my-4">
          <Col md={6}>
            <h4 className="dashboard-head">Tiers</h4>
          </Col>
        </Row>
        <div className="container-fluid">
          <ToastContainer />

          <Row>
            <Col lg={12}>
              <div className="d-flex justify-content-end mb-3">
                <Link to="/create-tier" className="send">
                  Create Tiers
                </Link>
              </div>
              <Card>
                <CardBody>
                  {loading ? (
                    <div className="container">
                      <div className="row">
                        <div className="col-md-2 mb-5 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-10 skeleton skeleton-search"></div>
                      </div>
                    </div>
                  ) : (
                    <div className="">
                      <Row className="mt-2">
                      </Row>
                      {tiers?.length !== 0 ? (
                        <div className="table-responsive">
                          <Table className="table responsive table-hover mt-2 mb-0">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Limit</th>
                              </tr>
                            </thead>
                            <tbody>
                              {tiers?.map(tier => {
                                const { uuid, name, description, limit } = tier
                                return (
                                  <tr
                                    key={uuid}
                                    className="table-row"
                                    onClick={() =>
                                      setRedirect("/tiers/" + uuid)
                                    }
                                  >
                                    <td>
                                      {name}
                                      <br />
                                    </td>
                                    <td>{description}</td>
                                    <td>₦{limit}</td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </Table>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-center align-items-center my-4">
                          <h3 className="fw-bold">Oops, No Tiers</h3>
                        </div>
                      )}
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Tier
