import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Table, Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import "../senders/Users.css"

import "../senders/datatables.scss"
import accessToken from "../../helpers/jwt-token-access/accessToken"
import { Redirect } from "react-router-dom"
import "../senders/UserProfile.css"

import ReactPaginate from "react-paginate"
import { FaArrowDown, FaArrowUp, FaFilter } from "react-icons/fa"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import { DateRangePicker } from "react-date-range"
import { addDays } from "date-fns"
import baseURL from "../../utils"

const PendingVerifications = () => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)
  const [redirect, setRedirect] = useState("")
  const [next, setNext] = useState(1)
  const [lastPage, setLastPage] = useState({})
  const [sort, setSort] = useState("asc")
  const [perPage, setPerPage] = useState(50)
  const [showDate, setShowDate] = useState(false)
  const [dateRange, setDateRange] = useState([
    {
      startDate: addDays(new Date("2022-01-01"), 0),
      endDate: addDays(new Date(), 1),
      key: "selection",
    },
  ])

  const minDate = dateRange[0].startDate.toISOString().split("T")[0]
  const maxDate = dateRange[0].endDate.toISOString().split("T")[0]

  const handlePageClick = data => {
    setNext(data.selected + 1)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/identity-verifications/pending?perPage=${perPage}&page=${next}&order=${sort}`,
          options
        )
        const users = await response.json()
        console.log(users.data)
        setUsers(users.data.users)
        setLoading(false)
        setLastPage(users.data.meta)
      } catch (error) {
        setLoading(false)
        toast.error(error.message)
        // window.location.href = '/login'
      }
    }

    fetchData()
  }, [next, sort, perPage, minDate, maxDate])

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <React.Fragment>
      =
      <div className="page-content">
        <MetaTags>
          <title>Pending Verifications | Dilivva Admin</title>
        </MetaTags>
        <Row className="mx-1 my-4">
          <Col md={6}>
            <h4 className="dashboard-head">Pending Verifications</h4>
          </Col>
        </Row>
        <div className="container-fluid">
          <ToastContainer />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {loading ? (
                    <div className="container">
                      <div className="row">
                        <div className="col-md-2 mb-5 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {users.length ? (
                        <div className="table-responsive">
                          <Row className="mt-2">
                            <Col md={9}>
                              <h4 className="title-header">All Senders</h4>
                            </Col>
                            <Col md={1} xs={4}>
                              <select
                                className="form-select form-select-sm"
                                aria-label=".form-select-sm example"
                                value={perPage}
                                onChange={e => {
                                  setLoading(true)
                                  setPerPage(e.target.value)
                                }}
                              >
                                <option defaultValue>50</option>
                                <option defaultValue="25">25</option>
                                <option defaultValue="10">10</option>
                                <option defaultValue="5">5</option>
                              </select>
                            </Col>
                            <Col md={2} xs={8}>
                              <Row className="">
                                <Col>
                                  <FaArrowUp
                                    className="sort"
                                    onClick={() => setSort("asc")}
                                  />{" "}
                                  <FaArrowDown
                                    className="sort"
                                    onClick={() => setSort("desc")}
                                  />{" "}
                                  Sort
                                </Col>
                                <Col
                                  className="sort"
                                  onClick={() => setShowDate(!showDate)}
                                >
                                  <FaFilter /> Filter
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              {showDate && (
                                <DateRangePicker
                                  onChange={item =>
                                    setDateRange([item.selection])
                                  }
                                  showSelectionPreview={true}
                                  moveRangeOnFirstSelection={false}
                                  months={1}
                                  ranges={dateRange}
                                  direction="horizontal"
                                />
                              )}
                            </Col>
                          </Row>
                          <Table className="table responsive table-hover mt-2 mb-0">
                            <thead>
                              <tr>
                                <th>Sender Name</th>
                                <th>Email Address</th>
                                <th>Phone Number</th>
                                <th>Registered</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {users?.map((user, index) => {
                                const {
                                  uuid,
                                  full_name,
                                  email,
                                  created_at,
                                  phone,
                                  identity_verification_status,
                                } = user
                                return (
                                  <tr
                                    key={uuid}
                                    onClick={() => setRedirect("/user/" + uuid)}
                                    className="table-row"
                                  >
                                    <td>{full_name}</td>
                                    <td>{email}</td>
                                    <td>{phone}</td>
                                    <td>{created_at}</td>
                                    <td
                                      style={{
                                        color: "#D97706",
                                      }}
                                    >
                                      {identity_verification_status}
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </Table>
                          <div className="float-end mt-3">
                            <ReactPaginate
                              previousLabel={"Prev"}
                              nextLabel={"Next"}
                              breakLabel={"..."}
                              pageCount={Math.ceil(
                                lastPage?.total / lastPage?.per_page
                              )}
                              marginPagesDisplayed={4}
                              pageRangeDisplayed={6}
                              onPageChange={handlePageClick}
                              containerClassName={"pagination"}
                              pageClassName={"page-item"}
                              pageLinkClassName={"page-link"}
                              previousClassName={"page-item"}
                              previousLinkClassName={"page-link"}
                              nextClassName={"page-item"}
                              nextLinkClassName={"page-link"}
                              breakClassName={"page-item"}
                              breakLinkClassName={"page-link"}
                              activeClassName={"active"}
                            />
                          </div>
                        </div>
                      ) : (
                        <div>
                          <h4>No Pending Verifications</h4>
                        </div>
                      )}
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PendingVerifications
