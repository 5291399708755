import { ToastContainer, toast } from "react-toastify"
import accessToken from "../../helpers/jwt-token-access/accessToken"
import React, { useEffect, useState } from "react"
import baseURL from "../../utils"

const SalesRep = ({ userId, profile }) => {
  const [account, setAccount] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/users/${userId}/virtual-account`,
          options
        )
        const account = await response.json()
        setAccount(account.data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    }

    fetchData()
  }, [])

  // Make user a sales rep agen
  const AssignSalesRep = async e => {
    e.preventDefault()
    // do stuff
    setLoading(true)
    try {
      const options = {
        method: "POST",
        headers: new Headers({
          Authorization: accessToken,
          "Content-Type": "application/json",
        }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/users/${userId}/assign-sales-rep`,
        options
      )
      const data = await response.json()
      if (data.status == "success") {
        toast.success(data["message"])
        setLoading(false)
        setTimeout(() => {
          location.reload()
        }, 5000)
      } else if (data.status == "error") {
        setLoading(false)
        toast.error(data["message"])
      }
    } catch (error) {
      // do what
    }
  }

  // Make user a sales rep agen
  const RevokeSalesRep = async e => {
    e.preventDefault()
    // do stuff
    setLoading(true)
    try {
      const options = {
        method: "POST",
        headers: new Headers({
          Authorization: accessToken,
          "Content-Type": "application/json",
        }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/users/${userId}/revoke-sales-rep`,
        options
      )
      const data = await response.json()
      if (data.status == "success") {
        toast.success(data["message"])
        setLoading(false)
        setTimeout(() => {
          location.reload()
        }, 5000)
      } else if (data.status == "error") {
        setLoading(false)
        toast.error(data["message"])
      }
    } catch (error) {
      // do what
    }
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <ToastContainer />
          <h4 className="mt-3">Sales Rep</h4>
          <div className="">
            {profile.is_sales_rep ? (
              <>
                <p>Revoke the role of sales rep to user</p>
                <button
                  className="change"
                  onClick={RevokeSalesRep}
                  disabled={loading}
                >
                  {loading ? "Revoking..." : "Revoke"}
                </button>
              </>
            ) : (
              <>
                <p>Assing the role of sales rep to user</p>
                <button
                  className="send"
                  onClick={AssignSalesRep}
                  disabled={loading}
                >
                  {loading ? "Assigning..." : "Assign"}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SalesRep
