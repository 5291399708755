import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Table, Row, Col, Card, CardBody } from "reactstrap"

import "../senders/datatables.scss"
import accessToken from "../../helpers/jwt-token-access/accessToken"
import { Link, Redirect } from "react-router-dom"

import ReactPaginate from "react-paginate"
import { FaArrowDown, FaArrowUp, FaFilter } from "react-icons/fa"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import { DateRangePicker } from "react-date-range"
import { addDays } from "date-fns"
import baseURL from "../../utils"

const Hubs = () => {
  const [hubs, setHubs] = useState([])
  const [loading, setLoading] = useState(true)
  const [redirect, setRedirect] = useState("")
  const [perPage, setPerPage] = useState(50)
  const [next, setNext] = useState(1)
  const [lastPage, setLastPage] = useState({})
  const [sort, setSort] = useState("desc")
  const [address, setAddress] = useState("")
  const [showDate, setShowDate] = useState(false)
  const [dateRange, setDateRange] = useState([
    {
      startDate: addDays(new Date("2022-01-01"), 0),
      endDate: addDays(new Date(), 1),
      key: "selection",
    },
  ])

  const minDate = dateRange[0].startDate.toISOString().split("T")[0]
  const maxDate = dateRange[0].endDate.toISOString().split("T")[0]

  const handlePageClick = data => {
    setNext(data.selected + 1)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/hubs?name=${address}&perPage=${perPage}&page=${next}&sort=${sort}&date_range=${minDate},${maxDate}`,
          options
        )
        const hubs = await response.json()
        console.log(hubs.data)
        setHubs(hubs.data.hubs)
        console.log(hubs)
        setLoading(false)
        setLastPage(hubs.data.meta)
      } catch (error) {
        setLoading(false)
        console.log(error)
        toast.error(error.message)
      }
    }

    fetchData()
  }, [next, sort, perPage, minDate, maxDate, address])

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Hubs | Dilivva Admin</title>
        </MetaTags>
        <Row className="mx-1 my-4">
          <Col md={6}>
            <h4 className="dashboard-head">Hubs</h4>
          </Col>
          <Col md={6}>
            <input
              type="text"
              placeholder="Search hubs by address"
              value={address}
              onChange={e => setAddress(e.target.value)}
            />
          </Col>
        </Row>
        <div className="container-fluid">
          <ToastContainer />

          <Row>
            <Col lg={12}>
              <div className="d-flex justify-content-end mb-3">
                <Link to="/create-hubs" className="send">
                  Create Hub
                </Link>
              </div>
              <Card>
                <CardBody>
                  {loading ? (
                    <div className="container">
                      <div className="row">
                        <div className="col-md-2 mb-5 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-10 skeleton skeleton-search"></div>
                      </div>
                    </div>
                  ) : (
                    <div className="">
                      <Row className="mt-2">
                        <div className="d-flex justify-content-end align-items-center gap-lg-4 w-full">
                          <Col md={1} xs={4} style={{ marginRight: "10px" }}>
                            <select
                              className="form-select form-select-sm"
                              aria-label=".form-select-sm example"
                              value={perPage}
                              onChange={e => {
                                setLoading(true)
                                setPerPage(e.target.value)
                              }}
                            >
                              <option defaultValue>50</option>
                              <option defaultValue="25">25</option>
                              <option defaultValue="10">10</option>
                              <option defaultValue="5">5</option>
                            </select>
                          </Col>
                          <Col md={2} xs={8}>
                            <Row className="">
                              <Col>
                                <FaArrowUp
                                  className="sort"
                                  onClick={() => setSort("asc")}
                                />{" "}
                                <FaArrowDown
                                  className="sort"
                                  onClick={() => setSort("desc")}
                                />{" "}
                                Sort
                              </Col>
                              <Col
                                className="sort"
                                onClick={() => setShowDate(!showDate)}
                              >
                                <FaFilter /> Filter
                              </Col>
                            </Row>
                          </Col>
                        </div>
                      </Row>
                      <Row>
                        <Col>
                          {showDate && (
                            <DateRangePicker
                              onChange={item => setDateRange([item.selection])}
                              showSelectionPreview={true}
                              moveRangeOnFirstSelection={false}
                              months={1}
                              ranges={dateRange}
                              direction="horizontal"
                            />
                          )}
                        </Col>
                      </Row>
                      {hubs?.length !== 0 ? (
                        <div className="table-responsive">
                          <Table className="table responsive table-hover mt-2 mb-0">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Address</th>
                                <th>Contact Person</th>
                                <th>BDO Name</th>
                                <th>State</th>
                                <th>Date Created</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {hubs?.map(hubs => {
                                const {
                                  uuid,
                                  name,
                                  address,
                                  contact_person,
                                  state,
                                  city,
                                  is_active,
                                  created_at,
                                  bdo_name,
                                } = hubs
                                return (
                                  <tr
                                    key={uuid}
                                    className="table-row"
                                    onClick={() => setRedirect("/hubs/" + uuid)}
                                  >
                                    <td>
                                      {name}
                                      <br />
                                      {contact_person?.phone}
                                    </td>
                                    <td>{address}</td>
                                    <td>
                                      {contact_person?.name}
                                      <br />
                                      <span className="text-green">
                                        {contact_person?.email}
                                      </span>
                                    </td>
                                    <td>{bdo_name ? bdo_name : "No name"}</td>
                                    <td>
                                      {city}
                                      {city ? "," : " "} {state}
                                    </td>
                                    <td>{created_at}</td>
                                    <td
                                      style={{
                                        color:
                                          is_active === true
                                            ? "#29CC97"
                                            : "#FEC400",
                                      }}
                                    >
                                      {is_active ? "ACTIVE" : "NOT ACTIVE"}
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </Table>
                          <div className="float-end mt-3">
                            <ReactPaginate
                              previousLabel={"Prev"}
                              nextLabel={"Next"}
                              breakLabel={"..."}
                              pageCount={Math.ceil(
                                lastPage?.total / lastPage?.per_page
                              )}
                              marginPagesDisplayed={4}
                              pageRangeDisplayed={6}
                              onPageChange={handlePageClick}
                              containerClassName={"pagination"}
                              pageClassName={"page-item"}
                              pageLinkClassName={"page-link"}
                              previousClassName={"page-item"}
                              previousLinkClassName={"page-link"}
                              nextClassName={"page-item"}
                              nextLinkClassName={"page-link"}
                              breakClassName={"page-item"}
                              breakLinkClassName={"page-link"}
                              activeClassName={"active"}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-center align-items-center my-4">
                          <h3 className="fw-bold">Oops, No hubs</h3>
                        </div>
                      )}
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Hubs
