import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Table, Row, Col, Card, CardBody } from "reactstrap"

import "../senders/datatables.scss"
import accessToken from "../../helpers/jwt-token-access/accessToken"
import { Redirect } from "react-router-dom"

import ReactPaginate from "react-paginate"
import { FaArrowDown, FaArrowUp, FaFilter } from "react-icons/fa"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import { DateRangePicker } from "react-date-range"
import { addDays } from "date-fns"
import baseURL from "../../utils"

const CorporateClient = () => {
  const [corporateClient, setCorporateClient] = useState([])
  const [loading, setLoading] = useState(true)
  const [redirect, setRedirect] = useState("")
  const [perPage, setPerPage] = useState(50)
  const [next, setNext] = useState(1)
  const [lastPage, setLastPage] = useState({})
  const [sort, setSort] = useState("desc")
  const [search, setSearch] = useState("")
  const [address, setAddress] = useState("")
  const [showDate, setShowDate] = useState(false)
  const [dateRange, setDateRange] = useState([
    {
      startDate: addDays(new Date("2022-01-01"), 0),
      endDate: addDays(new Date(), 1),
      key: "selection",
    },
  ])

  const minDate = dateRange[0].startDate.toISOString().split("T")[0]
  const maxDate = dateRange[0].endDate.toISOString().split("T")[0]

  const handlePageClick = data => {
    setNext(data.selected + 1)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/corporates?perPage=${perPage}&page=${next}&sort=${sort}&date_range=${minDate},${maxDate}`,
          options
        )
        const corporateClient = await response.json()
        console.log(corporateClient.data.users)
        setCorporateClient(corporateClient.data.users)
        console.log(corporateClient.data)
        setLoading(false)
        setLastPage(corporateClient.data.meta)
      } catch (error) {
        setLoading(false)
        console.log(error)
        toast.error(error.message)
      }
    }

    fetchData()
  }, [next, sort, perPage, minDate, maxDate, address])

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Corporate Client | Dilivva Admin</title>
        </MetaTags>
        <Row className="mx-1 my-4">
          <Col md={6}>
            <h4 className="dashboard-head">Corporate Client</h4>
          </Col>
          <Col md={6}>
            <input
              type="text"
              placeholder="Search corporate client by name, email, or phone"
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
          </Col>
        </Row>
        <div className="container-fluid">
          <ToastContainer />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {loading ? (
                    <div className="container">
                      <div className="row">
                        <div className="col-md-2 mb-5 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-10 skeleton skeleton-search"></div>
                      </div>
                    </div>
                  ) : (
                    <div className="">
                      <Row className="mt-2">
                        <div className="d-flex justify-content-end align-items-center gap-lg-4 w-full">
                          <Col md={1} xs={4} style={{ marginRight: "10px"}}>
                            <select
                              className="form-select form-select-sm"
                              aria-label=".form-select-sm example"
                              value={perPage}
                              onChange={e => {
                                setLoading(true)
                                setPerPage(e.target.value)
                              }}
                            >
                              <option defaultValue>50</option>
                              <option defaultValue="25">25</option>
                              <option defaultValue="10">10</option>
                              <option defaultValue="5">5</option>
                            </select>
                          </Col>
                          <Col md={2} xs={8}>
                            <Row className="">
                              <Col>
                                <FaArrowUp
                                  className="sort"
                                  onClick={() => setSort("asc")}
                                />{" "}
                                <FaArrowDown
                                  className="sort"
                                  onClick={() => setSort("desc")}
                                />{" "}
                                Sort
                              </Col>
                              <Col
                                className="sort"
                                onClick={() => setShowDate(!showDate)}
                              >
                                <FaFilter /> Filter
                              </Col>
                            </Row>
                          </Col>
                        </div>
                      </Row>
                      <Row>
                        <Col>
                          {showDate && (
                            <DateRangePicker
                              onChange={item => setDateRange([item.selection])}
                              showSelectionPreview={true}
                              moveRangeOnFirstSelection={false}
                              months={1}
                              ranges={dateRange}
                              direction="horizontal"
                            />
                          )}
                        </Col>
                      </Row>
                      {corporateClient?.length !== 0 ? (
                        <div className="table-responsive">
                          <Table className="table responsive table-hover mt-2 mb-0">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Contact Person</th>
                                <th>Phone</th>
                                <th>Accrued Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {corporateClient
                                ?.filter(corporateClient => {
                                  if (search === "") {
                                    return corporateClient
                                  } else if (
                                    corporateClient?.full_name
                                      ?.toLowerCase()
                                      ?.includes(search.toLowerCase())
                                  ) {
                                    return corporateClient
                                  } else if (
                                    corporateClient?.email
                                      ?.toLowerCase()
                                      ?.includes(search?.toLowerCase())
                                  ) {
                                    return corporateClient
                                  } else if (
                                    corporateClient?.phone
                                      ?.toLowerCase()
                                      ?.includes(search?.toLowerCase())
                                  ) {
                                    return corporateClient
                                  }
                                })
                                ?.map(corporate => {
                                  const {
                                    uuid,
                                    full_name,
                                    email,
                                    phone,
                                    accrued_amount,
                                    contact_person_name,
                                  } = corporate
                                  return (
                                    <tr
                                      key={uuid}
                                      className="table-row"
                                      onClick={() =>
                                        setRedirect("/corporates/" + uuid)
                                      }
                                    >
                                      <td>
                                        {full_name}
                                        <br />
                                      </td>
                                      <td>{email}</td>
                                      <td>{contact_person_name}</td>
                                      <td>{phone}</td>
                                      <td>₦{accrued_amount}</td>
                                    </tr>
                                  )
                                })}
                            </tbody>
                          </Table>
                          <div className="float-end mt-3">
                            <ReactPaginate
                              previousLabel={"Prev"}
                              nextLabel={"Next"}
                              breakLabel={"..."}
                              pageCount={Math.ceil(
                                lastPage?.total / lastPage?.per_page
                              )}
                              marginPagesDisplayed={4}
                              pageRangeDisplayed={6}
                              onPageChange={handlePageClick}
                              containerClassName={"pagination"}
                              pageClassName={"page-item"}
                              pageLinkClassName={"page-link"}
                              previousClassName={"page-item"}
                              previousLinkClassName={"page-link"}
                              nextClassName={"page-item"}
                              nextLinkClassName={"page-link"}
                              breakClassName={"page-item"}
                              breakLinkClassName={"page-link"}
                              activeClassName={"active"}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-center align-items-center my-4">
                          <h3 className="fw-bold">Oops, No corporateClient</h3>
                        </div>
                      )}
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CorporateClient
