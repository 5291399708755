import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Table, Row, Col, Card, CardBody, CardTitle } from "reactstrap"

import accessToken from "../../helpers/jwt-token-access/accessToken"
import { Redirect } from "react-router-dom"
import "../senders/datatables.scss"

import ReactPaginate from "react-paginate"
import { FaArrowDown, FaArrowUp, FaFileExport, FaFilter } from "react-icons/fa"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import { DateRangePicker } from "react-date-range"
import { addDays } from "date-fns"

import { read, writeFile, utils } from "xlsx"
import baseURL from "../../utils"

const Courier = () => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)
  const [redirect, setRedirect] = useState("")
  const [search, setSearch] = useState("")
  const [next, setNext] = useState(1)
  const [lastPage, setLastPage] = useState({})
  const [sort, setSort] = useState("desc")
  const [searchResult, setSearchResult] = useState([])
  const [perPage, setPerPage] = useState(50)
  const [showDate, setShowDate] = useState(false)
  const [dateRange, setDateRange] = useState([
    {
      startDate: addDays(new Date("2022-01-01"), 0),
      endDate: addDays(new Date(), 1),
      key: "selection",
    },
  ])

  const minDate = dateRange[0].startDate.toISOString().split("T")[0]
  const maxDate = dateRange[0].endDate.toISOString().split("T")[0]

  const handlePageClick = data => {
    setNext(data.selected + 1)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/couriers?perPage=${perPage}&page=${next}&sort=${sort}&date_range=${minDate},${maxDate}`,
          options
        )
        const users = await response.json()
        console.log(users.data)
        setUsers(users.data.couriers)
        setLoading(false)
        setLastPage(users.data.meta)
      } catch (error) {
        setLoading(false)
        toast.error(error.message)
        // window.location.href = '/login'
      }
    }

    fetchData()
  }, [next, sort, perPage, minDate, maxDate])

  // Search Users
  const searchUser = async () => {
    console.log(search)
    try {
      const options = {
        method: "POST",
        headers: new Headers({
          Authorization: accessToken,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          search,
        }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/users/search`,
        options
      )
      const data = await response.json()
      setSearchResult(data["data"]["users"])
      if (data.status == "success") {
        console.log(data["data"]["users"])
      } else if (data.status == "error") {
        toast.error(data["message"])
      }
    } catch (error) {
      // do what
    }
  }

  const handleExport = () => {
    console.log(users)
    var wb = utils.book_new(),
      ws = utils.json_to_sheet(users)

    utils.book_append_sheet(wb, ws, "MySheet1")

    writeFile(wb, "Couriers.xlsx")
  }

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <React.Fragment>
      =
      <div className="page-content">
        <MetaTags>
          <title>All couriers | Dilivva Admin</title>
        </MetaTags>
        <Row className="mx-1 my-4">
          <Col md={6}>
            <h4 className="dashboard-head">Couriers</h4>
          </Col>
          <Col md={6}>
            <input
              type="text"
              placeholder="Search sender by name or email address"
              value={search}
              onChange={e => setSearch(e.target.value)}
              onKeyUp={() => searchUser()}
            />
            <div className="list-group rounded">
              {searchResult.map(search => {
                const { full_name, uuid } = search
                return (
                  <button
                    type="button"
                    className="list-group-item list-group-item-action"
                    onClick={() => setRedirect("/user/" + uuid)}
                  >
                    {full_name}
                  </button>
                )
              })}
            </div>
          </Col>
        </Row>
        <div className="container-fluid">
          <ToastContainer />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {loading ? (
                    <div className="container">
                      <div className="row">
                        <div className="col-md-2 mb-5 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                      </div>
                    </div>
                  ) : (
                    <div className="table-responsive">
                      <Row className="mt-2">
                        <Col md={8}>
                          <h4 className="title-header">All Couriers</h4>
                        </Col>
                        <Col md={1} xs={4}>
                          <select
                            className="form-select form-select-sm"
                            aria-label=".form-select-sm example"
                            value={perPage}
                            onChange={e => {
                              setLoading(true)
                              setPerPage(e.target.value)
                            }}
                          >
                            <option defaultValue>50</option>
                            <option defaultValue="25">25</option>
                            <option defaultValue="10">10</option>
                            <option defaultValue="5">5</option>
                          </select>
                        </Col>
                        <Col md={3} xs={8}>
                          <Row className="">
                            <Col>
                              <FaArrowUp
                                className="sort"
                                onClick={() => setSort("asc")}
                              />{" "}
                              <FaArrowDown
                                className="sort"
                                onClick={() => setSort("desc")}
                              />{" "}
                              Sort
                            </Col>
                            <Col
                              className="sort"
                              onClick={() => setShowDate(!showDate)}
                            >
                              <FaFilter /> Filter
                            </Col>
                            <Col className="sort" onClick={handleExport}>
                              <FaFileExport /> Export
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {showDate && (
                            <DateRangePicker
                              onChange={item => setDateRange([item.selection])}
                              showSelectionPreview={true}
                              moveRangeOnFirstSelection={false}
                              months={1}
                              ranges={dateRange}
                              direction="horizontal"
                            />
                          )}
                        </Col>
                      </Row>
                      <Table className="table responsive table-hover mt-2 mb-0">
                        <thead>
                          <tr>
                            <th>COURIER NAME</th>
                            <th>PHONE NUMBER</th>
                            <th>REGISTRATION DATE</th>
                            <th>STATE</th>
                            <th>VERIFICATION STATUS</th>
                          </tr>
                        </thead>
                        <tbody>
                          {users?.map(user => {
                            const {
                              uuid,
                              name,
                              email,
                              created_at,
                              state,
                              local_government,
                              phone,
                              verification_status,
                            } = user
                            return (
                              <tr
                                key={uuid}
                                onClick={() => setRedirect("/courier/" + uuid)}
                                className="table-row"
                              >
                                <td>
                                  {name} <br />{" "}
                                  <small className="text-green">{email}</small>
                                </td>
                                <td>{phone}</td>
                                <td>{created_at}</td>
                                <td>
                                  {state} <br />{" "}
                                  <small className="text-orange">
                                    {local_government}
                                  </small>
                                </td>
                                <td
                                  style={{
                                    color:
                                      verification_status === "approved"
                                        ? "#29CC97"
                                        : verification_status === "pending"
                                        ? "#FEC400"
                                        : "#222",
                                  }}
                                >
                                  {verification_status
                                    ? verification_status
                                    : "null"}
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                      <div className="float-end mt-3">
                        <ReactPaginate
                          previousLabel={"Prev"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={Math.ceil(
                            lastPage?.total / lastPage?.per_page
                          )}
                          marginPagesDisplayed={4}
                          pageRangeDisplayed={6}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Courier
