import React, { useState } from "react"
import { MetaTags } from "react-meta-tags"
import { Link } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap"
import "./Login.css"

// import images
import logoSm from "../../assets/images/dilivva-logo.png"
import baseURL from "../../utils/"

const url = window.location.origin

const Login = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [role, setRole] = useState("admin")
  const [disableButton, setDisbaleButton] = useState(false)
  const [buttonLoad, setButtonLoad] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    setDisbaleButton(true)
    setButtonLoad(true)
    setRole("admin")
    const options = {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ email, password, role }),
    }

    const response = fetch(`${baseURL}/api/v1/login`, options)
      .then(data => data.json())
      .then(response => {
        setDisbaleButton(false)
        setButtonLoad(false)
        toast(response.message)
        localStorage.setItem("authUser", response.data.token)
        window.location = `${url}/dashboard`
      })
      .catch(error => {
        console.log(error.message)
        setDisbaleButton(false)
        setButtonLoad(false)
      })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | Dilivva Admin Dashboard</title>
      </MetaTags>
      <div className="account-pages pt-sm-4">
        <Container>
          <ToastContainer autoClose={3000} />
          <Row className="justify-content-center">
            <Col md={6} lg={5} xl={4}>
              <Card className="overflow-hidden">
                <div className="text-primary text-center p-4">
                  <Link to="/" className="logo logo-admin">
                    <img src={logoSm} height="50" alt="logo" />
                  </Link>
                </div>
                <CardBody className="m-3">
                  <div className="login-head">
                    <h2 className="mb-4">Dilivva</h2>
                    <h3>Log In to Dilivva Dashboard</h3>
                    <p>Enter your email and password below</p>
                  </div>
                  <Form onSubmit={handleSubmit}>
                    <Label className="input-label mt-5">EMAIL</Label>
                    <Input
                      type="text"
                      name="email"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      placeholder="Email Address"
                    />

                    <Label className="input-label mt-4">PASSWORD </Label>
                    <Input
                      type="password"
                      name="password"
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      placeholder="Password"
                    />
                    <Link to="/forgot-password" className="forgot-password text-orange my-1">
                      Forgot password?
                    </Link>

                    <Row className="mt-4">
                      <Col sm={12} className="text-end">
                        <button
                          className="login-btn w-100"
                          disabled={disableButton}
                          type="submit"
                        >
                          {buttonLoad ? (
                            <div className="d-flex justify-content-center align-items-center">
                              <div
                                className="spinner-border text-white"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            </div>
                          ) : (
                            "Log In"
                          )}
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Login
