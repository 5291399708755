import { Autocomplete } from "@react-google-maps/api"
import accessToken from "../../helpers/jwt-token-access/accessToken"
import { useState } from "react"
import { toast } from "react-toastify"
import baseURL from "../../utils"

const Address = ({ location, setLocation, coordinates, setCoordinates }) => {
  const [autocomplete, setAutocomplete] = useState(null)
  const [available, setAvailable] = useState(null)
  const [address, setAddress] = useState(location)

  const onLoad = auto => {
    setAutocomplete(auto)
    // Set the country restriction to Nigeria
    auto.setComponentRestrictions({ country: "NG" })
  }

  const handleChange = event => {
    setAddress(event.target.value)
  }

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const address = autocomplete.getPlace()

      if (address.geometry) {
        const lat = address.geometry.location.lat()
        const lng = address.geometry.location.lng()

        setAddress(`${address.name}, ${address.formatted_address}`)
        setLocation(`${address.name}, ${address.formatted_address}`)

        coordinates = `${lat}, ${lng}`
        setCoordinates(coordinates)

        let state =
          address.formatted_address.split(",")[
            address.formatted_address.split(",").length - 2
          ]

        checkLocation(state, coordinates)
      }
    } else {
      console.log("Autocomplete is not loaded yet!")
    }
  }

  // check pickup location availability
  const checkLocation = (state, coordinates) => {
    setAvailable("")
    const options = {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
      body: JSON.stringify({
        state,
        coordinates,
      }),
    }
    fetch(`${baseURL}/api/v1/misc/confirm-location`, options)
      .then(data => data.json())
      .then(response => {
        if (response.status !== "success") {
          setAvailable(response.message)
        } else {
        }
      })
      .catch(error => {
        console.log(error.message)
      })
  }

  return (
    <div>
      <label htmlFor="name">Address</label>
      <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
        <input
          type="text"
          placeholder="Enter Address"
          value={address}
          onChange={handleChange}
        />
      </Autocomplete>
      <small className="text-danger">{available}</small>
    </div>
  )
}

export default Address
