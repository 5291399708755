import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Row, Col, Card, CardBody } from "reactstrap"

import accessToken from "../../helpers/jwt-token-access/accessToken"

import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import profileImage from "../../assets/images/profile-img.png"
import baseURL from "../../utils"

const RefundDetails = props => {
  const [refunds, setrefunds] = useState([])
  const [loading, setLoading] = useState(true)

  // Refund Details
  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/refunds/${props?.match?.params?.id}`,
          options
        )
        const refunds = await response.json()
        console.log(refunds.data, "refund")
        setLoading(false)
        setrefunds(refunds.data)
      } catch (error) {
        console.log("error", error)
        toast.error(error.message)
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Refund Details | Dilivva Admin</title>
        </MetaTags>
        <h4 className="refunds-head mb-4">Refund Details</h4>
        {loading ? (
          <>
            <Row className="d-flex justify-content-center">
              <Col lg={11}>
                <Row>
                  <Col>
                    <Card className="profile-card">
                      <CardBody>
                        <Col md={12}>
                          <Row className="p-3">
                            <Col md={4}>
                              <div className="skel">
                                <div className="col-md-8 skeleton skeleton-circle"></div>
                              </div>
                            </Col>
                            <Col md={8} className="mt-1">
                              <div className="col-md-12 skeleton skeleton-head"></div>
                              <div className="col-md-12 skeleton skeleton-p"></div>
                              <div className="col-md-8 skeleton skeleton-p"></div>
                            </Col>
                          </Row>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="profile-wallet">
                      <CardBody>
                        <Col md={12}>
                          <Row className="p-2">
                            <Col>
                              <div className="col-md-2 skeleton skeleton-p my-2 mb-4"></div>
                              <div className="col-md-3 skeleton skeleton-head p-3"></div>
                            </Col>
                          </Row>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <div className="card">
                <div className="card-body">
                  <div className="row p-2">
                    <div className="col-md-6">
                      <div className="col-md-2 skeleton skeleton-p p-1"></div>
                      <div className="col-md-5 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-6">
                      <div className="col-md-2 skeleton skeleton-p p-1"></div>
                      <div className="col-md-5 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-6">
                      <div className="col-md-1 skeleton skeleton-p p-1"></div>
                      <div className="col-md-2 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-6">
                      <div className="col-md-2 skeleton skeleton-p p-1"></div>
                      <div className="col-md-8 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-6">
                      <div className="col-md-1 skeleton skeleton-p p-1"></div>
                      <div className="col-md-2 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-6">
                      <div className="col-md-3 skeleton skeleton-p p-1"></div>
                      <div className="col-md-2 skeleton skeleton-p p-2"></div>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </>
        ) : (
          <Row className="d-flex justify-content-center">
            <Col lg={11}>
              <Row>
                <Col>
                  <Card className="profile-card">
                    <CardBody>
                      <Col md={12}>
                        <Row className="p-3">
                          <Col md={4}>
                            <img
                              className="img-fluid"
                              src={profileImage}
                              alt="profile"
                            />
                          </Col>
                          <Col md={8}>
                            <h3 className="profile-name">
                              {refunds?.user?.name}
                            </h3>
                            <h6 className="details">{refunds?.user?.email}</h6>
                            <h6 className="details">{refunds?.user?.phone}</h6>
                          </Col>
                        </Row>
                      </Col>
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card className="profile-wallet">
                    <CardBody>
                      <Col md={12}>
                        <Row className="p-2">
                          <Col>
                            <p className="wallet">Amount</p>
                            <h2 className="balance">₦ {refunds?.amount}</h2>
                          </Col>
                        </Row>
                      </Col>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        <div className="container">
          <div className="row">
            <div className="card">
              <div className="card-body">
                <div className="row p-2">
                  <div className="col-md-6">
                    <span className="price-head text-green">Date Created</span>
                    <h6 className="details">{refunds?.created_at}</h6>
                  </div>
                  <div className="col-md-6">
                    <span className="price-head text-green">Reference</span>
                    <h6 className="details">
                      {refunds?.transaction?.reference}
                    </h6>
                  </div>
                  <div className="col-md-6">
                    <span className="price-head text-green">Amount</span>
                    <h6 className="details">₦ {refunds?.amount}</h6>
                  </div>
                  <div className="col-md-6">
                    <span className="price-head text-green">Description</span>
                    <h6 className="details">
                      {refunds?.transaction?.description}
                    </h6>
                  </div>
                  <div className="col-md-6">
                    <span className="price-head">Status</span>
                    <h6
                      className="details"
                      style={{
                        color:
                          refunds?.status === "success"
                            ? "#29CC97"
                            : status === "pending"
                            ? "#FEC400"
                            : "#DC1515",
                      }}
                    >
                      {refunds?.status}
                    </h6>
                  </div>
                  <div className="col-md-6">
                    <span className="price-head text-green">Date Updated</span>
                    <h6 className="details">{refunds?.updated_at}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default RefundDetails
