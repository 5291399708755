import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Row, Col, Card, CardBody } from "reactstrap"

import accessToken from "../../helpers/jwt-token-access/accessToken"

import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import profileImage from "../../assets/images/profile-img.png"
import baseURL from "../../utils"

const TransactionDetails = props => {
  const [transactions, setTransactions] = useState([])
  const [loading, setLoading] = useState(true)
  const [buttonLoad, setButtonLoad] = useState(false)
  const [disableButton, setDisableButton] = useState(false)

  // Transaction Details
  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/transactions/${props?.match?.params?.id}`,
          options
        )
        const transactions = await response.json()
        console.log(transactions.data, "transaction")
        setLoading(false)
        setTransactions(transactions.data)
      } catch (error) {
        console.log("error", error)
        toast.error(error.message)
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  // Refund
  const refund = async () => {
    if (confirm("Are you sure you want to make a refund?")){
      // do stuff
      setButtonLoad(true)
      setDisableButton(true)
      try {
        const options = {
          method: "POST",
          headers: new Headers({
            Authorization: accessToken,
            "Content-Type": "application/json",
          }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/transactions/${props?.match?.params?.id}/refund`,
          options
        )
        const data = await response.json()
        console.log(data)
        console.log(data.message)
        if (data.status == "success") {
          toast.success(data["message"])
          setTimeout(() => {
            window.location.href = "/refund"
          }, 5000)
        } else if (data.status == "error") {
          toast.error(data["message"])
          setButtonLoad(false)
          setDisableButton(false)
        }
      } catch (error) {
        // do what
        toast.error(error.message)
      }
    } else {
      return false
    }
      
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Transaction Details | Dilivva Admin</title>
        </MetaTags>
        <h4 className="transactions-head mb-4">Transaction Details</h4>
        <ToastContainer />
        {loading ? (
          <>
            <Row className="d-flex justify-content-center">
              <Col lg={11}>
                <Row>
                  <Col>
                    <Card className="profile-card">
                      <CardBody>
                        <Col md={12}>
                          <Row className="p-3">
                            <Col md={4}>
                              <div className="skel">
                                <div className="col-md-8 skeleton skeleton-circle"></div>
                              </div>
                            </Col>
                            <Col md={8} className="mt-1">
                              <div className="col-md-12 skeleton skeleton-head"></div>
                              <div className="col-md-12 skeleton skeleton-p"></div>
                              <div className="col-md-8 skeleton skeleton-p"></div>
                            </Col>
                          </Row>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="profile-wallet">
                      <CardBody>
                        <Col md={12}>
                          <Row className="p-2">
                            <Col>
                              <div className="col-md-2 skeleton skeleton-p my-2 mb-4"></div>
                              <div className="col-md-3 skeleton skeleton-head p-3"></div>
                            </Col>
                          </Row>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <div className="card">
                <div className="card-body">
                  <div className="row p-2">
                    <div className="col-md-6">
                      <div className="col-md-2 skeleton skeleton-p p-1"></div>
                      <div className="col-md-5 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-6">
                      <div className="col-md-2 skeleton skeleton-p p-1"></div>
                      <div className="col-md-5 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-6">
                      <div className="col-md-1 skeleton skeleton-p p-1"></div>
                      <div className="col-md-2 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-6">
                      <div className="col-md-2 skeleton skeleton-p p-1"></div>
                      <div className="col-md-7 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-6">
                      <div className="col-md-1 skeleton skeleton-p p-1"></div>
                      <div className="col-md-2 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-6">
                      <div className="col-md-1 skeleton skeleton-p p-1"></div>
                      <div className="col-md-2 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-6">
                      <div className="col-md-1 skeleton skeleton-p p-1"></div>
                      <div className="col-md-2 skeleton skeleton-p p-2"></div>
                    </div>
                    <div className="col-md-6">
                      <div className="col-md-3 skeleton skeleton-p p-1"></div>
                      <div className="col-md-2 skeleton skeleton-p p-2"></div>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </>
        ) : (
          <Row className="d-flex justify-content-center">
            <Col lg={11}>
              <Row>
                <Col>
                  <Card className="profile-card">
                    <CardBody>
                      <Col md={12}>
                        <Row className="p-3">
                          <Col md={4}>
                            <img
                              className="img-fluid"
                              src={profileImage}
                              alt="profile"
                            />
                          </Col>
                          <Col md={8}>
                            <h3 className="profile-name">
                              {transactions?.user?.name}
                            </h3>
                            <h6 className="details">
                              {transactions?.user?.email}
                            </h6>
                            <h6 className="details">
                              {transactions?.user?.phone}
                            </h6>
                          </Col>
                        </Row>
                      </Col>
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card className="profile-wallet">
                    <CardBody>
                      <Col md={12}>
                        <Row className="p-2">
                          <Col>
                            <p className="wallet">Amount</p>
                            <h2 className="balance">
                              ₦ {transactions?.amount}
                            </h2>
                          </Col>
                        </Row>
                      </Col>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        <div className="container">
          <div className="row">
            <div className="card">
              <div className="card-body">
                <div className="row p-2">
                  <div className="col-md-6">
                    <span className="price-head text-green">Date Created</span>
                    <h6 className="details">{transactions?.created_at}</h6>
                  </div>
                  <div className="col-md-6">
                    <span className="price-head text-green">Reference</span>
                    <h6 className="details">{transactions?.reference}</h6>
                  </div>
                  <div className="col-md-6">
                    <span className="price-head text-green">Amount</span>
                    <h6 className="details">₦ {transactions?.amount}</h6>
                  </div>
                  <div className="col-md-6">
                    <span className="price-head text-green">Description</span>
                    <h6 className="details">{transactions?.description}</h6>
                  </div>
                  <div className="col-md-6">
                    <span className="price-head text-green">Mode</span>
                    <h6 className="details">{transactions?.mode}</h6>
                  </div>
                  <div className="col-md-6">
                    <span className="price-head">Type</span>
                    <h6
                      className="details"
                      style={{
                        color:
                          transactions?.type === "credit"
                            ? "#29CC97"
                            : transactions?.type === "debit"
                            ? "#DC1515"
                            : "#FEC400",
                      }}
                    >
                      {transactions?.type}
                    </h6>
                  </div>
                  <div className="col-md-6">
                    <span className="price-head">Status</span>
                    <h6
                      className="details"
                      style={{
                        color:
                          transactions?.status === "success"
                            ? "#29CC97"
                            : transactions?.status === "pending"
                            ? "#FEC400"
                            : "#DC1515",
                      }}
                    >
                      {transactions?.status}
                    </h6>
                  </div>
                  <div className="col-md-6">
                    <span className="price-head text-green">Date Updated</span>
                    <h6 className="details">{transactions?.updated_at}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              {transactions?.type === 'debit' && transactions?.status === 'pending' && <button
                className="send"
                onClick={() => {
                  refund()
                }}
                disabled={disableButton}
              >
                Refund
                {buttonLoad && (
                  <div
                    className="spinner-border text-light mx-3"
                    style={{
                      width: "20px",
                      height: "20px",
                    }}
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default TransactionDetails
