import { Line } from 'react-chartjs-2'
import { Chart } from 'chart.js'

const LineChart = () => {
  return (
    <div>
      <Line
                        data={{
                          labels: [
                            "Jan",
                            "Feb",
                            "Mar",
                            "Apr",
                            "May",
                            "Jun",
                            "Jul",
                          ],
                          datasets: [
                            {
                              label: "Users",
                              data: [
                                30, 10, 35, 50, 20, 41,
                                48,
                              ],
                              backgroundColor: ["transparent"],
                              borderColor: ["#059669"],
                              borderWidth: 2,
                            },
                            {
                              label: "Drivers",
                              data: [33, 25, 35, 51, 54, 66, 23],
                              borderWidth: 2,
                              fill: false,
                              borderColor: "#D97706"
                            }
                          ],
                        }}
                        height={220}
                        width={300}
                        options={{
                          maintainAspectRatio: false,
                          scales: {
                            yAxes: [
                              {
                                ticks: {
                                  beginAtZero: true,
                                  stepSize: 20,
                                },
                              },
                            ],
                            xAxes: [
                              {
                                gridLines: {
                                  drawBorder: false,
                                  display: false,
                                },
                              },
                            ],
                          },
                        }}
                      />
    </div>
  )
}

export default LineChart