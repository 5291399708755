import React, { useMemo } from "react"
import { useState } from "react"
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  Autocomplete,
  DirectionsRenderer,
  Circle,
} from "@react-google-maps/api"
import { FaLocationArrow, FaTimes } from "react-icons/fa"
import { useRef } from "react"

import bike from "../../assets/images/bike.png"
import redBike from "../../assets/images/bike2.png"
import packages from "../../assets/images/package.png"
import { Link } from "react-router-dom"

const center = {
  lat: 7.7307739,
  lng: 5.1301063,
}

const Map = ({ partnerLocation, pendingOrders }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API,
    libraries: ["places"],
  })

  let orderPending = pendingOrders?.map(order => order.pickup_coordinates)

  const [map, setMap] = useState(/** @type google.maps.Map */ null)
  const [directions, setDirections] = useState(null)
  const [distance, setDistance] = useState("")
  const [duration, setDuration] = useState("")
  const [coordinates, setCoordinates] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [singleOrder, setSingleOrder] = useState({})
  const [showPartnerModal, setShowPartnerModal] = useState(false)
  const [singlePartner, setSinglePartner] = useState({})

  const showOrder = singleOrder => {
    setSingleOrder(singleOrder)
  }

  const showPartner = singlePartner => {
    setSinglePartner(singlePartner)
  }

  const originRef = useRef()

  const destinationRef = useRef()
  const options = useMemo(
    () => ({
      // mapId: "b181cac70f27f5e6",
      disableDefaultUI: true,
      clickableIcons: false,
    }),
    []
  )

  if (!isLoaded) {
    return "loading...."
  }

  async function calculateRoute() {
    if (originRef.current.value === "" || destinationRef.current.value === "") {
      return
    }
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService()
    const results = await directionsService.route({
      origin: originRef.current.value,
      destination: destinationRef.current.value,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    })
    setDirections(results)
    const lat = results.routes[0].legs[0].start_location.lat()
    const lng = results.routes[0].legs[0].start_location.lng()
    setCoordinates(`${lat}, ${lng}`)
    setDistance(results.routes[0].legs[0].distance.text)
    setDuration(results.routes[0].legs[0].duration.text)
  }

  return (
    <div className="container-fluid">
      <h5>Pending Packages</h5>
      <div className="row">
        {/* <div className="col-md-12 d-flex justify-content-center">
            <div className="search py-3">
              <div className="card" style={{ background: "#00000080" }}>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <Autocomplete>
                        <input
                          className="form-control shadow-none mb-1"
                          type="text"
                          placeholder="origin"
                          ref={originRef}
                        />
                      </Autocomplete>
                    </div>
                    <div className="col-md-6">
                      <Autocomplete>
                        <input
                          className="form-control shadow-none mb-1"
                          type="text"
                          placeholder="destination"
                          ref={destinationRef}
                        />
                      </Autocomplete>
                    </div>
                    <div className="col">
                      <span className="text-white">
                        clear <FaTimes onClick={clearRoute} />
                      </span>
                    </div>
                    <div className="col">{distance}</div>
                    <div className="col">{duration}</div>
                  </div>

                  <button
                    className="submit-btn float-end"
                    type="submit"
                    onClick={calculateRoute}
                  >
                    Search
                  </button>
                  <div className="">
                    <FaLocationArrow
                      className="text-white"
                      onClick={() => map.panTo(center)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
      </div>
      <div className="row mb-5 mt-2">
        <div className="map" style={{ height: "100vh", width: "100vw" }}>
          <GoogleMap
            center={center}
            zoom={8}
            mapContainerStyle={{ width: "100%", height: "100%" }}
            onLoad={map => setMap(map)}
            options={options}
          >
            <>
              {partnerLocation?.map(partner => {
                return (
                  <>
                    <Marker
                      key={partner.id}
                      position={{
                        lat: +partner?.coordinates.split(",")[0],
                        lng: +partner?.coordinates.split(",")[1],
                      }}
                      icon={partner?.is_online ? bike : redBike}
                      onClick={() => {
                        setShowPartnerModal(true)
                        showPartner(partner)
                      }}
                    />
                    <div className="row">
                      {showPartnerModal && (
                        <div
                          className="col-md-6 py-2 px-5 rounded"
                          style={{
                            display: "flex",
                            transition: "all 700ms ease-in-out",
                            transform: "translate(250px, 25px)",
                            zIndex: "10000",
                          }}
                        >
                          {partner?.id === singlePartner?.id && (
                            <div className="bg-white p-3 rounded">
                              <span
                                className="d-flex justify-content-end"
                                onClick={() => {
                                  setShowPartnerModal(false)
                                }}
                              >
                                <button
                                  className="btn btn-close"
                                  style={{
                                    fontSize: "9px",
                                  }}
                                ></button>
                              </span>
                              <hr />
                              <span>{singlePartner?.name}</span>
                              <br />
                              <span>{singlePartner?.email}</span> <br />
                              <span>{singlePartner?.phone}</span>
                              <br />
                              <div className="d-flex justify-content-end">
                                <Link
                                  to={`partner/${singlePartner?.id}`}
                                  className="btn btn-success mt-2"
                                >
                                  More details
                                </Link>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </>
                )
              })}
              {/* <Circle center={center} radius={1500} options={closeOptions} />
              <Circle center={center} radius={3000} options={middleOptions} />
              <Circle center={center} radius={4500} options={farOptions} /> */}
              {pendingOrders?.map(order => {
                const {
                  pickup_coordinates,
                  uuid,
                  pickup_address,
                  payment_status,
                } = order
                return (
                  <>
                    {payment_status === "completed" && (
                      <>
                        <Marker
                          key={uuid}
                          position={{
                            lat: +pickup_coordinates.split(",")[0],
                            lng: +pickup_coordinates.split(",")[1],
                          }}
                          icon={packages}
                          onClick={() => {
                            setShowModal(true)
                            showOrder(order)
                          }}
                        />
                        <div className="row">
                          {showModal && (
                            <div
                              className="col-md-6 py-2 px-5 rounded"
                              style={{
                                display: "flex",
                                transition: "all 700ms ease-in-out",
                                transform: "translate(250px, 250px)",
                                zIndex: "10000",
                              }}
                            >
                              {uuid === singleOrder?.uuid && (
                                <div className="bg-white p-3 rounded">
                                  <span
                                    className="d-flex justify-content-end"
                                    onClick={() => {
                                      setShowModal(false)
                                    }}
                                  >
                                    <button
                                      className="btn btn-close"
                                      style={{
                                        fontSize: "9px",
                                      }}
                                    ></button>
                                  </span>
                                  <hr />
                                  <span>
                                    {singleOrder?.package?.label} (
                                    {singleOrder?.package?.size})
                                  </span>
                                  <br />
                                  <small className="fw-bold">pickup</small>{" "}
                                  <br />
                                  <span>
                                    {singleOrder?.pickup_address}
                                  </span>{" "}
                                  <br />
                                  <small className="fw-bold">
                                    dropoff
                                  </small>{" "}
                                  <br />
                                  <span>
                                    {singleOrder?.destination_local_government},{" "}
                                    {singleOrder?.destination_state}
                                  </span>{" "}
                                  <br />
                                  <div className="d-flex justify-content-end">
                                    <Link
                                      to={`order/${uuid}`}
                                      className="btn btn-success"
                                    >
                                      More details
                                    </Link>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </>
                )
              })}
            </>
            {directions && <DirectionsRenderer directions={directions} />}
          </GoogleMap>
        </div>
      </div>
    </div>
  )
}

const defaultOptions = {
  strokeOpacity: 0.5,
  strokeWeight: 2,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
}
const closeOptions = {
  ...defaultOptions,
  zIndex: 3,
  fillOpacity: 0.05,
  strokeColor: "#8BC34A",
  fillColor: "#8BC34A",
}
const middleOptions = {
  ...defaultOptions,
  zIndex: 2,
  fillOpacity: 0.05,
  strokeColor: "#FBC02D",
  fillColor: "#FBC02D",
}
const farOptions = {
  ...defaultOptions,
  zIndex: 1,
  fillOpacity: 0.05,
  strokeColor: "#FF5252",
  fillColor: "#FF5252",
}

export default Map
