import { useEffect, useState } from "react"

import { FaSpinner } from "react-icons/fa"
import accessToken from "../../helpers/jwt-token-access/accessToken"
import { toast } from "react-toastify"
import baseURL from "../../utils"
import { Autocomplete } from "@react-google-maps/api"

const CreateOrder = ({ userId, profile }) => {
  const [label, setLabel] = useState("")
  const [token, setToken] = useState("")
  const [spinner, setSpinner] = useState(false)
  const [sizes, setSizes] = useState(null)
  const [productSize, setProductSize] = useState("")
  const [fragile, setFragile] = useState(null)
  const [packageNote, setPackageNote] = useState("")
  const [recipientNote, setRecipientNote] = useState("")
  const [pickupAddress, setPickupAddress] = useState("")
  const [destinationAddress, setDestinationAddress] = useState("")
  const [destinationLocalGovernment, setDestinationLocalGovernment] =
    useState("")
  const [pickupContactName, setPickupContactName] = useState("")
  const [pickupContactEmail, setPickupContactEmail] = useState("")
  const [pickupContactPhone, setPickupContactPhone] = useState("")
  const [recipientContactName, setRecipientContactName] = useState("")
  const [recipientContactEmail, setRecipientContactEmail] = useState("")
  const [recipientContactPhone, setRecipientContactPhone] = useState("")
  const [disableButton, setDisableButton] = useState(false)
  const [image, setImage] = useState(null)
  const [imageId, setImageId] = useState([])
  const [alternate, setAlternate] = useState(false)
  const [pickupTime, setPickupTime] = useState(null)
  const [originHubId, setOriginHubId] = useState("")
  const [destinationHubId, setDestinationHubId] = useState("")

  const [variants, setVariants] = useState([])
  const [variant, setVariant] = useState("")
  const [showShipping, setShowShipping] = useState(false)
  const [loadingPrice, setLoadingPrice] = useState(false)

  const [selectedItem, setSelectedItem] = useState(null)

  const handleClick = variant => {
    // Check if the clicked item is already selected
    if (selectedItem === variant) {
      // If it is, deselect it
      setSelectedItem(null)
    } else {
      // If it's not selected, clear previous selection and select the new variant
      setSelectedItem(variant)
      setDisableButton(false)
    }
  }

  const [pickupAvailable, setPickupAvailable] = useState("")
  const [destinationAvailable, setDestinationAvailable] = useState("")
  const [autocomplete, setAutocomplete] = useState(null)
  const [autocompleteDestination, setAutocompleteDestination] = useState(null)

  const [pickup] = useState({
    lat: null,
    lng: null,
    state: null,
  })
  const [destination] = useState({
    lat: null,
    lng: null,
    state: null,
  })

  // Fetch access token
  useEffect(() => {
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    }
    fetch(`${baseURL}/api/v1/fetch-access-token`, options)
      .then(data => data.json())
      .then(response => {
        setToken(response.data.access_token)
      })
      .catch(error => {
        console.log(error.message)
      })
  }, [])

  // Upload image to google drive
  const uploadImage = image => {
    setSpinner(true)
    // setDisableButton(true)
    const file = image
    const fr = new FileReader()
    fr.readAsArrayBuffer(file)
    fr.onload = f => {
      const fileMetadata = {
        name: file.name,
        parents: ["13lMB0X5aIfc4vFcklI3k2gH17sNwNoZ4"],
      }
      const form = new FormData()
      form.append(
        "metadata",
        new Blob([JSON.stringify(fileMetadata)], { type: "application/json" })
      )
      form.append(
        "file",
        new Blob([new Uint8Array(f.target.result)], { type: file.type })
      )
      const options = {
        method: "POST",
        headers: new Headers({ Authorization: `Bearer ${token}` }),
        "Content-Type": "multipart/form-data",
        body: form,
      }
      fetch(
        "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart",
        options
      )
        .then(response => response.json())
        .then(response => {
          let image_id = []
          image_id.push(response.id)
          setImageId(image_id)
          setSpinner(false)
          setDisableButton(false)
        })
        .catch(error => console.log(error, "error message"))
    }
  }

  // Fetch package size
  useEffect(() => {
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    }
    fetch(`${baseURL}/api/v1/sizes`, options)
      .then(data => data.json())
      .then(response => {
        setSizes(response.data)
      })
      .catch(error => {
        console.log(error.message)
      })
  }, [])

  const onLoad = auto => {
    setAutocomplete(auto)
    // Set the country restriction to Nigeria
    auto.setComponentRestrictions({ country: "NG" })
  }

  const onLoadDestination = auto => {
    setAutocompleteDestination(auto)
    // Set the country restriction to Nigeria
    auto.setComponentRestrictions({ country: "NG" })
  }

  // handle place change for pickup
  const onPickupPlaceChanged = () => {
    setLoadingPrice(true)
    setPickupAvailable("")
    if (autocomplete !== null) {
      const address = autocomplete?.getPlace()

      if (address.geometry) {
        const lat = address?.geometry?.location.lat()
        const lng = address?.geometry?.location.lng()

        setPickupAddress(
          `${address?.name}${address?.formatted_address.replace(
            address?.formatted_address.split(",")[0],
            ""
          )}`
        )

        let pickupState =
          address.formatted_address.split(",")[
            address.formatted_address.split(",").length - 2
          ]

        pickup.lat = lat
        pickup.lng = lng
        pickup.state = pickupState

        checkPickupLocation()
      }
    } else {
      console.log("Autocomplete is not loaded yet!")
    }
  }

  const checkPickupLocation = () => {
    setPickupAvailable("")
    const options = {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        state: pickup.state,
        coordinates: `${pickup.lat}, ${pickup.lng}`,
      }),
    }
    fetch(`${baseURL}/api/v1/misc/confirm-location`, options)
      .then(data => data.json())
      .then(response => {
        if (response.status !== "success") {
          setPickupAvailable(response.message)
        } else if (productSize && destination.state && fragile) {
          calculatePrice()
          setLoadingPrice(false)
        }
      })
      .catch(error => {
        console.log(error.message)
      })
  }

  // handle place change for destination
  const onDestinationPlaceChanged = () => {
    setLoadingPrice(true)
    setDestinationAvailable("")
    if (autocompleteDestination !== null) {
      const address = autocompleteDestination?.getPlace()

      if (address.geometry) {
        const lat = address?.geometry?.location.lat()
        const lng = address?.geometry?.location.lng()

        // Find the state in the address components
        if (
          address?.address_components.find(component =>
            component.types.includes("administrative_area_level_2")
          )
        ) {
          const lgaName = address?.address_components.find(component =>
            component.types.includes("administrative_area_level_2")
          ).long_name
          console.log(lgaName)
          setDestinationLocalGovernment(lgaName)
        }

        setDestinationAddress(
          `${address?.name}${address?.formatted_address.replace(
            address?.formatted_address.split(",")[0],
            ""
          )}`
        )

        let destinationState =
          address.formatted_address.split(",")[
            address.formatted_address.split(",").length - 2
          ]

        destination.lat = lat
        destination.lng = lng
        destination.state = destinationState

        checkDestinationLocation()
      }
    } else {
      console.log("Autocomplete is not loaded yet!")
    }
  }

  const checkDestinationLocation = () => {
    setDestinationAvailable("")
    const options = {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        state: destination.state,
        coordinates: `${destination.lat}, ${destination.lng}`,
      }),
    }
    fetch(`${baseURL}/api/v1/misc/confirm-location`, options)
      .then(data => data.json())
      .then(response => {
        if (response.status !== "success") {
          setDestinationAvailable(response.message)
        } else if (productSize && pickup.state && fragile) {
          calculatePrice()
        }
      })
      .catch(error => {
        console.log(error.message)
      })
  }

  // Calculate Pricing
  const calculatePrice = async e => {
    e?.preventDefault()
    setDisableButton(true)
    setLoadingPrice(true)
    const options = {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        size_id: productSize,
        pickup_coordinates: `${pickup.lat}, ${pickup.lng}`,
        destination_coordinates: `${destination.lat}, ${destination.lng}`,
        pickup_state: pickup.state,
        destination_state: destination.state,
        is_fragile: fragile,
      }),
    }
    await fetch(`${baseURL}/api/v1/pricing`, options)
      .then(data => data.json())
      .then(response => {
        console.log(response)
        setVariants(response.data)
        setShowShipping(true)
        setLoadingPrice(false)
      })
      .catch(error => {
        console.log(error.message)
        setDisableButton(false)
        setLoadingPrice(false)
      })
  }

  // hub to hub
  const addHub = (dropOff, destination, key) => {
    setOriginHubId(dropOff?.uuid)
    setDestinationHubId(destination?.uuid)
    console.log(dropOff.uuid, destination?.uuid, key)
    setVariant(key)
  }

  // hub to door
  const addDoor = (dropOff, key) => {
    setOriginHubId(dropOff?.uuid)
    setVariant(key)
  }

  // door to hub
  const addDoorHub = (destination, key) => {
    setDestinationHubId(destination?.uuid)
    setVariant(key)
  }
  // Create new order for user

  const handleSubmit = async e => {
    setDisableButton(true)
    e.preventDefault()

    let info = {
      user_id: userId,
      pickup_address: pickupAddress,
      pickup_coordinates: `${pickup.lat}, ${pickup.lng}`,
      destination_address: destinationAddress,
      destination_coordinates: `${destination.lat}, ${destination.lng}`,
      size_id: productSize,
      label,
      package_note: packageNote,
      is_fragile: fragile,
      images: imageId,
      is_user: 1,
      name: recipientContactName,
      phone: recipientContactPhone,
      email: recipientContactEmail,
      recipient_note: recipientNote,
      destination_state: destination.state,
      destination_local_government: destinationLocalGovernment,
      pickup_state: pickup.state,
      payment_channel: "others",
      alternate,
      order_type: `${pickupContactName === "" ? "delivery" : "pickup"}`,
      pickup_time: pickupTime,
      variant,
      origin_hub_id: originHubId,
      destination_hub_id: destinationHubId,
    }
    if (pickupContactName.trim() !== "") {
      info.pickup_contact_name = pickupContactName
      info.pickup_contact_phone = pickupContactPhone
      info.pickup_contact_email = pickupContactEmail
    }
    try {
      console.log(info)
      const options = {
        method: "POST",
        headers: new Headers({
          Authorization: accessToken,
          "Content-Type": "application/json",
          accept: "application/json",
        }),

        body: JSON.stringify(info),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/users/${userId}/orders`,
        options
      )
      const data = await response.json()
      console.log(data)
      if (data.status === "success") {
        toast.success(data["message"])
        setDisableButton(false)
        setTimeout(() => {
          window.location.href = "/all-orders"
        }, 3000)
      } else {
        toast.error(data["message"])
        setDisableButton(false)
      }
    } catch (error) {
      // do what
      console.log(error)
      setDisableButton(false)
    }
  }

  return (
    <div className="">
      <form onSubmit={handleSubmit}>
        <>
          <div className="row">
            <h4 className="mb-4">Create Order</h4>
            <div className="col-md-6 mb-4">
              <label className="mb-2">Pickup address</label>
              <Autocomplete
                onLoad={onLoad}
                onPlaceChanged={onPickupPlaceChanged}
              >
                <input
                  type="text"
                  className="h-[44.13px]"
                  placeholder="Type location"
                  onChange={setPickupAddress}
                  required
                />
              </Autocomplete>
              <small className="text-danger">{pickupAvailable}</small>
            </div>
            <div className="col-md-6 mb-4">
              <label className="mb-2">Destination address</label>
              <Autocomplete
                onLoad={onLoadDestination}
                onPlaceChanged={onDestinationPlaceChanged}
              >
                <input
                  type="text"
                  placeholder="Type location"
                  className="h-[44.13px]"
                  onChange={setDestinationAddress}
                  required
                />
              </Autocomplete>
              <small className="text-danger">{destinationAvailable}</small>
            </div>
            <div className="col-md-6 mb-4">
              <label className="mb-2">Select size of item</label>
              <select
                className="form-select shadow-none"
                value={productSize}
                onChange={e => setProductSize(e.target.value)}
              >
                <option value="small">--select size--</option>
                {sizes?.map(size => {
                  const { id, uuid, name, description } = size
                  return (
                    <option className="" key={id} value={uuid}>
                      {name} ({description})
                    </option>
                  )
                })}
              </select>
            </div>
            <div className="col-md-6 mb-4">
              <label className="mb-2">Is item fragile</label>
              <select
                className="form-select shadow-none"
                value={fragile}
                onChange={e => setFragile(e.target.value)}
                required
              >
                <option value="">Is item Fragile?</option>
                <option value={1}>yes</option>
                <option value={0}>no</option>
              </select>
            </div>
          </div>
          {!showShipping && (
            <div className="row">
              <div className="d-flex justify-content-end">
                <div>
                  <button
                    disabled={disableButton}
                    className="btn btn-success"
                    onClick={calculatePrice}
                  >
                    Proceed
                  </button>
                </div>
              </div>
            </div>
          )}
        </>

        {showShipping && (
          <>
            <div className="container border my-3 p-3 rounded">
              {loadingPrice ? (
                <div className="d-flex justify-content-center align-items-center my-5">
                  <div class="spinner-border text-success" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <h5 className="my-4">Select Shipping Options</h5>
                  {variants?.map((variant, index) => {
                    const key = Object.keys(variant)[0] // Get the key type (HUB_TO_HUB, HUB_TO_DOOR, etc.)
                    const value = variant[key] // Extract the data object
                    const {
                      formatted_price,
                      destination_hub,
                      dropoff_hub,
                      discounted_price,
                    } = value // Destructure necessary values
                    return (
                      <>
                        <div
                          className="col-md-6 mb-3"
                          onClick={() => {
                            handleClick(variant)
                            {
                              key === "HUB_TO_HUB"
                                ? addHub(dropoff_hub, destination_hub, key)
                                : key === "HUB_TO_DOOR"
                                ? addDoor(dropoff_hub, key)
                                : key === "DOOR_TO_HUB"
                                ? addDoorHub(destination_hub, key)
                                : setVariant("DOOR_TO_DOOR")
                            }
                          }}
                        >
                          <div
                            className={`${
                              selectedItem === variant ? "custom-border" : ""
                            }`}
                            style={{
                              background: "#FAFAFA",
                              padding: "13px",
                              borderRadius: "10px",
                              cursor: "pointer",
                            }}
                          >
                            <h6
                              style={{
                                color: "#000",
                                fontSize: "14px",
                                fontWeight: "700",
                              }}
                            >
                              {key
                                ?.toString()
                                .replace("_", " ")
                                .replace("_", " ")
                                .toLowerCase()}
                            </h6>

                            {discounted_price && (
                              <p className="fw-bold text-green">
                                ₦ {discounted_price}
                              </p>
                            )}
                            <h6
                              className={`${
                                discounted_price
                                  ? "text-danger text-decoration-line-through"
                                  : ""
                              } m-0 fw-bold`}
                            >
                              ₦ {formatted_price}
                            </h6>
                          </div>
                        </div>
                      </>
                    )
                  })}
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-md-6 mb-4">
                <input
                  type="text"
                  value={label}
                  onChange={e => setLabel(e.target.value)}
                  required
                  placeholder="Enter name of item"
                />
              </div>
              <div className="col-md-6 mb-4">
                <input
                  className="form-control shadow-none"
                  type="file"
                  name="file"
                  accept="image/png, image/gif, image/jpeg, image/jpg"
                  onChange={e => {
                    setImage(e.target.files[0])
                    uploadImage(e.target.files[0])
                  }}
                  required
                />
                {spinner && (
                  <small className="text-green">
                    <FaSpinner size={15} /> uploading...
                  </small>
                )}
              </div>
              <div className="col-md-6 mb-4">
                <textarea
                  className="form-control"
                  rows={2}
                  type="textarea"
                  placeholder="Add a note for the package..."
                  value={packageNote}
                  onChange={e => setPackageNote(e.target.value)}
                />
              </div>
              <div className="col-md-6 mb-4">
                <textarea
                  className="form-control"
                  rows={2}
                  type="textarea"
                  placeholder="Add a note for the recipient..."
                  value={recipientNote}
                  onChange={e => setRecipientNote(e.target.value)}
                />
              </div>
              <div className="col-md-6 mb-4">
                <label>
                  Schedule pickup{" "}
                  <span className="small text-orange">(optional)</span>
                </label>
                <input
                  type="datetime-local"
                  value={pickupTime}
                  onChange={e => {
                    let newPickup = e.target.value
                    newPickup = `${newPickup.replace("T", " ")}:00`
                    setPickupTime(newPickup)
                  }}
                  placeholder="Schedule pick up"
                />
              </div>
            </div>

            <div className="row mt-4">
              <h4 className="mb-4">Recipient</h4>
              <div className="col-md-6 mb-4">
                <input
                  type="text"
                  value={recipientContactName}
                  onChange={e => setRecipientContactName(e.target.value)}
                  placeholder="Enter recipient name"
                  required
                />
              </div>
              <div className="col-md-6 mb-4">
                <input
                  type="text"
                  value={recipientContactEmail}
                  onChange={e => setRecipientContactEmail(e.target.value)}
                  placeholder="Enter recipient email"
                />
              </div>
              <div className="col-md-6 mb-4">
                <input
                  type="text"
                  value={recipientContactPhone}
                  onChange={e => setRecipientContactPhone(e.target.value)}
                  placeholder="Enter recipient phone"
                  required
                />
              </div>
            </div>
            <div className="row mt-4">
              <h4 className="mb-4">
                Pickup Contact <small className="small">(optional)</small>
              </h4>
              <div className="col-md-6 mb-4">
                <input
                  type="text"
                  value={pickupContactName}
                  onChange={e => setPickupContactName(e.target.value)}
                  placeholder="Enter pickup contact name"
                />
              </div>
              <div className="col-md-6 mb-4">
                <input
                  type="text"
                  value={pickupContactEmail}
                  onChange={e => setPickupContactEmail(e.target.value)}
                  placeholder="Enter pickup contact email"
                />
              </div>
              <div className="col-md-6 mb-4">
                <input
                  type="text"
                  value={pickupContactPhone}
                  onChange={e => setPickupContactPhone(e.target.value)}
                  placeholder="Enter pickup contact phone"
                />
              </div>
            </div>
            {/* {showAlternateRecipient && (
              <div className="row mt-4">
                <h4 className="mb-4">Alternate Recipient</h4>
                <div className="col-md-6 mb-4">
                  <input
                    type="text"
                    value={alternateRecipientName}
                    onChange={e => setAlternateRecipientName(e.target.value)}
                    placeholder="Enter alternate recipient name"
                    required
                  />
                </div>
                <div className="col-md-6 mb-4">
                  <input
                    type="text"
                    value={alternateRecipientEmail}
                    onChange={e => setAlternateRecipientEmail(e.target.value)}
                    placeholder="Enter alternate recipient email"
                    required
                  />
                </div>
                <div className="col-md-6 mb-4">
                  <input
                    type="text"
                    value={alternateRecipientPhone}
                    onChange={e => setAlternateRecipientPhone(e.target.value)}
                    placeholder="Enter alternate recipient phone"
                    required
                  />
                </div>
                <div className="col-md-6 mb-4">
                  <PlacesAutocomplete
                    value={alternateAddress}
                    onChange={setAlternateAddress}
                    onSelect={handleSelectAlternate}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <input
                          {...getInputProps({
                            placeholder: "Enter Alternate Address",
                            className: "form-control shadow-none",
                          })}
                        />
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion, index) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item"
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                }
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                                key={suggestion.index}
                              >
                                <span>
                                  <FaMapPin className="text-green mx-1" />{" "}
                                  {suggestion.description}
                                </span>
                                <hr />
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                  <small className="text-danger">{availableAlternate}</small>
                </div>
                <div className="col-md-6 mb-4">
                  <textarea
                    className="form-control"
                    rows={2}
                    type="textarea"
                    placeholder="Add a note for alternate recipient..."
                    required
                    value={alternateRecipientNote}
                    onChange={e => setAlternateRecipientNote(e.target.value)}
                  />
                </div>
              </div>
            )} */}
            {/* <div className="form-check mx-4">
              <Input
                type="checkbox"
                onChange={e => e.target.checked}
                onClick={() =>
                  setShowAlternateRecipient(!showAlternateRecipient)
                }
              />
              {showAlternateRecipient
                ? "Remove alternate Recipient"
                : "Add alternate Recipient"}
            </div> */}

            <div className="d-flex justify-content-end">
              <button type="submit" className="send" disabled={disableButton}>
                {disableButton ? "Creating..." : "Create Order"}
              </button>
            </div>
          </>
        )}
      </form>
    </div>
  )
}

export default CreateOrder
