import accessToken from "../../helpers/jwt-token-access/accessToken"
import { useEffect, useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import baseURL from "../../utils"
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete"

import { FaLocationArrow } from "react-icons/fa"

const CreateHub = () => {
  const [details, setDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    name: "",
    stateId: "",
    lga: "",
    location: "",
  })
  const [states, setStates] = useState([])
  const [lgas, setLgas] = useState([])
  const [loading, setLoading] = useState(true)
  const [disableButton, setDisableButton] = useState(false)
  const [errors, setErrors] = useState({})
  const [error, setError] = useState("")
  const [address, setAddress] = useState("")
  const [available, setAvailable] = useState(null)
  const [coordinates, setCoordinates] = useState("")
  const [locations, setLocations] = useState([])

  // Fetch states
  useEffect(() => {
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    }
    fetch(`${baseURL}/api/v1/misc/states`, options)
      .then(data => data.json())
      .then(response => {
        setLoading(false)
        console.log(response.data)
        setStates(response.data)
      })
      .catch(error => {
        console.log(error.message)
        setLoading(false)
      })
  }, [])

  // Fetch LGA
  const fetchLgas = async id => {
    setLoading(true)
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    }
    await fetch(`${baseURL}/api/v1/misc/local-governments/${id}`, options)
      .then(data => data.json())
      .then(response => {
        setLoading(false)
        setLgas(response.data)
      })
      .catch(error => {
        console.log(error.message)
        setLoading(false)
      })
  }

  // Fetch locations
  useEffect(() => {
    const options = {
      method: "GET",
      headers: new Headers({
        Authorization: accessToken,
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    }
    fetch(`${baseURL}/api/v1/admin/locations`, options)
      .then(data => data.json())
      .then(response => {
        setLoading(false)
        console.log(response.data)
        setLocations(response.data)
      })
      .catch(error => {
        console.log(error.message)
        setLoading(false)
      })
  }, [])

  // Handle select in google map
  const handleSelect = async value => {
    const results = await geocodeByAddress(value)
    setAddress(results[0].formatted_address)

    const ll = await getLatLng(results[0])

    let Coord = `${ll.lat}, ${ll.lng}`
    setCoordinates(Coord)

    let state =
      results[0].formatted_address.split(",")[
        results[0].formatted_address.split(",").length - 2
      ]

    checkLocation(state, Coord)
  }

  // check pickup location availability
  const checkLocation = (state, coordinates) => {
    setAvailable("")
    const options = {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
      body: JSON.stringify({
        state,
        coordinates,
      }),
    }
    fetch(`${baseURL}/api/v1/misc/confirm-location`, options)
      .then(data => data.json())
      .then(response => {
        if (response.status !== "success") {
          toast.error(response.message)
          setAvailable(response.message)
        } else {
        }
      })
      .catch(error => {
        console.log(error.message)
      })
  }

  // Create a new hub
  const handleSubmit = async e => {
    e.preventDefault()
    // do stuff
    setDisableButton(true)
    setErrors({})
    try {
      const options = {
        method: "POST",
        headers: new Headers({
          Authorization: accessToken,
          "Content-Type": "application/json",
          accept: "application/json",
        }),
        body: JSON.stringify({
          manager_first_name: details.firstName,
          manager_last_name: details.lastName,
          manager_email: details.email,
          manager_phone: details.phone,
          name: details.name,
          state_id: details.stateId,
          local_government_id: details.lga,
          address,
          coordinates,
          location_id: details.location,
        }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/delivery-hubs`,
        options
      )
      const data = await response.json()
      console.log(data)

      if (data.errors) {
        toast.error(data["message"])
        setDisableButton(false)
        setErrors(data.errors)
      } else {
        toast.success(data["message"])
        setTimeout(() => {
          window.location.href = "/delivery-hub"
        }, 5000)
      }
    } catch (error) {
      // do what
      console.log(error)
      setDisableButton(false)
    }
  }

  return (
    <section className="mt-5">
      <ToastContainer />
      <div className="container">
        <div className="row my-5 d-flex justify-content-center">
          <div className="col-md-11 mt-5">
            <div className="card">
              <div className="card-body">
                <h3 className="text-green m-lg-5">Create A New Hub Account</h3>
                <div className="row m-lg-5">
                  <div className="col-md-12">
                    <form onSubmit={handleSubmit}>
                      <div className="row mt-4">
                        <h6 className="text-secondary">Hub Details</h6>
                        <hr />
                        <div className="col-md-6 mb-4">
                          <label
                            htmlFor="hubName"
                            className="fw-bold text-green"
                          >
                            Hub Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter hub name"
                            value={details.name}
                            onChange={e =>
                              setDetails({
                                ...details,
                                name: e.target.value,
                              })
                            }
                            required
                          />
                          <small className="text-danger">{errors?.name}</small>
                        </div>
                        <div className="col-md-6 mb-4">
                          <label htmlFor="state" className="fw-bold text-green">
                            Location
                          </label>
                          <select
                            required
                            type="text"
                            value={details.location}
                            className={`${
                              error?.state_id ? "border-danger" : ""
                            } form-select shadow-none`}
                            onChange={e => {
                              setDetails({
                                ...details,
                                location: e.target.value,
                              })
                            }}
                          >
                            <option value="">Select Location</option>
                            {loading
                              ? "Loading..."
                              : locations?.map(location => {
                                  const { name, uuid } = location
                                  return (
                                    <option key={uuid} value={uuid}>
                                      {name}
                                    </option>
                                  )
                                })}
                          </select>
                        </div>
                        <div className="col-md-6 mb-4">
                          <label htmlFor="state" className="fw-bold text-green">
                            State
                          </label>
                          <select
                            required
                            type="text"
                            value={details.stateId}
                            className={`${
                              error?.state_id ? "border-danger" : ""
                            } form-select shadow-none`}
                            onChange={e => {
                              setDetails({
                                ...details,
                                stateId: e.target.value,
                              })
                              // setSelectedState(e.target.selectedIndex)
                              fetchLgas(e.target.value)
                            }}
                          >
                            <option value="">select State</option>
                            {loading
                              ? "Loading..."
                              : states?.map(state => {
                                  const { name, id } = state
                                  return (
                                    <option key={id} value={id}>
                                      {name}
                                    </option>
                                  )
                                })}
                          </select>
                        </div>
                        <div className="col-md-6 mb-4">
                          <label htmlFor="lga" className="fw-bold text-green">
                            Local Government
                          </label>
                          <select
                            required
                            type="text"
                            className={`${
                              error?.state_id ? "border-danger" : ""
                            } form-select shadow-none`}
                            value={details.lga}
                            onChange={e =>
                              setDetails({ ...details, lga: e.target.value })
                            }
                          >
                            <option value="">Select LGA</option>
                            {lgas?.map(lga => {
                              const { name, id } = lga
                              return (
                                <option key={id} value={id}>
                                  {name}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                        <div className="col-md-12 mb-4">
                          <label
                            htmlFor="address"
                            className="fw-bold text-green"
                          >
                            Address
                          </label>
                          <PlacesAutocomplete
                            value={address}
                            onChange={setAddress}
                            onSelect={handleSelect}
                            required
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div>
                                <input
                                  {...getInputProps({
                                    placeholder: "Enter Hub Address",
                                    className: "form-control shadow-none",
                                  })}
                                />
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map((suggestion, index) => {
                                    const className = suggestion.active
                                      ? "suggestion-item--active"
                                      : "suggestion-item"
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                      ? {
                                          backgroundColor: "#fafafa",
                                          cursor: "pointer",
                                        }
                                      : {
                                          backgroundColor: "#ffffff",
                                          cursor: "pointer",
                                        }
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                        key={suggestion.index}
                                      >
                                        <span>
                                          <FaLocationArrow className="text-green mx-1" />{" "}
                                          {suggestion.description}
                                        </span>
                                        <hr />
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                          <small className="text-danger">{available}</small>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <h6 className="text-secondary">Manager</h6>
                        <hr />
                        <div className="col-md-6 mb-4">
                          <label
                            htmlFor="firstName"
                            className="fw-bold text-green"
                          >
                            First Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Ayodele"
                            value={details.firstName}
                            onChange={e =>
                              setDetails({
                                ...details,
                                firstName: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                        <div className="col-md-6 mb-4">
                          <label
                            htmlFor="lastName"
                            className="fw-bold text-green"
                          >
                            Last Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Olusola"
                            value={details.lastName}
                            onChange={e =>
                              setDetails({
                                ...details,
                                lastName: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                        <div className="col-md-6 mb-4">
                          <label htmlFor="email" className="fw-bold text-green">
                            Email Address
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="email@manager.com"
                            value={details.email}
                            onChange={e =>
                              setDetails({
                                ...details,
                                email: e.target.value,
                              })
                            }
                            required
                          />
                          <small className="text-danger">
                            {errors?.manager_email}
                          </small>
                        </div>
                        <div className="col-md-6 mb-4">
                          <label htmlFor="phone" className="fw-bold text-green">
                            Phone Number
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="+2348027125201"
                            value={details?.phone}
                            onChange={e =>
                              setDetails({
                                ...details,
                                phone: e.target.value,
                              })
                            }
                            required
                          />
                          <small className="text-danger">
                            {errors?.manager_phone}
                          </small>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <button
                          type="submit"
                          className="send mt-4"
                          disabled={disableButton}
                        >
                          {disableButton ? "Creating Hub..." : "Create Hub"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CreateHub
