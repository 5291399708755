import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Table, Row, Col, Card, CardBody } from "reactstrap"

import accessToken from "../../helpers/jwt-token-access/accessToken"

import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Link, Redirect } from "react-router-dom"
import { FaArrowLeft } from "react-icons/fa"
import baseURL from "../../utils"
import hub from "../../assets/images/delivery-hub.jpg"

const DeliveryHubDetails = props => {
  const [profile, setProfile] = useState({
    manager: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
    },
  })
  const [loading, setLoading] = useState(true)
  const [disableButton, setDisableButton] = useState(false)
  const [redirect, setRedirect] = useState("")

  const handleManagerChange = (field, value) => {
    setProfile(prevProfile => ({
      ...prevProfile,
      manager: {
        ...prevProfile.manager,
        [field]: value,
      },
    }))
  }

  // DeliveryHub Details
  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/delivery-hubs/${props?.match?.params?.id}`,
          options
        )
        const profile = await response.json()
        console.log(profile.data)
        setLoading(false)
        setProfile(profile.data)
      } catch (error) {
        console.log("error", error)
        toast.error(error.message)
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  // Activate Account
  const activateAccount = async e => {
    if (confirm("Are you sure you want to activate this account?")) {
      e.preventDefault()
      // do stuff
      setDisableButton(true)
      try {
        const options = {
          method: "POST",
          headers: new Headers({
            Authorization: accessToken,
            "Content-Type": "application/json",
          }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/delivery-hubs/${props?.match?.params?.id}/activate`,
          options
        )
        const data = await response.json()
        if (data.status == "success") {
          toast.success(data["message"])
          setTimeout(() => {
            location.reload()
          }, 3000)
        } else if (data.status == "error") {
          toast.error(data["message"])
          setDisableButton(false)
        }
      } catch (error) {
        // do what
      }
    } else {
      return false
    }
  }

  // Deactivate Account
  const deactivateAccount = async e => {
    if (confirm("Are you sure you want to deactivate this account?")) {
      e.preventDefault()
      // do stuff
      setDisableButton(true)
      try {
        const options = {
          method: "POST",
          headers: new Headers({
            Authorization: accessToken,
            "Content-Type": "application/json",
          }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/delivery-hubs/${props?.match?.params?.id}/deactivate`,
          options
        )
        const data = await response.json()
        if (data.status == "success") {
          toast.success(data["message"])
          setTimeout(() => {
            location.reload()
          }, 3000)
        } else if (data.status == "error") {
          toast.error(data["message"])
          setDisableButton(false)
        }
      } catch (error) {
        // do what
      }
    } else {
      return false
    }
  }

  // Update Hub
  const updateHub = async () => {
    setDisableButton(true)
    // do stuff
    try {
      const options = {
        method: "PUT",
        headers: new Headers({
          Authorization: accessToken,
          "Content-Type": "application/json",
          accept: "application/json",
        }),
        body: JSON.stringify({
          manager_first_name: profile.manager.first_name,
          manager_last_name: profile.manager.last_name,
          manager_email: profile.manager.email,
          manager_phone: profile.manager.phone,
          name: profile.name,
          state_id: profile.state.id,
          local_government_id: profile.local_government.id,
          address: profile.address,
          coordinates: profile.coordinates,
        }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/delivery-hubs/${props?.match?.params?.id}`,
        options
      )
      const data = await response.json()
      console.log(data)
      if (data.status == "success") {
        toast.success(data["message"])
        setTimeout(() => {
          location.reload(1)
        }, 3000)
      } else {
        toast.error(data["message"])
        setDisableButton(false)
      }
    } catch (error) {
      // do what
    }
  }

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Delivery Hub Details | Dilivva Admin</title>
        </MetaTags>
        <div className="container-fluid">
          <ToastContainer draggable={false} />
          <div>
            <Link to="/delivery-hub" className="mb-4 btn">
              <FaArrowLeft />{" "}
              <span className="mx-2">back to Delivery Hubs</span>
            </Link>
          </div>
          <h2 className="profile-head mb-4">Delivery Hub Details</h2>
          {loading ? (
            <Row className="d-flex justify-content-center">
              <Col lg={11}>
                <Row>
                  <Col>
                    <Card className="profile-card">
                      <CardBody>
                        <Col md={12}>
                          <Row className="">
                            <Col md={3}>
                              <div className="skel">
                                <div className="col-md-8 skeleton skeleton-circle"></div>
                              </div>
                            </Col>
                            <Col md={7} className="mx-3">
                              <div className="col-md-8 skeleton skeleton-head"></div>
                              <div className="col-md-10 skeleton skeleton-p"></div>
                              <div className="col-md-7 skeleton skeleton-p"></div>
                              <div className="col-md-6 skeleton skeleton-p"></div>
                            </Col>
                          </Row>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="profile-wallet">
                      <CardBody>
                        <Col md={12}>
                          <Row className="p-2">
                            <Col>
                              <div className="col-md-2 skeleton skeleton-p mb-4"></div>
                              <div className="col-md-4 skeleton skeleton-head p-3"></div>
                            </Col>
                          </Row>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card className="profile-card">
                      <CardBody>
                        <Row>
                          <Col md={9}>
                            <div className="col-md-12 skeleton skeleton-p"></div>
                            <div className="col-md-6 skeleton skeleton-p"></div>
                            <div className="col-md-6 skeleton skeleton-p"></div>
                            <div className="col-md-5 skeleton skeleton-p"></div>
                            <div className="price-head">
                              {profile?.updated_at}
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div className="skel">
                              <div className="col-md-3 skeleton skeleton-circle"></div>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="profile-wallet">
                      <CardBody>
                        <Col>
                          <Row className="p-2">
                            <Col lg={8}>
                              <div className="col-md-7 skeleton skeleton-p"></div>
                              <div className="skel">
                                <div className="col-md-8 skeleton skeleton-circle"></div>
                              </div>
                            </Col>
                            <Col lg={3}>
                              <div className="col-md-12 skeleton skeleton-head py-3 px-5"></div>
                            </Col>
                          </Row>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <Row className="d-flex justify-content-center">
              <Col lg={11}>
                <Row>
                  <Col lg={8}>
                    <Card className="profile-card">
                      <CardBody>
                        <Col md={12}>
                          <h5>Basic Profile</h5>
                          <hr />
                          <Row className="">
                            <Col md={2}>
                              <div className="profile">
                                <img
                                  src={hub}
                                  className="img-fluid mb-2"
                                  alt={profile?.name}
                                />
                              </div>
                            </Col>
                            <Col md={9} className="mx-3">
                              <h3 className="profile-name">{profile?.name}</h3>
                              <h6 className="details">{profile?.address}</h6>
                              <h6 className="price-head">
                                {profile?.local_government?.name}
                              </h6>
                              <div className="price-head">
                                {profile?.is_active ? (
                                  <span className="text-green">Active</span>
                                ) : (
                                  <span className="text-orange">Inactive</span>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </CardBody>
                    </Card>
                    <Row>
                      <Col>
                        <Card className="profile-card">
                          <CardBody>
                            <h5>Manager</h5>
                            <hr />
                            <div className="row">
                              <div className="col-md-6 mb-4">
                                <label>First Name</label>
                                <input
                                  type="text"
                                  value={profile?.manager?.first_name}
                                  readOnly
                                />
                              </div>
                              <div className="col-md-6 mb-4">
                                <label>Last Name</label>
                                <input
                                  type="text"
                                  value={profile?.manager?.last_name}
                                  readOnly
                                />
                              </div>
                              <div className="col-md-6 mb-4">
                                <label>Email Address</label>
                                <input
                                  type="text"
                                  value={profile?.manager?.email}
                                  readOnly
                                />
                              </div>
                              <div className="col-md-6 mb-4">
                                <label>Phone Number</label>
                                <input
                                  type="text"
                                  value={profile?.manager?.phone}
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="row">
                              <Col className="d-flex justify-content-end my-4">
                                <button
                                  className="send"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                >
                                  Update Hub Details
                                </button>
                                <div
                                  className="modal fade"
                                  id="exampleModal"
                                  tabIndex="-1"
                                  aria-labelledby="exampleModalLabel"
                                  aria-hidden="true"
                                >
                                  <div className="modal-dialog modal-lg">
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h5
                                          className="modal-title"
                                          id="exampleModalLabel"
                                        >
                                          Update Hub Details
                                        </h5>
                                        <button
                                          type="button"
                                          className="btn-close"
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                        ></button>
                                      </div>
                                      <div className="modal-body">
                                        <div className="container">
                                          <div className="row mb-4">
                                            <h6>Hub Name</h6>
                                            <div className="col-md-12">
                                              <input
                                                type="text"
                                                value={profile?.name}
                                                onChange={e =>
                                                  setProfile({
                                                    ...profile,
                                                    name: e.target.value,
                                                  })
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div className="row">
                                            <h6>Manager Details</h6>
                                            <div className="col-md-6 mb-4">
                                              <span>First Name</span>
                                              <input
                                                type="text"
                                                value={
                                                  profile?.manager?.first_name
                                                }
                                                onChange={event => {
                                                  handleManagerChange(
                                                    "first_name",
                                                    event.target.value
                                                  )
                                                }}
                                              />
                                            </div>
                                            <div className="col-md-6 mb-4">
                                              <span>Last Name</span>
                                              <input
                                                type="text"
                                                value={
                                                  profile?.manager?.last_name
                                                }
                                                onChange={event =>
                                                  handleManagerChange(
                                                    "last_name",
                                                    event.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="col-md-6 mb-4">
                                              <span>Email</span>
                                              <input
                                                type="text"
                                                value={profile?.manager?.email}
                                                onChange={event =>
                                                  handleManagerChange(
                                                    "email",
                                                    event.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="col-md-6 mb-4">
                                              <span>Phone</span>
                                              <input
                                                type="text"
                                                value={profile?.manager?.phone}
                                                onChange={event =>
                                                  handleManagerChange(
                                                    "phone",
                                                    event.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="modal-footer">
                                        <button
                                          type="button"
                                          className="btn btn-secondary"
                                          data-bs-dismiss="modal"
                                        >
                                          Close
                                        </button>
                                        <button
                                          type="submit"
                                          className="send"
                                          disabled={disableButton}
                                          onClick={() => updateHub()}
                                        >
                                          Update
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={4}>
                    <Card className="profile-card">
                      <CardBody>
                        <Col md={12}>
                          <Row className="p-2">
                            <h5>Account</h5>
                            <hr />
                            {profile?.is_active ? (
                              <Col>
                                <p className="mb-4">Deactivate Account</p>
                                <button
                                  type="button"
                                  className="change mb-2"
                                  disabled={disableButton}
                                  onClick={deactivateAccount}
                                >
                                  Deactivate
                                </button>
                              </Col>
                            ) : (
                              <Col>
                                <p className="mb-4">Activate Account</p>
                                <button
                                  type="button"
                                  className="send mb-2"
                                  disabled={disableButton}
                                  onClick={activateAccount}
                                >
                                  Activate
                                </button>
                              </Col>
                            )}
                          </Row>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default DeliveryHubDetails
