import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Table, Row, Col, Card, CardBody } from "reactstrap"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"

import "../senders/datatables.scss"
import accessToken from "../../helpers/jwt-token-access/accessToken"
import "./connectfee.css"

import { Link, Redirect } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { BsThreeDots } from "react-icons/bs"
import { MdRefresh } from "react-icons/md"

import baseURL from "../../utils"
import ReactPaginate from "react-paginate"

import emptyState from "../../assets/images/empty-state.png"

const ConnectionFee = () => {
  const [connectingFees, setConnectingFees] = useState([])
  const [loading, setLoading] = useState(true)
  const [redirect, setRedirect] = useState("")
  const [disabled, setDisabled] = useState(false)
  const [perPage, setPerPage] = useState(50)
  const [next, setNext] = useState(1)
  const [lastPage, setLastPage] = useState({})
  const [buttonLoad, setButtonLoad] = useState(false)
  const [originCenter, setOriginCenter] = useState("")
  const [destinationCenter, setDestinationCenter] = useState("")
  const [searchResult, setSearchResult] = useState(false)
  const [searchData, setSearchData] = useState([])
  const [errors, setErrors] = useState({})
  const [show, setShow] = useState(false)
  const [singleConnect, setSingleConnect] = useState({
    amount: 0,
  })
  const [connectId, setConnectId] = useState("")

  const handlePageClick = data => {
    setNext(data.selected + 1)
  }

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/center-connecting-prices?perPage=${perPage}&page=${next}`,
          options
        )
        const connectingFees = await response.json()
        setConnectingFees(connectingFees.data.center_connecting_prices)
        setLastPage(connectingFees.data.meta)
        console.log(connectingFees.data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error)
        toast.error(error.message)
      }
    }

    fetchData()
  }, [next, perPage])

  const searchCenterConnectingPrice = async () => {
    setLoading(true)
    setButtonLoad(true)
    setErrors({})
    try {
      const options = {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: accessToken,
        }),
        body: JSON.stringify({
          origin_center: originCenter,
          destination_center: destinationCenter,
        }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/center-connecting-prices/search`,
        options
      )
      const data = await response.json()
      console.log(data)
      if (data.errors) {
        toast.error(data["message"])
        setErrors(data.errors)
        setButtonLoad(false)
        setDisabled(false)
      } else {
        setSearchData(data["data"])
        setSearchResult(true)
        setButtonLoad(false)
        setLoading(false)
        handleClose()
      }
    } catch (error) {
      console.log(error)
      setSearchResult(false)
    }
  }

  if (redirect) {
    return <Redirect to={redirect} />
  }

  const updateConnectingFees = async connectId => {
    setDisabled(true)
    setButtonLoad(true)
    const options = {
      method: "PUT",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: accessToken,
      }),
      body: JSON.stringify({ amount: singleConnect.amount }),
    }
    fetch(
      `${baseURL}/api/v1/admin/center-connecting-prices/${connectId}`,
      options
    )
      .then(data => data.json())
      .then(response => {
        console.log(response)
        if (response.errors) {
          toast.error(response.message)
          setDisabled(false)
          setButtonLoad(false)
        } else {
          toast.success(response.message)
          setButtonLoad(false)
          setTimeout(() => {
            window.location.reload()
          }, 3000)
        }
      })
      .catch(error => {
        console.log(error.message)
        toast.error(error.message)
        setDisabled(false)
        setButtonLoad(false)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Connection Fee | Dilivva Admin</title>
        </MetaTags>
        <Row className="mx-1 my-4">
          <Col md={6}>
            <h4 className="dashboard-head">Center connection fee</h4>
          </Col>
        </Row>
        <div className="container-fluid">
          <ToastContainer />

          <Row>
            <Col lg={12}>
              <Row className="my-3">
                <Col md={12} className="d-flex justify-content-end gap-2">
                  <>
                    <Button
                      variant="connect-btn save-btn"
                      onClick={handleShow}
                      style={{
                        width: "fit-content",
                        fontSize: "12px",
                        whiteSpace: "nowrap",
                        padding: "8.352px 15.034px",
                        borderRadius: "10px",
                        height: "47px",
                        borderRadius: "6.7px",
                        border: "1px solid #F5F5F5",
                        backgroundColor: "#D97706",
                        color: "#fff",
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "160%",
                      }}
                    >
                      Search for center
                    </Button>

                    <Modal show={show} onHide={handleClose}>
                      <Modal.Header closeButton>
                        <h5
                          className="modal-title"
                          id="itemModalLabel"
                          style={{
                            color: "#0D172B",
                            fontSize: "15px",
                          }}
                        >
                          Search for Center
                        </h5>
                      </Modal.Header>
                      <div className="modal-body bg-white">
                        <div className="container">
                          <div className="row">
                            <div className="col-md-12 mb-4">
                              <label className="connect-label">
                                Center origin location
                              </label>
                              <input
                                type="text"
                                placeholder="Ogun State"
                                value={originCenter}
                                onChange={e => setOriginCenter(e.target.value)}
                                required
                              />
                              <small className="text-danger">
                                {errors?.origin_center}
                              </small>
                            </div>
                            <div className="col-md-12 mb-4">
                              <label className="connect-label">
                                Center destination location
                              </label>
                              <input
                                type="text"
                                value={destinationCenter}
                                onChange={e =>
                                  setDestinationCenter(e.target.value)
                                }
                                placeholder="Lagos State"
                                required
                              />
                              <small className="text-danger">
                                {errors?.destination_center}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="connect-footer">
                        <button
                          type="button"
                          className="connect-btn"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                        <button
                          type="submit"
                          disabled={disabled}
                          className={`connect-btn save-btn ${
                            buttonLoad && "opacity-50"
                          }`}
                          onClick={searchCenterConnectingPrice}
                        >
                          {buttonLoad ? (
                            <div
                              className="spinner-border text-light mx-3"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          ) : (
                            "Search"
                          )}
                        </button>
                      </div>
                    </Modal>
                  </>
                </Col>
              </Row>
              <Card>
                <CardBody>
                  {loading ? (
                    <div className="container">
                      <div className="row">
                        <div className="col-md-2 mb-5 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-10 skeleton skeleton-search"></div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {searchResult ? (
                        <div>
                          {searchData?.length === 0 ? (
                            <div className="d-flex flex-column justify-content-center align-items-center">
                              <img
                                src={emptyState}
                                alt="empty state"
                                width={206}
                                height={206}
                              />
                              <p
                                style={{
                                  maxWidth: "185px",
                                  textAlign: "center",
                                  color: "#8D8D8D",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  lineHeight: "21px",
                                }}
                              >
                                No results found
                              </p>
                              <button
                                className="connect-btn save-btn"
                                style={{
                                  width: "fit-content",
                                  padding: "2px 10px",
                                  paddingRight: "14px",
                                }}
                                onClick={() => setSearchResult(false)}
                              >
                                <MdRefresh size={18} className="mx-2" />
                                Refresh
                              </button>
                            </div>
                          ) : (
                            <div className="">
                              <button
                                className="connect-btn save-btn"
                                style={{
                                  width: "fit-content",
                                  padding: "2px 10px",
                                  paddingRight: "14px",
                                }}
                                onClick={() => setSearchResult(false)}
                              >
                                <MdRefresh size={18} className="mx-2" />
                                Refresh
                              </button>
                              {searchData?.length !== 0 ? (
                                <div className="table-responsive">
                                  <Table className="table responsive table-hover mt-2 mb-0">
                                    <thead>
                                      <tr>
                                        <th>Center Origin</th>
                                        <th>Center Destination</th>
                                        <th>Amount</th>
                                        <th>Date added</th>
                                        <th>
                                          <BsThreeDots />
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {searchData?.map(item => {
                                        const {
                                          uuid,
                                          origin_center,
                                          destination_center,
                                          amount,
                                          created_at,
                                        } = item
                                        return (
                                          <tr key={uuid}>
                                            <td>
                                              {origin_center?.name}
                                              <br />
                                            </td>
                                            <td>{destination_center?.name}</td>
                                            <td>
                                              {amount
                                                ? `₦ ${amount}`
                                                : "No amount"}
                                            </td>
                                            <td>{created_at}</td>
                                            <td>
                                              <button
                                                className="btn shadow-none bg-transparent border"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModal"
                                                onClick={() => {
                                                  setSingleConnect(item)
                                                  setConnectId(item.uuid)
                                                }}
                                              >
                                                Edit
                                              </button>
                                              <div
                                                className="modal fade"
                                                id="exampleModal"
                                                tabIndex="-1"
                                                aria-labelledby="exampleModalLabel"
                                                aria-hidden="true"
                                              >
                                                <div className="modal-dialog">
                                                  <div className="modal-content">
                                                    <div className="modal-header">
                                                      <h5
                                                        className="modal-title"
                                                        id="exampleModalLabel"
                                                        style={{
                                                          color: "#0D172B",
                                                          fontSize: "15px",
                                                        }}
                                                      >
                                                        Add new connection fee
                                                      </h5>
                                                      <button
                                                        type="button"
                                                        className="btn-close"
                                                        data-bs-dismiss="modal"
                                                        aria-label="Close"
                                                      ></button>
                                                    </div>
                                                    <div className="modal-body bg-white">
                                                      <div className="container">
                                                        <div className="row">
                                                          <div className="col-md-12 mb-4">
                                                            <label className="connect-label">
                                                              Center origin
                                                              location{" "}
                                                              <span className="connect-required">
                                                                *
                                                              </span>
                                                            </label>
                                                            <input
                                                              type="text"
                                                              value={
                                                                singleConnect
                                                                  ?.origin_center
                                                                  ?.name
                                                              }
                                                              onChange={e =>
                                                                setConnectingFees(
                                                                  {
                                                                    ...connectingFees,
                                                                    orign_center:
                                                                      e.target
                                                                        .value,
                                                                  }
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                          <div className="col-md-12 mb-4">
                                                            <label className="connect-label">
                                                              Center destination
                                                              location{" "}
                                                              <span className="connect-required">
                                                                *
                                                              </span>
                                                            </label>
                                                            <input
                                                              type="text"
                                                              value={
                                                                singleConnect
                                                                  ?.destination_center
                                                                  ?.name
                                                              }
                                                              onChange={e =>
                                                                setConnectingFees(
                                                                  {
                                                                    ...connectingFees,
                                                                    destination_center:
                                                                      e.target
                                                                        .value,
                                                                  }
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                          <div className="col-md-12 mb-4">
                                                            <label className="connect-label">
                                                              Amount{" "}
                                                              <span className="connect-required">
                                                                *
                                                              </span>
                                                            </label>
                                                            <input
                                                              type="text"
                                                              value={
                                                                singleConnect?.amount
                                                              }
                                                              onChange={e =>
                                                                setSingleConnect(
                                                                  {
                                                                    ...connectingFees,
                                                                    amount:
                                                                      e.target
                                                                        .value,
                                                                  }
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="connect-footer">
                                                      <button
                                                        type="button"
                                                        className="connect-btn"
                                                        data-bs-dismiss="modal"
                                                      >
                                                        Close
                                                      </button>
                                                      <button
                                                        disabled={disabled}
                                                        className={`connect-btn save-btn ${
                                                          buttonLoad &&
                                                          "opacity-50"
                                                        }`}
                                                        onClick={() =>
                                                          updateConnectingFees(
                                                            connectId
                                                          )
                                                        }
                                                      >
                                                        {buttonLoad ? (
                                                          <div
                                                            className="spinner-border text-light mx-3"
                                                            role="status"
                                                          >
                                                            <span className="visually-hidden">
                                                              Loading...
                                                            </span>
                                                          </div>
                                                        ) : (
                                                          "Save Details"
                                                        )}
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        )
                                      })}
                                    </tbody>
                                  </Table>
                                </div>
                              ) : (
                                <div className="d-flex justify-content-center align-items-center my-4">
                                  <h3 className="fw-bold">
                                    Oops, No Connection Fee
                                  </h3>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="">
                          {connectingFees?.length !== 0 ? (
                            <div className="table-responsive">
                              <Table className="table responsive table-hover mt-2 mb-0">
                                <thead>
                                  <tr>
                                    <th>Center Origin</th>
                                    <th>Center Destination</th>
                                    <th>Amount</th>
                                    <th>Date added</th>
                                    <th>
                                      <BsThreeDots />
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {connectingFees?.map(connectingFee => {
                                    const {
                                      uuid,
                                      origin_center,
                                      destination_center,
                                      amount,
                                      created_at,
                                    } = connectingFee
                                    return (
                                      <tr key={uuid}>
                                        <td>
                                          {origin_center?.name}
                                          <br />
                                        </td>
                                        <td>{destination_center?.name}</td>
                                        <td>
                                          {amount ? `₦ ${amount}` : "No amount"}
                                        </td>
                                        <td>{created_at}</td>
                                        <td>
                                          <button
                                            className="btn shadow-none bg-transparent border"
                                            data-bs-toggle="modal"
                                            data-bs-target="#exampleModal"
                                            onClick={() => {
                                              setSingleConnect(connectingFee)
                                              setConnectId(connectingFee.uuid)
                                            }}
                                          >
                                            Edit
                                          </button>
                                          <div
                                            className="modal fade"
                                            id="exampleModal"
                                            tabIndex="-1"
                                            aria-labelledby="exampleModalLabel"
                                            aria-hidden="true"
                                          >
                                            <div className="modal-dialog">
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h5
                                                    className="modal-title"
                                                    id="exampleModalLabel"
                                                    style={{
                                                      color: "#0D172B",
                                                      fontSize: "15px",
                                                    }}
                                                  >
                                                    Add new connection fee
                                                  </h5>
                                                  <button
                                                    type="button"
                                                    className="btn-close"
                                                    data-bs-dismiss="modal"
                                                    aria-label="Close"
                                                  ></button>
                                                </div>
                                                <div className="modal-body bg-white">
                                                  <div className="container">
                                                    <div className="row">
                                                      <div className="col-md-12 mb-4">
                                                        <label className="connect-label">
                                                          Center origin location{" "}
                                                          <span className="connect-required">
                                                            *
                                                          </span>
                                                        </label>
                                                        <input
                                                          type="text"
                                                          value={
                                                            singleConnect
                                                              ?.origin_center
                                                              ?.name
                                                          }
                                                          onChange={e =>
                                                            setConnectingFees({
                                                              ...connectingFees,
                                                              orign_center:
                                                                e.target.value,
                                                            })
                                                          }
                                                        />
                                                      </div>
                                                      <div className="col-md-12 mb-4">
                                                        <label className="connect-label">
                                                          Center destination
                                                          location{" "}
                                                          <span className="connect-required">
                                                            *
                                                          </span>
                                                        </label>
                                                        <input
                                                          type="text"
                                                          value={
                                                            singleConnect
                                                              ?.destination_center
                                                              ?.name
                                                          }
                                                          onChange={e =>
                                                            setConnectingFees({
                                                              ...connectingFees,
                                                              destination_center:
                                                                e.target.value,
                                                            })
                                                          }
                                                        />
                                                      </div>
                                                      <div className="col-md-12 mb-4">
                                                        <label className="connect-label">
                                                          Amount{" "}
                                                          <span className="connect-required">
                                                            *
                                                          </span>
                                                        </label>
                                                        <input
                                                          type="text"
                                                          value={
                                                            singleConnect?.amount
                                                          }
                                                          onChange={e =>
                                                            setSingleConnect({
                                                              ...connectingFees,
                                                              amount:
                                                                e.target.value,
                                                            })
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="connect-footer">
                                                  <button
                                                    type="button"
                                                    className="connect-btn"
                                                    data-bs-dismiss="modal"
                                                  >
                                                    Close
                                                  </button>
                                                  <button
                                                    disabled={disabled}
                                                    className="connect-btn save-btn"
                                                    onClick={() =>
                                                      updateConnectingFees(
                                                        connectId
                                                      )
                                                    }
                                                  >
                                                    Save Details
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </Table>
                              <div className="float-end mt-5">
                                <ReactPaginate
                                  previousLabel={"Prev"}
                                  nextLabel={"Next"}
                                  breakLabel={"..."}
                                  pageCount={Math.ceil(
                                    lastPage?.total / lastPage?.per_page
                                  )}
                                  marginPagesDisplayed={4}
                                  pageRangeDisplayed={6}
                                  onPageChange={handlePageClick}
                                  containerClassName={"pagination"}
                                  pageClassName={"page-item"}
                                  pageLinkClassName={"page-link"}
                                  previousClassName={"page-item"}
                                  previousLinkClassName={"page-link"}
                                  nextClassName={"page-item"}
                                  nextLinkClassName={"page-link"}
                                  breakClassName={"page-item"}
                                  breakLinkClassName={"page-link"}
                                  activeClassName={"active"}
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="d-flex justify-content-center align-items-center my-4">
                              <h3 className="fw-bold">
                                Oops, No Connection Fee
                              </h3>
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ConnectionFee
