import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Row, Col, Card, CardBody, Table } from "reactstrap"

import accessToken from "../../helpers/jwt-token-access/accessToken"

import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Link } from "react-router-dom"
import { FaChevronLeft } from "react-icons/fa"
import baseURL from "../../utils"
import emptyState from "../../assets/images/empty-state.png"

const SweeperDetails = props => {
  const [profile, setProfile] = useState({
    contact_person: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
    },
  })
  const [loading, setLoading] = useState(true)
  const [loadingOrders, setLoadingOrders] = useState(true)
  const [destination, setDestination] = useState("hub")
  const [orders, setOrders] = useState([])

  // Sweeper Details
  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/sweepers/${props?.match?.params?.id}`,
          options
        )
        const profile = await response.json()
        console.log(profile.data)
        setLoading(false)
        setProfile(profile.data)
      } catch (error) {
        console.log("error", error)
        toast.error(error.message)
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  // Fetch sweeper orders
  useEffect(() => {
    setLoadingOrders(true)
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/sweepers/${props?.match?.params?.id}/orders?destination=${destination}`,
          options
        )
        const orders = await response.json()
        console.log(orders)
        setOrders(orders.data.orders)
        setLoadingOrders(false)

        setLoading(false)
      } catch (error) {
        setLoading(false)
        toast.error(error.message)
        setLoadingOrders(false)
      }
    }

    fetchData()
  }, [destination])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Sweeper Details | Dilivva Admin</title>
        </MetaTags>
        <div className="container-fluid">
          <ToastContainer draggable={false} />
          <div
            className="mb-4 d-flex justify-content-center align-items-center"
            style={{
              gap: "4px",
              padding: "7px 18px 7px 12px",
              width: "76px",
              borderRadius: "32px",
              border: "1px solid #F0F0F0",
              background: "#FFF",
              boxShadow: "1px 1px 4px 0px rgba(0, 0, 0, 0.04)",
              marginBottom: "8px",
            }}
          >
            <Link
              to="/sweepers"
              className="text-black d-flex align-items-center"
            >
              <FaChevronLeft size={16} />
              <div
                style={{
                  color: "#000",
                  fontSize: "14px",
                  fontWeight: "600",
                  marginLeft: "4px",
                }}
              >
                Back
              </div>
            </Link>
          </div>
          <h5 className="mb-4">Sweeper Details</h5>
          {loading ? (
            <Row className="d-flex justify-content-center">
              <Col lg={11}>
                <Row>
                  <Col md={8}>
                    <Card className="profile-card">
                      <CardBody>
                        <Col md={12}>
                          <Row className="">
                            <Col md={3}>
                              <div className="skel">
                                <div className="col-md-8 skeleton skeleton-circle"></div>
                              </div>
                            </Col>
                            <Col md={7} className="mx-3">
                              <div className="col-md-8 skeleton skeleton-head"></div>
                              <div className="col-md-10 skeleton skeleton-p"></div>
                              <div className="col-md-7 skeleton skeleton-p"></div>
                              <div className="col-md-6 skeleton skeleton-p"></div>
                            </Col>
                          </Row>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <CardBody>
                        <Col md={12}>
                          <Row className="p-2">
                            <Col>
                              <div className="col-md-2 skeleton skeleton-p mb-4"></div>
                              <div className="col-md-7 skeleton skeleton-head p-1"></div>
                              <div className="col-md-8 skeleton skeleton-head"></div>
                            </Col>
                          </Row>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <Row className="d-flex justify-content-center">
              <Col lg={12}>
                <Row>
                  <Col lg={4}>
                    <Card className="profile-card">
                      <CardBody>
                        <Row>
                          <h6>Sweeper Details</h6>
                          <hr />
                          <Col md={9}>
                            <h6 className="mb-0">
                              {profile?.first_name} {profile?.last_name}
                            </h6>
                            <small className="text-green">
                              {profile?.email}
                            </small>
                            <h6 className="pt-1">{profile?.phone}</h6>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg={8}>
                    <Card className="profile-card">
                      <CardBody>
                        <Col md={12}>
                          <Row>
                            <h6>Center Details</h6>
                            <hr />
                            <Col>
                              <h5>
                                {profile?.center?.name} -{" "}
                                {profile?.center?.location?.name}
                              </h5>

                              <h6>{profile?.center?.address}</h6>
                              <h6 className="text-orange">
                                {profile?.center?.email}
                              </h6>
                            </Col>
                          </Row>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <Card className="profile-card">
                      <CardBody>
                        <h6 className="mt-5">Orders</h6>
                        <hr />
                        <div className="row feture-tabs" data-aos="fade-up">
                          <div className="col-lg-12">
                            <ul className="nav nav-pills my-3">
                              <li onClick={() => setDestination("hub")}>
                                <a
                                  className="nav-link active"
                                  data-bs-toggle="pill"
                                  href="#tab1"
                                >
                                  Hub Orders
                                </a>
                              </li>
                              <li onClick={() => setDestination("center")}>
                                <a
                                  className="nav-link"
                                  data-bs-toggle="pill"
                                  href="#tab2"
                                >
                                  Center Orders
                                </a>
                              </li>
                            </ul>
                            <div className="tab-content">
                              <div
                                className="tab-pane fade show active"
                                id="tab1"
                              >
                                <div className="container-fluid">
                                  <Row>
                                    <Col lg={12}>
                                      {loadingOrders ? (
                                        <div className="container">
                                          <div className="row">
                                            <div className="col-md-12 skeleton skeleton-search"></div>
                                            <div className="col-md-12 skeleton skeleton-search"></div>
                                            <div className="col-md-12 skeleton skeleton-search"></div>
                                            <div className="col-md-12 skeleton skeleton-search"></div>
                                            <div className="col-md-12 skeleton skeleton-search"></div>
                                            <div className="col-md-12 skeleton skeleton-search"></div>
                                            <div className="col-md-12 skeleton skeleton-search"></div>
                                            <div className="col-md-12 skeleton skeleton-search"></div>
                                            <div className="col-md-12 skeleton skeleton-search"></div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="table-responsive">
                                          <div className="table-responsive">
                                            {orders.length !== 0 && destination === "hub" ? (
                                              <>
                                                <Table className="table-hover mt-2 mb-0">
                                                  <thead>
                                                    <tr>
                                                      <th>Sender</th>
                                                      <th>Label</th>
                                                      <th>Price</th>
                                                      <th>Current Location</th>
                                                      <th>Payment Status</th>
                                                      <th>Created</th>
                                                      <th>Delivery Status</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {orders?.map(
                                                      (order, index) => {
                                                        const {
                                                          uuid,
                                                          price,
                                                          payment_status,
                                                          status,
                                                          created_at,
                                                          pickup_time,
                                                          current_location_type,
                                                          current_phase,
                                                          user,
                                                        } = order
                                                        return (
                                                          <tr
                                                            key={uuid}
                                                            onClick={() =>
                                                              setRedirect(
                                                                "/order/" + uuid
                                                              )
                                                            }
                                                            className="table-row"
                                                          >
                                                            <td>
                                                              {user?.full_name}{" "}
                                                              <br />
                                                              <small className="text-green">
                                                                {user?.email}
                                                              </small>
                                                            </td>
                                                            <td
                                                              style={{
                                                                whiteSpace:
                                                                  "nowrap",
                                                              }}
                                                            >
                                                              <div>
                                                                {order?.package
                                                                  ?.images
                                                                  .length !==
                                                                  0 && (
                                                                  <img
                                                                    src={`${
                                                                      process
                                                                        .env
                                                                        .REACT_APP_IMAGE_URL
                                                                    }/download/${encodeURIComponent(
                                                                      order
                                                                        ?.package
                                                                        ?.images[0]
                                                                    )}`}
                                                                    className="img-fluid rounded"
                                                                    width={40}
                                                                    alt={
                                                                      order
                                                                        ?.package
                                                                        ?.label
                                                                    }
                                                                  />
                                                                )}
                                                              </div>
                                                              <small className="small">
                                                                {
                                                                  order?.package
                                                                    ?.label
                                                                }
                                                              </small>
                                                            </td>
                                                            <td>₦{price}</td>
                                                            <td>
                                                              {current_location_type ===
                                                                "Sender" ||
                                                              current_location_type ===
                                                                "Recipient"
                                                                ? current_location_type
                                                                : current_phase ===
                                                                    "center_to_center" ||
                                                                  current_phase ===
                                                                    "hub_to_sweeper" ||
                                                                  current_phase ===
                                                                    "partner_to_center" ||
                                                                  current_phase ===
                                                                    "sweeper_to_center"
                                                                ? `Origin ${current_location_type}`
                                                                : current_phase ===
                                                                    "center_to_partner" ||
                                                                  current_phase ===
                                                                    "center_to_sweeper" ||
                                                                  current_phase ===
                                                                    "hub_to_recipient" ||
                                                                  current_phase ===
                                                                    "parner_to_recipient" ||
                                                                  current_phase ===
                                                                    "sweeper_to_hub"
                                                                ? `Destination ${current_location_type}`
                                                                : ""}
                                                            </td>
                                                            <td
                                                              style={{
                                                                color:
                                                                  payment_status ===
                                                                  "completed"
                                                                    ? "#29CC97"
                                                                    : payment_status ===
                                                                      "cancelled"
                                                                    ? "#DC1515"
                                                                    : "#FEC400",
                                                              }}
                                                            >
                                                              {payment_status}
                                                            </td>
                                                            <td>
                                                              {created_at}
                                                            </td>
                                                            <td
                                                              style={{
                                                                color:
                                                                  status ===
                                                                  "completed"
                                                                    ? "#29CC97"
                                                                    : status ===
                                                                      "cancelled"
                                                                    ? "#DC1515"
                                                                    : status ===
                                                                      "accepted"
                                                                    ? "#86F599"
                                                                    : status ===
                                                                      "transit"
                                                                    ? "#C9DA1D"
                                                                    : "#FEC400",
                                                              }}
                                                            >
                                                              {status}
                                                            </td>
                                                          </tr>
                                                        )
                                                      }
                                                    )}
                                                  </tbody>
                                                </Table>
                                              </>
                                            ) : (
                                              <div className="d-flex flex-column justify-content-center align-items-center">
                                                <img
                                                  src={emptyState}
                                                  alt="empty state"
                                                  width={206}
                                                  height={206}
                                                />
                                                <p
                                                  style={{
                                                    maxWidth: "185px",
                                                    textAlign: "center",
                                                    color: "#8D8D8D",
                                                    fontSize: "14px",
                                                    fontWeight: "400",
                                                    lineHeight: "21px",
                                                  }}
                                                >
                                                  No of orders in the hub
                                                </p>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      )}
                                    </Col>
                                  </Row>
                                </div>
                              </div>

                              <div className="tab-pane fade show" id="tab2">
                                <Row>
                                <Col lg={12}>
                                      {loadingOrders ? (
                                        <div className="container">
                                          <div className="row">
                                            <div className="col-md-12 skeleton skeleton-search"></div>
                                            <div className="col-md-12 skeleton skeleton-search"></div>
                                            <div className="col-md-12 skeleton skeleton-search"></div>
                                            <div className="col-md-12 skeleton skeleton-search"></div>
                                            <div className="col-md-12 skeleton skeleton-search"></div>
                                            <div className="col-md-12 skeleton skeleton-search"></div>
                                            <div className="col-md-12 skeleton skeleton-search"></div>
                                            <div className="col-md-12 skeleton skeleton-search"></div>
                                            <div className="col-md-12 skeleton skeleton-search"></div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="table-responsive">
                                          <div className="table-responsive">
                                            {orders.length !== 0 && destination === "center" ? (
                                              <>
                                                <Table className="table-hover mt-2 mb-0">
                                                  <thead>
                                                    <tr>
                                                      <th>Sender</th>
                                                      <th>Label</th>
                                                      <th>Price</th>
                                                      <th>Current Location</th>
                                                      <th>Payment Status</th>
                                                      <th>Created</th>
                                                      <th>Delivery Status</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {orders?.map(
                                                      (order, index) => {
                                                        const {
                                                          uuid,
                                                          price,
                                                          payment_status,
                                                          status,
                                                          created_at,
                                                          pickup_time,
                                                          current_location_type,
                                                          current_phase,
                                                          user,
                                                        } = order
                                                        return (
                                                          <tr
                                                            key={uuid}
                                                            onClick={() =>
                                                              setRedirect(
                                                                "/order/" + uuid
                                                              )
                                                            }
                                                            className="table-row"
                                                          >
                                                            <td>
                                                              {user?.full_name}{" "}
                                                              <br />
                                                              <small className="text-green">
                                                                {user?.email}
                                                              </small>
                                                            </td>
                                                            <td
                                                              style={{
                                                                whiteSpace:
                                                                  "nowrap",
                                                              }}
                                                            >
                                                              <div>
                                                                {order?.package
                                                                  ?.images
                                                                  .length !==
                                                                  0 && (
                                                                  <img
                                                                    src={`${
                                                                      process
                                                                        .env
                                                                        .REACT_APP_IMAGE_URL
                                                                    }/download/${encodeURIComponent(
                                                                      order
                                                                        ?.package
                                                                        ?.images[0]
                                                                    )}`}
                                                                    className="img-fluid rounded"
                                                                    width={40}
                                                                    alt={
                                                                      order
                                                                        ?.package
                                                                        ?.label
                                                                    }
                                                                  />
                                                                )}
                                                              </div>
                                                              <small className="small">
                                                                {
                                                                  order?.package
                                                                    ?.label
                                                                }
                                                              </small>
                                                            </td>
                                                            <td>₦{price}</td>
                                                            <td>
                                                              {current_location_type ===
                                                                "Sender" ||
                                                              current_location_type ===
                                                                "Recipient"
                                                                ? current_location_type
                                                                : current_phase ===
                                                                    "center_to_center" ||
                                                                  current_phase ===
                                                                    "hub_to_sweeper" ||
                                                                  current_phase ===
                                                                    "partner_to_center" ||
                                                                  current_phase ===
                                                                    "sweeper_to_center"
                                                                ? `Origin ${current_location_type}`
                                                                : current_phase ===
                                                                    "center_to_partner" ||
                                                                  current_phase ===
                                                                    "center_to_sweeper" ||
                                                                  current_phase ===
                                                                    "hub_to_recipient" ||
                                                                  current_phase ===
                                                                    "parner_to_recipient" ||
                                                                  current_phase ===
                                                                    "sweeper_to_hub"
                                                                ? `Destination ${current_location_type}`
                                                                : ""}
                                                            </td>
                                                            <td
                                                              style={{
                                                                color:
                                                                  payment_status ===
                                                                  "completed"
                                                                    ? "#29CC97"
                                                                    : payment_status ===
                                                                      "cancelled"
                                                                    ? "#DC1515"
                                                                    : "#FEC400",
                                                              }}
                                                            >
                                                              {payment_status}
                                                            </td>
                                                            <td>
                                                              {created_at}
                                                            </td>
                                                            <td
                                                              style={{
                                                                color:
                                                                  status ===
                                                                  "completed"
                                                                    ? "#29CC97"
                                                                    : status ===
                                                                      "cancelled"
                                                                    ? "#DC1515"
                                                                    : status ===
                                                                      "accepted"
                                                                    ? "#86F599"
                                                                    : status ===
                                                                      "transit"
                                                                    ? "#C9DA1D"
                                                                    : "#FEC400",
                                                              }}
                                                            >
                                                              {status}
                                                            </td>
                                                          </tr>
                                                        )
                                                      }
                                                    )}
                                                  </tbody>
                                                </Table>
                                              </>
                                            ) : (
                                              <div className="d-flex flex-column justify-content-center align-items-center">
                                                <img
                                                  src={emptyState}
                                                  alt="empty state"
                                                  width={206}
                                                  height={206}
                                                />
                                                <p
                                                  style={{
                                                    maxWidth: "185px",
                                                    textAlign: "center",
                                                    color: "#8D8D8D",
                                                    fontSize: "14px",
                                                    fontWeight: "400",
                                                    lineHeight: "21px",
                                                  }}
                                                >
                                                  No of orders in the center
                                                </p>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      )}
                                    </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default SweeperDetails
