import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Table, Row, Col, Card, CardBody, CardImg, Input } from "reactstrap"

import "./datatables.scss"
import accessToken from "../../helpers/jwt-token-access/accessToken"
import "./UserProfile.css"

import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import ReactPaginate from "react-paginate"

import visa from "../../assets/images/visa.png"
import mastercard from "../../assets/images/mastercard.png"
import CurrencyFormat from "react-currency-format"
import {
  FaArrowDown,
  FaArrowLeft,
  FaArrowUp,
  FaChevronLeft,
} from "react-icons/fa"
import { BsThreeDotsVertical } from "react-icons/bs"
import { Link } from "react-router-dom"
import CreateOrder from "./CreateOrder"
import VirtualAccount from "./VirtualAccount"
import SalesRep from "./SalesRep"
import baseURL from "../../utils"

const UsersProfile = props => {
  const [profile, setProfile] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    isInfluencer: "",
    commission_rate: "",
  })
  const [loading, setLoading] = useState(true)
  const [transactions, setTransactions] = useState([])
  const [orders, setOrders] = useState([])
  const [payments, setPayments] = useState([])
  const [next, setNext] = useState(1)
  const [page, setPage] = useState({})
  const [subject, setSubject] = useState("")
  const [body, setBody] = useState("")
  const [attachment, setAttachment] = useState([])
  const [disableButton, setDisableButton] = useState(false)
  const [buttonLoadEmail, setbuttonLoadEmail] = useState(false)
  const [comment, setComment] = useState("")
  const [expire, setExpire] = useState("")
  const [buttonLoadBan, setbuttonLoadBan] = useState(false)
  const [buttonLoadUnban, setButtonLoadUnban] = useState(false)
  const [singleOrder, setSingleOrder] = useState({})
  const [errors, setErrors] = useState({})
  const [buttonLoad, setbuttonLoad] = useState(false)
  const [creditAmount, setCreditAmount] = useState("")
  const [narration, setNarration] = useState("")
  const [loadWallet, setLoadWallet] = useState(false)
  const [commission, setCommission] = useState("")
  const [code, setCode] = useState("")
  const [sort, setSort] = useState("desc")
  const [perPage, setPerPage] = useState(5)

  const handlePageClick = data => {
    setNext(data.selected + 1)
  }

  const showModal = singleOrder => {
    setSingleOrder(singleOrder)
  }

  // User Profile
  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/users/${props?.match?.params?.id}`,
          options
        )
        const profile = await response.json()
        console.log(profile.data)
        setLoading(false)
        setProfile(profile.data)
        setCode(profile.data.referral_link.split("=")[1])
      } catch (error) {
        console.log("error", error)
        toast.error(error.message)
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  // User Transaction
  useEffect(() => {
    const fetchTransaction = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/users/${props?.match?.params?.id}/transactions?page=${next}&perPage=${perPage}&sort=${sort}`,
          options
        )
        const transactions = await response.json()
        console.log(transactions.data.meta)
        setLoading(false)
        console.log(transactions.data, "transactions")
        setTransactions(transactions.data.transactions)
        setPage(transactions.data.meta)
      } catch (error) {
        console.log("error", error)
      }
    }

    fetchTransaction()
  }, [next, perPage, sort])

  // User Orders
  useEffect(() => {
    const fetchTransaction = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/users/${props?.match?.params?.id}/orders?page=${next}&perPage=${perPage}&sort=${sort}`,
          options
        )
        const orders = await response.json()
        console.log(orders.data)
        setLoading(false)
        setOrders(orders.data.orders)
        setPage(orders.data.meta)
      } catch (error) {
        console.log("error", error)
      }
    }

    fetchTransaction()
  }, [next, perPage, sort])

  // User Payment Methods
  useEffect(() => {
    const fetchTransaction = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/users/${props?.match?.params?.id}/payment-methods`,
          options
        )
        const payments = await response.json()
        console.log(payments.data)
        setLoading(false)
        setPayments(payments.data)
      } catch (error) {
        console.log("error", error)
      }
    }

    fetchTransaction()
  }, [next])

  // handle repayments
  const retryPayment = async orderId => {
    // do stuff

    if (confirm("Are you sure you want to proceed to payment?")) {
      setbuttonLoadBan(true)
      setDisableButton(true)
      try {
        const options = {
          method: "POST",
          headers: new Headers({
            Authorization: accessToken,
            "Content-Type": "application/json",
          }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/orders/${orderId}/retry-payment`,
          options
        )
        const data = await response.json()
        console.log(data)
        console.log(data.message)
        if (data.status == "success") {
          toast.success(data["message"])
          setTimeout(() => {
            location.reload()
          }, 5000)
        } else if (data.status == "error") {
          toast.error(data["message"])
          setbuttonLoadBan(false)
          setDisableButton(false)
        }
      } catch (error) {
        // do what
      }
    } else {
      return false
    }
  }
  // Ban User
  const banUser = async e => {
    e.preventDefault()
    // do stuff
    setbuttonLoadBan(true)
    setDisableButton(true)
    try {
      const options = {
        method: "POST",
        headers: new Headers({
          Authorization: accessToken,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          comment,
          expires_at: expire,
        }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/users/${props?.match?.params?.id}/ban`,
        options
      )
      const data = await response.json()
      console.log(data)
      console.log(data.message)
      if (data.status == "success") {
        toast.success(data["message"])
        setTimeout(() => {
          location.reload()
        }, 5000)
      } else if (data.status == "error") {
        toast.error(data["message"])
        setbuttonLoadBan(false)
        setDisableButton(false)
      }
    } catch (error) {
      // do what
    }
  }

  // Unban user
  const unbanUser = async () => {
    // do stuff
    setButtonLoadUnban(true)
    setDisableButton(true)
    try {
      const options = {
        method: "POST",
        headers: new Headers({
          Authorization: accessToken,
          "Content-Type": "application/json",
        }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/users/${props?.match?.params?.id}/unban`,
        options
      )
      const data = await response.json()
      console.log(data)
      console.log(data.message)
      if (data.status == "success") {
        toast.success(data["message"])
        setTimeout(() => {
          location.reload()
        }, 5000)
      } else if (data.status == "error") {
        toast.error(data["message"])
        setButtonLoadUnban(false)
        setDisableButton(false)
      }
    } catch (error) {
      // do what
    }
  }

  // Send Email
  const sendEmail = async e => {
    e.preventDefault()
    console.log(attachment)

    // do stuff
    // setbuttonLoadEmail(true)
    // setDisableButton(true)
    const dataForm = new FormData()
    dataForm.append("upload_preset", "dilivva")
    dataForm.append("attachments", attachment)
    dataForm.append("body", body)
    dataForm.append("subject", subject)
    console.log(dataForm, "data inputed")
    try {
      const options = {
        method: "POST",
        headers: new Headers({
          Authorization: accessToken,
          accept: "application/json",
          // "Content-Type": "multipart/form-data",
        }),

        body: dataForm,
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/users/${props?.match?.params?.id}/send-email`,
        options
      )
      const data = await response.json()
      console.log(data)
      console.log(data.errors)
      setErrors(data.errors)
      if (data.status == "success") {
        toast.success(data["message"])
        setbuttonLoadEmail(false)
        // setTimeout(() => {
        //   location.reload()
        // }, 5000)
      } else if (data.status == "error") {
        toast.error(data["message"])
        setbuttonLoadEmail(false)
      }
    } catch (error) {
      // do what
      console.log(error.message)
      setbuttonLoadEmail(false)
      setDisableButton(false)
    }
  }

  // Update User
  const updateUser = async () => {
    setDisableButton(true)
    // do stuff
    try {
      const options = {
        method: "PUT",
        headers: new Headers({
          Authorization: accessToken,
          "Content-Type": "application/json",
          accept: "application/json",
        }),
        body: JSON.stringify({
          first_name: profile.first_name,
          last_name: profile.last_name,
          email: profile.email,
          phone: profile.phone,
          is_influencer: profile.isInfluencer,
          commission_rate: profile.commission_rate,
          referral_code: code,
        }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/users/${props?.match?.params?.id}`,
        options
      )
      const data = await response.json()
      console.log(data)
      console.log(data.message)
      if (data.status == "success") {
        toast.success(data["message"])
        setbuttonLoadBan(false)
        setTimeout(() => {
          location.reload(1)
        }, 5000)
      } else if (data.status == "error") {
        toast.error(data["message"])
        setbuttonLoadBan(false)
        setDisableButton(false)
      }
    } catch (error) {
      // do what
    }
  }

  // Verify User
  const verifyUser = async () => {
    // do stuff
    setbuttonLoad(true)
    setDisableButton(true)
    try {
      const options = {
        method: "POST",
        headers: new Headers({
          Authorization: accessToken,
          "Content-Type": "application/json",
        }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/identity-verifications/${props?.match?.params?.id}/verify`,
        options
      )
      const data = await response.json()
      console.log(data)
      console.log(data.message)
      if (data.status == "success") {
        toast.success(data["message"])
        setbuttonLoad(false)
        setTimeout(() => {
          location.reload(1)
        }, 5000)
      } else if (data.status == "error") {
        toast.error(data["message"])
        setbuttonLoad(false)
        setDisableButton(false)
      }
    } catch (error) {
      // do what
    }
  }
  // Reject User
  const rejectUser = async () => {
    // do stuff
    setbuttonLoad(true)
    setDisableButton(true)
    try {
      const options = {
        method: "POST",
        headers: new Headers({
          Authorization: accessToken,
          "Content-Type": "application/json",
        }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/identity-verifications/${props?.match?.params?.id}/reject`,
        options
      )
      const data = await response.json()
      console.log(data)
      console.log(data.message)
      if (data.status == "success") {
        toast.success(data["message"])
        setbuttonLoad(false)
        setTimeout(() => {
          location.reload(1)
        }, 5000)
      } else if (data.status == "error") {
        toast.error(data["message"])
        setbuttonLoad(false)
        setDisableButton(false)
      }
    } catch (error) {
      // do what
    }
  }
  // Verify User
  const revokeUser = async () => {
    // do stuff
    setbuttonLoad(true)
    setDisableButton(true)
    try {
      const options = {
        method: "POST",
        headers: new Headers({
          Authorization: accessToken,
          "Content-Type": "application/json",
        }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/identity-verifications/${props?.match?.params?.id}/revoke`,
        options
      )
      const data = await response.json()
      console.log(data)
      console.log(data.message)
      if (data.status == "success") {
        toast.success(data["message"])
        setbuttonLoad(false)
        setDisableButton(false)
        setTimeout(() => {
          location.reload(1)
        }, 5000)
      } else if (data.status == "error") {
        toast.error(data["message"])
        setbuttonLoad(false)
      }
    } catch (error) {
      // do what
    }
  }

  // credit user wallet
  const creditWallet = async e => {
    e.preventDefault()
    let amount = creditAmount.toString().replace("₦ ", "").replace(",", "")
    setLoadWallet(true)
    setDisableButton(true)
    try {
      const options = {
        method: "POST",
        headers: new Headers({
          Authorization: accessToken,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          amount,
          narration,
        }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/users/${props?.match?.params?.id}/credit-wallet`,
        options
      )
      const data = await response.json()
      if (data.status == "success") {
        setLoadWallet(false)
        setCreditAmount("")
        setNarration("")
        toast.success(data["message"])
        setTimeout(() => {
          location.reload()
        }, 5000)
      } else if (data.status == "error") {
        toast.error(data["message"])
        setLoadWallet(false)
        setDisableButton(false)
      }
    } catch (error) {
      // do what
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>User Profile | Dilivva Admin</title>
        </MetaTags>
        <div className="container-fluid">
          <ToastContainer draggable={false} />
          <div
            className="mb-4 d-flex justify-content-center align-items-center"
            style={{
              gap: "4px",
              padding: "7px 18px 7px 12px",
              width: "76px",
              borderRadius: "32px",
              border: "1px solid #F0F0F0",
              background: "#FFF",
              boxShadow: "1px 1px 4px 0px rgba(0, 0, 0, 0.04)",
              marginBottom: "8px",
            }}
          >
            <Link
              to="/senders"
              className="text-black d-flex align-items-center"
            >
              <FaChevronLeft size={16} />
              <div
                style={{
                  color: "#000",
                  fontSize: "14px",
                  fontWeight: "600",
                  marginLeft: "4px",
                }}
              >
                Back
              </div>
            </Link>
          </div>
          <h2 className="profile-head mb-4">Sender Details</h2>
          {loading ? (
            <Row className="d-flex justify-content-center">
              <Col lg={11}>
                <Row>
                  <Col>
                    <Card className="profile-card">
                      <CardBody>
                        <Col md={12}>
                          <Row className="p-3">
                            <Col md={3}>
                              <div className="skel">
                                <div className="col-md-8 skeleton skeleton-circle"></div>
                              </div>
                            </Col>
                            <Col md={7} className="mt-1">
                              <div className="col-md-12 skeleton skeleton-head"></div>
                              <div className="col-md-12 skeleton skeleton-p"></div>
                              <div className="col-md-8 skeleton skeleton-p"></div>
                            </Col>
                            <Col
                              md={2}
                              className="d-flex justify-content-center align-items-center my-4"
                            >
                              <div className="col-md-12 skeleton skeleton-head"></div>
                            </Col>
                          </Row>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="profile-wallet">
                      <CardBody>
                        <Col md={12}>
                          <Row className="p-2">
                            <Col>
                              <div className="col-md-2 skeleton skeleton-p my-2 mb-4"></div>
                              <div className="col-md-3 skeleton skeleton-head p-3"></div>
                            </Col>
                          </Row>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <Row className="d-flex justify-content-center">
              <Col lg={11}>
                <Row>
                  <Col lg={7}>
                    <Card className="profile-card">
                      <CardBody>
                        <Col md={12}>
                          <Row className="px-3">
                            <Col md={2}>
                              <img
                                src="https://cdn.iconscout.com/icon/free/png-256/avatar-370-456322.png"
                                className="img-fluid"
                                alt={profile?.full_name}
                              />
                            </Col>
                            <Col md={8}>
                              <h3 className="profile-name">
                                {profile?.full_name}
                                <span
                                  className="mx-2"
                                  style={{ fontSize: "14px" }}
                                >
                                  {profile?.is_influencer && (
                                    <span>
                                      (Influencer) - {profile?.device_type}{" "}
                                      {profile?.device_version}
                                    </span>
                                  )}
                                </span>
                              </h3>
                              <h6 className="details">{profile?.email}</h6>
                              <h6 className="details">{profile?.phone}</h6>
                              {profile?.is_influencer ? (
                                <h6
                                  className="details"
                                >
                                  {profile?.referral_link}
                                </h6>
                              ) : (
                                <h6 className="details">
                                  {profile?.device_type}{" "}
                                  {profile?.device_version}
                                </h6>
                              )}
                            </Col>
                            <Col
                              md={2}
                              className="d-flex justify-content-start align-items-center my-4"
                            >
                              <button
                                className="change"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                              >
                                Update
                              </button>
                              <div
                                className="modal fade"
                                id="exampleModal"
                                tabIndex="-1"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true"
                              >
                                <div className="modal-dialog">
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <h5
                                        className="modal-title"
                                        id="exampleModalLabel"
                                      >
                                        Update User Details
                                      </h5>
                                      <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                    <div className="modal-body">
                                      <div className="container">
                                        <div className="row">
                                          <div className="col-md-6 mb-4">
                                            <span>First Name</span>
                                            <input
                                              type="text"
                                              value={profile?.first_name}
                                              onChange={e =>
                                                setProfile({
                                                  ...profile,
                                                  first_name: e.target.value,
                                                })
                                              }
                                            />
                                          </div>
                                          <div className="col-md-6 mb-4">
                                            <span>Last Name</span>
                                            <input
                                              type="text"
                                              value={profile?.last_name}
                                              onChange={e =>
                                                setProfile({
                                                  ...profile,
                                                  last_name: e.target.value,
                                                })
                                              }
                                            />
                                          </div>
                                          <div className="col-md-6 mb-4">
                                            <span>Email</span>
                                            <input
                                              type="text"
                                              value={profile?.email}
                                              onChange={e =>
                                                setProfile({
                                                  ...profile,
                                                  email: e.target.value,
                                                })
                                              }
                                            />
                                          </div>
                                          <div className="col-md-6 mb-4">
                                            <span>Phone</span>
                                            <input
                                              type="text"
                                              value={profile?.phone}
                                              onChange={e =>
                                                setProfile({
                                                  ...profile,
                                                  phone: e.target.value,
                                                })
                                              }
                                            />
                                          </div>
                                          <div className="col-md-6 mb-4">
                                            <span>Is an influencer</span>
                                            <select
                                              className="form-select"
                                              aria-label="Default select example shadow-none"
                                              onChange={e => {
                                                setProfile({
                                                  ...profile,
                                                  isInfluencer: e.target.value,
                                                })
                                              }}
                                            >
                                              <option defaultValue>
                                                {profile?.is_influencer
                                                  ? "True"
                                                  : "-- select --"}
                                              </option>
                                              {profile?.is_influencer ? (
                                                ""
                                              ) : (
                                                <option value={1}>True</option>
                                              )}
                                              <option value={0}>False</option>
                                            </select>
                                          </div>
                                          <div className="col-md-6 mb-4">
                                            <span>Commission</span>
                                            <input
                                              type="text"
                                              value={profile?.commission_rate}
                                              onChange={e =>
                                                setProfile({
                                                  ...profile,
                                                  commission_rate:
                                                    e.target.value,
                                                })
                                              }
                                            />
                                          </div>
                                          <div className="col-md-12 mb-4">
                                            <span>Add Referral Code</span>
                                            <input
                                              type="text"
                                              value={code}
                                              onChange={e =>
                                                setCode(e.target.value)
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="modal-footer">
                                      <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                      >
                                        Close
                                      </button>
                                      <button
                                        type="submit"
                                        className="send"
                                        disabled={disableButton}
                                        onClick={() => updateUser()}
                                      >
                                        Update
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="profile-wallet">
                      <CardBody>
                        <Col md={12}>
                          <Row className="p-2" style={{ height: '135px'}}>
                            <Col>
                              <p className="wallet">Wallet</p>
                              <h2 className="balance">
                                ₦ {profile?.wallet?.balance}
                              </h2>
                            </Col>
                          </Row>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}

          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <Col>
                    <div className="row feture-tabs" data-aos="fade-up">
                      <div className="col-lg-12">
                        <ul className="nav nav-pills mb-3">
                          <li>
                            <a
                              className="nav-link active"
                              data-bs-toggle="pill"
                              href="#tab1"
                            >
                              Details
                            </a>
                          </li>
                          <li>
                            <a
                              className="nav-link"
                              data-bs-toggle="pill"
                              href="#tab2"
                            >
                              Transactions
                            </a>
                          </li>
                          <li>
                            <a
                              className="nav-link"
                              data-bs-toggle="pill"
                              href="#tab3"
                            >
                              Orders
                            </a>
                          </li>
                          <li>
                            <a
                              className="nav-link"
                              data-bs-toggle="pill"
                              href="#tab4"
                            >
                              Payment Methods
                            </a>
                          </li>
                          <li>
                            <a
                              className="nav-link"
                              data-bs-toggle="pill"
                              href="#tab5"
                            >
                              Create Order
                            </a>
                          </li>
                          <li>
                            <a
                              className="nav-link"
                              data-bs-toggle="pill"
                              href="#tab6"
                            >
                              Virtual Account
                            </a>
                          </li>
                          <li>
                            <a
                              className="nav-link"
                              data-bs-toggle="pill"
                              href="#tab7"
                            >
                              Sales Rep
                            </a>
                          </li>
                        </ul>

                        <div className="tab-content">
                          <div className="tab-pane fade show active" id="tab1">
                            <div className="container-fluid mt-5">
                              <Row>
                                <Col lg={6}>
                                  <Card>
                                    <CardBody>
                                      <h4 className="profile-head mb-4">
                                        Credit User Wallet
                                      </h4>
                                      <Row>
                                        <Col>
                                          <form onSubmit={creditWallet}>
                                            <div className="mb-3">
                                              <CurrencyFormat
                                                value={creditAmount}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"₦ "}
                                                renderText={value => (
                                                  <>
                                                    <input
                                                      type="text"
                                                      placeholder="Amount"
                                                      required
                                                      value={value}
                                                      onChange={e => {
                                                        setCreditAmount(
                                                          e.target.value
                                                        )
                                                      }}
                                                    />
                                                  </>
                                                )}
                                              />
                                            </div>
                                            <div className="mb-3">
                                              <textarea
                                                className="form-control"
                                                rows={2}
                                                type="textarea"
                                                placeholder="Narration"
                                                required
                                                value={narration}
                                                onChange={e =>
                                                  setNarration(e.target.value)
                                                }
                                              />
                                            </div>
                                            <button
                                              type="submit"
                                              disabled={disableButton}
                                              className="send"
                                            >
                                              Credit Wallet
                                              {loadWallet && (
                                                <div
                                                  className="spinner-border text-light mx-3"
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                  }}
                                                  role="status"
                                                >
                                                  <span className="visually-hidden">
                                                    Loading...
                                                  </span>
                                                </div>
                                              )}
                                            </button>
                                          </form>
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Card>
                                </Col>
                                <Col lg={6}>
                                  <Card>
                                    <CardBody>
                                      <h4 className="profile-head mb-4">
                                        Send Email
                                      </h4>
                                      <Row>
                                        <Col md={12}>
                                          <form onSubmit={sendEmail}>
                                            <div className="mb-3">
                                              <input
                                                type="text"
                                                placeholder="Subject"
                                                required
                                                value={subject}
                                                onChange={e =>
                                                  setSubject(e.target.value)
                                                }
                                              />
                                            </div>
                                            <div className="mb-3">
                                              <textarea
                                                className="form-control"
                                                rows={5}
                                                type="textarea"
                                                placeholder="Body"
                                                required
                                                value={body}
                                                onChange={e =>
                                                  setBody(e.target.value)
                                                }
                                              />
                                            </div>
                                            <div className="mb-3">
                                              <input
                                                type="file"
                                                name="file[]"
                                                onChange={e => {
                                                  let new_doc = attachment
                                                  console.log(typeof new_doc)
                                                  new_doc.push(
                                                    e.target.files[0]
                                                  )

                                                  setAttachment(new_doc)
                                                  console.log(typeof attachment)
                                                }}
                                                multiple
                                              />
                                              {errors && (
                                                <p className="text-danger">
                                                  {errors.attachments}
                                                </p>
                                              )}
                                            </div>
                                            <button
                                              type="submit"
                                              disabled={disableButton}
                                              className="send"
                                            >
                                              Send{" "}
                                              {buttonLoadEmail && (
                                                <div
                                                  className="spinner-border text-light mx-3"
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                  }}
                                                  role="status"
                                                >
                                                  <span className="visually-hidden">
                                                    Loading...
                                                  </span>
                                                </div>
                                              )}
                                            </button>
                                          </form>
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Card>
                                </Col>
                                {profile?.identity_verification && (
                                  <Col lg={6}>
                                    <Card>
                                      <CardBody>
                                        <h4 className="profile-head mb-4">
                                          Bulk Delivery
                                        </h4>
                                        <Row>
                                          <Col md={7}>
                                            <img
                                              src={
                                                profile?.identity_verification
                                                  ?.image
                                              }
                                              className="img-fluid rounded"
                                              alt={profile?.first_name}
                                            />
                                            <small>
                                              {
                                                profile?.identity_verification
                                                  ?.number
                                              }
                                            </small>
                                            <p className="m-0">
                                              {
                                                profile?.identity_verification
                                                  ?.type
                                              }
                                            </p>
                                            <small
                                              style={{
                                                color:
                                                  profile?.identity_verification_status ===
                                                  "verified"
                                                    ? "#29CC97"
                                                    : profile?.identity_verification_status ===
                                                      "pending"
                                                    ? "#FEC400"
                                                    : "#DC1515",
                                              }}
                                            >
                                              {
                                                profile?.identity_verification_status
                                              }
                                            </small>
                                          </Col>
                                          <Col md={12}>
                                            <div className="mt-4">
                                              <div className="dropdown">
                                                <a
                                                  className="dropdown-toggle change"
                                                  href="#"
                                                  role="button"
                                                  id="dropdownMenuLink"
                                                  data-bs-toggle="dropdown"
                                                  aria-expanded="false"
                                                  disabled={disableButton}
                                                >
                                                  Change Status{" "}
                                                  {buttonLoad && (
                                                    <div
                                                      className="spinner-border text-light mx-1"
                                                      style={{
                                                        width: "20px",
                                                        height: "20px",
                                                        fontSize: "10px",
                                                      }}
                                                      role="status"
                                                    >
                                                      <span className="visually-hidden">
                                                        Loading...
                                                      </span>
                                                    </div>
                                                  )}
                                                </a>

                                                <ul
                                                  className="dropdown-menu"
                                                  aria-labelledby="dropdownMenuLink"
                                                >
                                                  {profile?.identity_verification_status !==
                                                    "verified" && (
                                                    <li>
                                                      <a
                                                        className="dropdown-item text-green"
                                                        onClick={() => {
                                                          verifyUser()
                                                        }}
                                                      >
                                                        Verify
                                                      </a>
                                                    </li>
                                                  )}
                                                  {profile?.identity_verification_status ===
                                                    "verified" && (
                                                    <li>
                                                      <a
                                                        className="dropdown-item text-orange"
                                                        onClick={() => {
                                                          revokeUser()
                                                        }}
                                                      >
                                                        Revoke
                                                      </a>
                                                    </li>
                                                  )}
                                                  <li>
                                                    <a
                                                      className="dropdown-item text-red"
                                                      onClick={() => {
                                                        rejectUser()
                                                      }}
                                                    >
                                                      Reject
                                                    </a>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                )}
                                <Col lg={6}>
                                  <Card>
                                    <CardBody>
                                      <Row>
                                        <Col md={12}>
                                          {profile?.is_banned === true ? (
                                            <div>
                                              <h4 className="profile-head mb-4">
                                                Unban User
                                              </h4>
                                              <h6 style={{ color: "#D97706" }}>
                                                Reasons For Ban
                                              </h6>
                                              <>
                                                {profile?.ban_history.map(
                                                  history => {
                                                    const {
                                                      banned_at,
                                                      banned_by,
                                                      comment,
                                                      expired_at,
                                                    } = history
                                                    return (
                                                      <div key={banned_at}>
                                                        <p>
                                                          <strong>
                                                            Banned At:
                                                          </strong>{" "}
                                                          {banned_at}
                                                        </p>
                                                        <p>
                                                          <strong>
                                                            Banned By:
                                                          </strong>{" "}
                                                          {banned_by
                                                            ? banned_by
                                                            : "Not specified"}
                                                        </p>
                                                        <p>
                                                          Comment:{" "}
                                                          <em>
                                                            {comment
                                                              ? comment
                                                              : "No comment"}
                                                          </em>
                                                        </p>
                                                        <p>{expired_at}</p>
                                                      </div>
                                                    )
                                                  }
                                                )}
                                              </>
                                              <button
                                                disabled={disableButton}
                                                className="change"
                                                onClick={() => unbanUser()}
                                              >
                                                Unban User{" "}
                                                {buttonLoadUnban && (
                                                  <div
                                                    className="spinner-border text-light mx-3"
                                                    style={{
                                                      width: "20px",
                                                      height: "20px",
                                                    }}
                                                    role="status"
                                                  >
                                                    <span className="visually-hidden">
                                                      Loading...
                                                    </span>
                                                  </div>
                                                )}
                                              </button>
                                            </div>
                                          ) : (
                                            <form onSubmit={banUser}>
                                              <h4 className="profile-head mb-4">
                                                Ban User
                                              </h4>
                                              <div className="mb-3">
                                                <textarea
                                                  className="form-control"
                                                  rows={5}
                                                  type="textarea"
                                                  placeholder="Reason for ban"
                                                  required
                                                  value={comment}
                                                  onChange={e =>
                                                    setComment(e.target.value)
                                                  }
                                                />
                                              </div>
                                              <div className="mb-3">
                                                <input
                                                  type="date"
                                                  placeholder="Expiry Date"
                                                  value={expire}
                                                  onChange={e =>
                                                    setExpire(e.target.value)
                                                  }
                                                />
                                              </div>
                                              <button
                                                type="submit"
                                                disabled={disableButton}
                                                className="change"
                                              >
                                                Ban User{" "}
                                                {buttonLoadBan && (
                                                  <div
                                                    className="spinner-border text-light mx-3"
                                                    style={{
                                                      width: "20px",
                                                      height: "20px",
                                                    }}
                                                    role="status"
                                                  >
                                                    <span className="visually-hidden">
                                                      Loading...
                                                    </span>
                                                  </div>
                                                )}
                                              </button>
                                            </form>
                                          )}
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Card>
                                </Col>
                              </Row>
                            </div>
                          </div>
                          <div className="tab-pane fade show" id="tab2">
                            <div className="container-fluid">
                              <Row>
                                <Col lg={12}>
                                  {loading ? (
                                    <div className="container">
                                      <div className="row">
                                        <div className="col-md-12 skeleton skeleton-search"></div>
                                        <div className="col-md-12 skeleton skeleton-search"></div>
                                        <div className="col-md-12 skeleton skeleton-search"></div>
                                        <div className="col-md-12 skeleton skeleton-search"></div>
                                        <div className="col-md-12 skeleton skeleton-search"></div>
                                        <div className="col-md-12 skeleton skeleton-search"></div>
                                        <div className="col-md-12 skeleton skeleton-search"></div>
                                        <div className="col-md-12 skeleton skeleton-search"></div>
                                        <div className="col-md-12 skeleton skeleton-search"></div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="table-responsive">
                                      {transactions.length !== 0 ? (
                                        <>
                                          <Row className="d-flex justify-content-end align-items-center mx-2">
                                            <Col md={1} xs={4}>
                                              <select
                                                className="form-select form-select-sm"
                                                aria-label=".form-select-sm example"
                                                value={perPage}
                                                onChange={e => {
                                                  setLoading(true)
                                                  setPerPage(e.target.value)
                                                }}
                                              >
                                                <option defaultValue>50</option>
                                                <option defaultValue="25">
                                                  25
                                                </option>
                                                <option defaultValue="10">
                                                  10
                                                </option>
                                                <option defaultValue="5">
                                                  5
                                                </option>
                                              </select>
                                            </Col>
                                            <Col md={1} xs={4}>
                                              <Row className="">
                                                <Col>
                                                  <FaArrowUp
                                                    className="sort"
                                                    onClick={() =>
                                                      setSort("asc")
                                                    }
                                                  />{" "}
                                                  <FaArrowDown
                                                    className="sort"
                                                    onClick={() =>
                                                      setSort("desc")
                                                    }
                                                  />
                                                </Col>
                                              </Row>
                                            </Col>
                                          </Row>
                                          <Table className="table responsive table-hover mt-2 mb-0">
                                            <thead>
                                              <tr>
                                                <th>Amount</th>
                                                <th>Description</th>
                                                <th>Reference</th>
                                                <th>Type</th>
                                                <th>Date</th>
                                                <th>Status</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {transactions?.map(
                                                transaction => {
                                                  const {
                                                    uuid,
                                                    amount,
                                                    created_at,
                                                    description,
                                                    status,
                                                    type,
                                                    reference,
                                                    wallet,
                                                  } = transaction
                                                  return (
                                                    <tr key={uuid}>
                                                      <td>₦{amount}</td>
                                                      <td>{description}</td>
                                                      <td>{reference}</td>
                                                      <td
                                                        style={{
                                                          color:
                                                            type === "credit"
                                                              ? "#29CC97"
                                                              : type === "debit"
                                                              ? "#DC1515"
                                                              : "#FEC400",
                                                        }}
                                                      >
                                                        {type}
                                                      </td>
                                                      <td>{created_at}</td>
                                                      <td
                                                        style={{
                                                          color:
                                                            status === "success"
                                                              ? "#29CC97"
                                                              : status ===
                                                                "pending"
                                                              ? "#FEC400"
                                                              : "#DC1515",
                                                        }}
                                                      >
                                                        {status}
                                                      </td>
                                                    </tr>
                                                  )
                                                }
                                              )}
                                            </tbody>
                                          </Table>
                                          <div className="float-end mt-3">
                                            <ReactPaginate
                                              previousLabel={"Prev"}
                                              nextLabel={"Next"}
                                              breakLabel={"..."}
                                              pageCount={Math.ceil(
                                                page?.total / page?.per_page
                                              )}
                                              marginPagesDisplayed={4}
                                              pageRangeDisplayed={6}
                                              onPageChange={handlePageClick}
                                              containerClassName={"pagination"}
                                              pageClassName={"page-item"}
                                              pageLinkClassName={"page-link"}
                                              previousClassName={"page-item"}
                                              previousLinkClassName={
                                                "page-link"
                                              }
                                              nextClassName={"page-item"}
                                              nextLinkClassName={"page-link"}
                                              breakClassName={"page-item"}
                                              breakLinkClassName={"page-link"}
                                              activeClassName={"active"}
                                            />
                                          </div>
                                        </>
                                      ) : (
                                        <h5 className="mt-3">
                                          No Transactions
                                        </h5>
                                      )}
                                    </div>
                                  )}
                                </Col>
                              </Row>
                            </div>
                          </div>

                          <div className="tab-pane fade show" id="tab3">
                            <Row className="d-flex justify-content-end align-items-center mx-2">
                              <Col md={1} xs={4}>
                                <select
                                  className="form-select form-select-sm"
                                  aria-label=".form-select-sm example"
                                  value={perPage}
                                  onChange={e => {
                                    setLoading(true)
                                    setPerPage(e.target.value)
                                  }}
                                >
                                  <option defaultValue>50</option>
                                  <option defaultValue="25">25</option>
                                  <option defaultValue="10">10</option>
                                  <option defaultValue="5">5</option>
                                </select>
                              </Col>
                              <Col md={1} xs={4}>
                                <Row className="">
                                  <Col>
                                    <FaArrowUp
                                      className="sort"
                                      onClick={() => setSort("asc")}
                                    />{" "}
                                    <FaArrowDown
                                      className="sort"
                                      onClick={() => setSort("desc")}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={12}>
                                <div className="table-responsive">
                                  {orders.length !== 0 ? (
                                    <>
                                      <Table className="table responsive table-hover mt-2 mb-0">
                                        <thead>
                                          <tr>
                                            <th>Date Created</th>
                                            <th>Pickup Address</th>
                                            <th>Reference</th>
                                            <th>Destination</th>
                                            <th>Price</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {orders?.map(order => {
                                            const {
                                              uuid,
                                              pickup_address,
                                              created_at,
                                              destination_address,
                                              status,
                                              price,
                                              reference,
                                              payment_status,
                                              transactions,
                                              partner,
                                            } = order
                                            return (
                                              <tr key={uuid}>
                                                <td>{created_at}</td>
                                                <td>{pickup_address}</td>
                                                <td>{reference}</td>
                                                <td>{destination_address}</td>
                                                <td>₦{price}</td>
                                                <td
                                                  style={{
                                                    color:
                                                      status === "completed"
                                                        ? "#29CC97"
                                                        : status === "cancelled"
                                                        ? "#DC1515"
                                                        : status === "accepted"
                                                        ? "greenyellow"
                                                        : "#FEC400",
                                                  }}
                                                >
                                                  {status}
                                                </td>
                                                <td>
                                                  <div className="dropdown">
                                                    <a
                                                      className="btn dropdown-toggle"
                                                      href="#"
                                                      role="button"
                                                      data-bs-toggle="dropdown"
                                                      aria-expanded="false"
                                                    >
                                                      <BsThreeDotsVertical />
                                                    </a>

                                                    <ul className="dropdown-menu action-item">
                                                      <li>
                                                        <Link
                                                          className="dropdown-item"
                                                          to={`/order/${uuid}`}
                                                        >
                                                          View
                                                        </Link>
                                                      </li>
                                                      <li>
                                                        {payment_status !==
                                                          "completed" && (
                                                          <a className="dropdown-item">
                                                            <span
                                                              onClick={() =>
                                                                retryPayment(
                                                                  uuid
                                                                )
                                                              }
                                                            >
                                                              Retry payment
                                                            </span>
                                                          </a>
                                                        )}
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </td>
                                              </tr>
                                            )
                                          })}
                                        </tbody>
                                      </Table>
                                      <div className="float-end mt-3">
                                        <ReactPaginate
                                          previousLabel={"Prev"}
                                          nextLabel={"Next"}
                                          breakLabel={"..."}
                                          pageCount={Math.ceil(
                                            page?.total / page?.per_page
                                          )}
                                          marginPagesDisplayed={4}
                                          pageRangeDisplayed={6}
                                          onPageChange={handlePageClick}
                                          containerClassName={"pagination"}
                                          pageClassName={"page-item"}
                                          pageLinkClassName={"page-link"}
                                          previousClassName={"page-item"}
                                          previousLinkClassName={"page-link"}
                                          nextClassName={"page-item"}
                                          nextLinkClassName={"page-link"}
                                          breakClassName={"page-item"}
                                          breakLinkClassName={"page-link"}
                                          activeClassName={"active"}
                                        />
                                      </div>
                                    </>
                                  ) : (
                                    <h5 className="mx-3">No Orders</h5>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </div>

                          <div className="tab-pane fade show" id="tab4">
                            {payments.length !== 0 ? (
                              payments.map(payment => {
                                const {
                                  account_name,
                                  bank,
                                  card_type,
                                  channel,
                                  last4,
                                  brand,
                                } = payment
                                return (
                                  <div key={payment.id}>
                                    <div className="container mt-5">
                                      <div className="row">
                                        <div className="col-md-4 payment">
                                          <h5 className="mb-4">{bank}</h5>
                                          <p className="number">
                                            5535 **** **** {last4}
                                          </p>
                                          <div className="row mt-5">
                                            <div className="col">
                                              <h6 className="mt-3">
                                                {account_name}
                                              </h6>
                                            </div>
                                            <div className="col">
                                              <img
                                                className="img-fluid mx-4"
                                                src={
                                                  brand === "visa"
                                                    ? visa
                                                    : mastercard
                                                }
                                                alt="card-type"
                                                width="100"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              })
                            ) : (
                              <h5 className="m-3">No Payments</h5>
                            )}
                          </div>
                          <div className="tab-pane fade show" id="tab5">
                            <div className="container-fluid">
                              <CreateOrder
                                userId={props?.match?.params?.id}
                                profile={profile}
                              />
                            </div>
                          </div>
                          <div className="tab-pane fade show" id="tab6">
                            <div className="container-fluid">
                              <VirtualAccount
                                userId={props?.match?.params?.id}
                              />
                            </div>
                          </div>
                          <div className="tab-pane fade show" id="tab7">
                            <div className="container-fluid">
                              <SalesRep
                                userId={props?.match?.params?.id}
                                profile={profile}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default UsersProfile
