import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Row, Col, Card, CardBody, Table } from "reactstrap"

import accessToken from "../../helpers/jwt-token-access/accessToken"

import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Link, Redirect } from "react-router-dom"
import { FaChevronLeft } from "react-icons/fa"
import baseURL from "../../utils"
import hub from "../../assets/images/delivery-hub.jpg"
import emptyState from "../../assets/images/empty-state.png"

const CenterDetails = props => {
  const [profile, setProfile] = useState({
    contact_person: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
    },
  })
  const [loading, setLoading] = useState(true)
  const [disableButton, setDisableButton] = useState(false)
  const [hubs, setHubs] = useState([])
  const [redirect, setRedirect] = useState("")

  // Center Details
  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/centers/${props?.match?.params?.id}`,
          options
        )
        const profile = await response.json()
        console.log(profile.data)
        setProfile(profile.data)
      } catch (error) {
        console.log("error", error)
        toast.error(error.message)
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  // fetch hubs
  useEffect(() => {
    const fetchHubs = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/hubs?center_id=${props?.match?.params?.id}`,
          options
        )
        const hubs = await response.json()
        console.log(hubs)
        setHubs(hubs.data.hubs)
        console.log(hubs)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error)
        toast.error(error.message)
      }
    }

    fetchHubs()
  }, [])

  // Toggle center status
  const toggleStatus = async () => {
    setDisableButton(true)
    try {
      const options = {
        method: "PUT",
        headers: new Headers({ Authorization: accessToken }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/centers/${props?.match?.params?.id}/toggle-status`,
        options
      )
      const profile = await response.json()
      setDisableButton(false)
      setProfile(profile.data)
    } catch (error) {
      console.log("error", error)
      toast.error(error.message)
      setDisableButton(false)
    }
  }

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Center Details | Dilivva Admin</title>
        </MetaTags>
        <div className="container-fluid">
          <ToastContainer draggable={false} />
          <div
            className="mb-4 d-flex justify-content-center align-items-center"
            style={{
              gap: "4px",
              padding: "7px 18px 7px 12px",
              width: "76px",
              borderRadius: "32px",
              border: "1px solid #F0F0F0",
              background: "#FFF",
              boxShadow: "1px 1px 4px 0px rgba(0, 0, 0, 0.04)",
              marginBottom: "8px",
            }}
          >
            <Link
              to="/centers"
              className="text-black d-flex align-items-center"
            >
              <FaChevronLeft size={16} />
              <div
                style={{
                  color: "#000",
                  fontSize: "14px",
                  fontWeight: "600",
                  marginLeft: "4px",
                }}
              >
                Back
              </div>
            </Link>
          </div>
          <h2 className="profile-head mb-4">center Details</h2>
          {loading ? (
            <Row className="d-flex justify-content-center">
              <Col lg={12}>
                <Row>
                  <Col md={8}>
                    <Card className="profile-card">
                      <CardBody>
                        <Col md={12}>
                          <Row className="">
                            <Col md={3}>
                              <div className="skel">
                                <div className="col-md-8 skeleton skeleton-circle"></div>
                              </div>
                            </Col>
                            <Col md={7} className="mx-3">
                              <div className="col-md-8 skeleton skeleton-head"></div>
                              <div className="col-md-10 skeleton skeleton-p"></div>
                              <div className="col-md-7 skeleton skeleton-p"></div>
                              <div className="col-md-6 skeleton skeleton-p"></div>
                            </Col>
                          </Row>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <CardBody>
                        <Col md={12}>
                          <Row className="p-2">
                            <Col>
                              <div className="col-md-2 skeleton skeleton-p mb-4"></div>
                              <div className="col-md-7 skeleton skeleton-head p-1"></div>
                              <div className="col-md-8 skeleton skeleton-head"></div>
                            </Col>
                          </Row>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <Row className="d-flex justify-content-center">
              <Col lg={12}>
                <Row>
                  <Col lg={8}>
                    <Card className="profile-card">
                      <CardBody>
                        <h5>Basic Profile</h5>
                        <hr />
                        <Row>
                          <Col md={9}>
                            <Row className="">
                              <Col md={2}>
                                <div className="profile">
                                  <img
                                    src={hub}
                                    className="img-fluid"
                                    alt={profile?.name}
                                  />
                                </div>
                              </Col>
                              <Col md={9} style={{ marginLeft: "35px" }}>
                                <h3 className="profile-name">
                                  {profile?.name}
                                </h3>
                                <h6 className="details">{profile?.address}</h6>
                                <h6 className="price-head">
                                  {profile?.local_government?.name}
                                </h6>
                                <h6>{profile?.email}</h6>
                                <div className="price-head">
                                  {profile?.is_active ? (
                                    <span className="text-green">Active</span>
                                  ) : (
                                    <span className="text-orange">
                                      Inactive
                                    </span>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col md={3}>
                            <div>
                              <button
                                disabled={disableButton}
                                className={`${
                                  profile?.is_active ? "change" : "send"
                                }`}
                                onClick={toggleStatus}
                              >
                                {profile?.is_active ? "Deactivate" : "Activate"}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg={4}>
                    <Card className="profile-card">
                      <CardBody>
                        <Col md={12}>
                          <Row className="p-2">
                            <h5>Contact Person</h5>
                            <hr />
                            <h6>{profile?.contact_person?.name}</h6>
                            <p className="text-orange">
                              {profile?.contact_person?.email}
                            </p>
                            <p>{profile?.contact_person?.phone}</p>
                          </Row>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col lg={12}>
                <Card className="profile-card">
                  <CardBody>
                    <h5>Hubs</h5>
                    <hr />
                    {loading ? (
                      <div className="container">
                        <div className="row">
                          <div className="col-md-2 mb-5 skeleton skeleton-search"></div>
                          <div className="col-md-12 skeleton skeleton-search"></div>
                          <div className="col-md-12 skeleton skeleton-search"></div>
                          <div className="col-md-12 skeleton skeleton-search"></div>
                          <div className="col-md-10 skeleton skeleton-search"></div>
                        </div>
                      </div>
                    ) : (
                      <Row>
                        <Col md={12}>
                          {hubs?.length !== 0 ? (
                            <div className="table-responsive">
                              <Table className="table responsive table-hover mt-2 mb-0">
                                <thead>
                                  <tr>
                                    <th>Name</th>
                                    <th>Address</th>
                                    <th>Contact Person</th>
                                    <th>BDO Name</th>
                                    <th>State</th>
                                    <th>Date Created</th>
                                    <th>Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {hubs?.map((hubs, index) => {
                                    const {
                                      uuid,
                                      name,
                                      address,
                                      contact_person,
                                      state,
                                      city,
                                      is_active,
                                      created_at,
                                      bdo_name,
                                    } = hubs
                                    return (
                                      <tr
                                        key={uuid}
                                        className="table-row"
                                        onClick={() =>
                                          setRedirect("/hubs/" + uuid)
                                        }
                                      >
                                        <td>
                                          {name}
                                          <br />
                                          {contact_person?.phone}
                                        </td>
                                        <td>{address}</td>
                                        <td>
                                          {contact_person?.name}
                                          <br />
                                          <span className="text-green">
                                            {contact_person?.email}
                                          </span>
                                        </td>
                                        <td>
                                          {bdo_name ? bdo_name : "No name"}
                                        </td>
                                        <td>
                                          {city}
                                          {city ? "," : " "} {state}
                                        </td>
                                        <td>{created_at}</td>
                                        <td
                                          style={{
                                            color:
                                              is_active === true
                                                ? "#29CC97"
                                                : "#FEC400",
                                          }}
                                        >
                                          {is_active ? "ACTIVE" : "NOT ACTIVE"}
                                        </td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </Table>
                            </div>
                          ) : (
                            <div className="d-flex flex-column justify-content-center align-items-center">
                              <img
                                src={emptyState}
                                alt="empty state"
                                width={206}
                                height={206}
                              />
                              <p
                                style={{
                                  maxWidth: "185px",
                                  textAlign: "center",
                                  color: "#8D8D8D",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  lineHeight: "21px",
                                }}
                              >
                                No hubs found
                              </p>
                            </div>
                          )}
                        </Col>
                      </Row>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default CenterDetails
