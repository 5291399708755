import accessToken from "../../helpers/jwt-token-access/accessToken"
import { useEffect, useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import baseURL from "../../utils"
import { Autocomplete } from "@react-google-maps/api"
import { FaChevronLeft } from "react-icons/fa"
import { Link } from "react-router-dom"

const CreateCenters = () => {
  const [details, setDetails] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    name: "",
    stateId: "",
    lga: "",
    location: "",
  })
  const [contact, setContact] = useState({
    name: "",
    phone: "",
    email: "",
    address: "",
  })
  const [states, setStates] = useState([])
  const [lgas, setLgas] = useState([])
  const [loading, setLoading] = useState(true)
  const [disableButton, setDisableButton] = useState(false)
  const [errors, setErrors] = useState({})
  const [error, setError] = useState("")
  const [address, setAddress] = useState(null)
  const [available, setAvailable] = useState(null)
  const [coordinates, setCoordinates] = useState("")
  const [locations, setLocations] = useState([])

  const [autocomplete, setAutocomplete] = useState(null)

  const onLoad = auto => {
    setAutocomplete(auto)
    // Set the country restriction to Nigeria
    auto.setComponentRestrictions({ country: "NG" })
  }

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const address = autocomplete.getPlace()

      if (address.geometry) {
        const lat = address.geometry.location.lat()
        const lng = address.geometry.location.lng()

        setAddress(`${address.name}, ${address.formatted_address}`)

        let Coord = `${lat}, ${lng}`
        setCoordinates(Coord)

        let state =
          address.formatted_address.split(",")[
            address.formatted_address.split(",").length - 2
          ]

        checkLocation(state, Coord)
      }
    } else {
      console.log("Autocomplete is not loaded yet!")
    }
  }

  // Fetch states
  useEffect(() => {
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    }
    fetch(`${baseURL}/api/v1/misc/states`, options)
      .then(data => data.json())
      .then(response => {
        setLoading(false)
        console.log(response.data)
        setStates(response.data)
      })
      .catch(error => {
        console.log(error.message)
        setLoading(false)
      })
  }, [])

  // Fetch LGA
  const fetchLgas = async id => {
    setLoading(true)
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    }
    await fetch(`${baseURL}/api/v1/misc/local-governments/${id}`, options)
      .then(data => data.json())
      .then(response => {
        setLoading(false)
        setLgas(response.data)
      })
      .catch(error => {
        console.log(error.message)
        setLoading(false)
      })
  }

  // Fetch locations
  useEffect(() => {
    const options = {
      method: "GET",
      headers: new Headers({
        Authorization: accessToken,
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    }
    fetch(`${baseURL}/api/v1/misc/locations`, options)
      .then(data => data.json())
      .then(response => {
        setLoading(false)
        console.log(response.data)
        setLocations(response.data)
      })
      .catch(error => {
        console.log(error.message)
        setLoading(false)
      })
  }, [])

  // check pickup location availability
  const checkLocation = (state, coordinates) => {
    setAvailable("")
    const options = {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
      body: JSON.stringify({
        state,
        coordinates,
      }),
    }
    fetch(`${baseURL}/api/v1/misc/confirm-location`, options)
      .then(data => data.json())
      .then(response => {
        if (response.status !== "success") {
          toast.error(response.message)
          setAvailable(response.message)
        } else {
        }
      })
      .catch(error => {
        console.log(error.message)
      })
  }

  // Create Center
  const handleSubmit = async e => {
    e.preventDefault()
    // do stuff
    setDisableButton(true)
    setErrors({})
    try {
      const options = {
        method: "POST",
        headers: new Headers({
          Authorization: accessToken,
          "Content-Type": "application/json",
          accept: "application/json",
        }),
        body: JSON.stringify({
          contact_person_name: contact.name,
          contact_person_email: details.email,
          contact_person_phone: details.phone,
          contact_person_address: address,
          name: details.name,
          phone: details.phone,
          email: details.email,
          state_id: details.stateId,
          local_government_id: details.lga,
          address,
          coordinates,
          location_id: details.location,
        }),
      }
      const response = await fetch(`${baseURL}/api/v1/admin/centers`, options)
      const data = await response.json()
      console.log(data)
      console.log(data.errors)

      if (data.errors) {
        console.log(data.errors)
        toast.error(data["message"])
        setDisableButton(false)
        setErrors(data.errors)
      } else {
        toast.success(data["message"])
        setTimeout(() => {
          window.location.href = "/centers"
        }, 5000)
      }
    } catch (error) {
      // do what
      console.log(error)
      setDisableButton(false)
    }
  }

  return (
    <section className="mt-5">
      <ToastContainer />
      <div className="container">
        <div className="row my-5 d-flex justify-content-center">
          <div className="col-md-11 mt-5">
            <div className="card">
              <div className="card-body">
                <div
                className="mx-lg-5 my-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "4px",
                    padding: "7px 18px 7px 12px",
                    width: "76px",
                    borderRadius: "32px",
                    border: "1px solid #F0F0F0",
                    background: "#FFF",
                    boxShadow: "1px 1px 4px 0px rgba(0, 0, 0, 0.04)",
                    marginBottom: "8px",
                  }}
                >
                  <Link
                    to="/centers"
                    className="text-black d-flex align-items-center"
                  >
                    <FaChevronLeft size={16} />
                    <div
                      style={{
                        color: "#000",
                        fontSize: "14px",
                        fontWeight: "600",
                        marginLeft: "4px",
                      }}
                    >
                      Back
                    </div>
                  </Link>
                </div>
                <h3 className="text-green mx-lg-5">Create Center</h3>
                <div className="row mx-lg-5">
                  <div className="col-md-12">
                    <form onSubmit={handleSubmit}>
                      <div className="row mt-2">
                        <h6 className="text-orange">Center Details</h6>
                        <hr />
                        <div className="col-md-6 mb-4">
                          <label
                            htmlFor="centerName"
                            className="fw-bold text-green"
                          >
                            Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Center name"
                            value={details.name}
                            onChange={e =>
                              setDetails({
                                ...details,
                                name: e.target.value,
                              })
                            }
                            required
                          />
                          <small className="text-danger">{errors?.name}</small>
                        </div>
                        <div className="col-md-6 mb-4">
                          <label
                            htmlFor="CenterEmail"
                            className="fw-bold text-green"
                          >
                            Email
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Center email"
                            value={details.email}
                            onChange={e =>
                              setDetails({
                                ...details,
                                email: e.target.value,
                              })
                            }
                            required
                          />
                          <small className="text-danger">{errors?.email}</small>
                        </div>
                        <div className="col-md-6 mb-4">
                          <label
                            htmlFor="CenterPhone"
                            className="fw-bold text-green"
                          >
                            Phone
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter phone"
                            value={details.phone}
                            onChange={e =>
                              setDetails({
                                ...details,
                                phone: e.target.value,
                              })
                            }
                            required
                          />
                          <small className="text-danger">{errors?.phone}</small>
                        </div>
                        <div className="col-md-6 mb-4">
                          <label
                            htmlFor="address"
                            className="fw-bold text-green"
                          >
                            Address
                          </label>
                          <Autocomplete
                            onLoad={onLoad}
                            onPlaceChanged={onPlaceChanged}
                          >
                            <input
                              type="text"
                              placeholder="Enter Address"
                              onChange={setAddress}
                            />
                          </Autocomplete>
                          <small className="text-danger">{available}</small>
                        </div>
                        <div className="col-md-6 mb-4">
                          <label htmlFor="state" className="fw-bold text-green">
                            State
                          </label>
                          <select
                            required
                            type="text"
                            value={details.stateId}
                            className={`${
                              error?.state_id ? "border-danger" : ""
                            } form-select shadow-none`}
                            onChange={e => {
                              setDetails({
                                ...details,
                                stateId: e.target.value,
                              })
                              // setSelectedState(e.target.selectedIndex)
                              fetchLgas(e.target.value)
                            }}
                          >
                            <option value="">select State</option>
                            {loading
                              ? "Loading..."
                              : states?.map(state => {
                                  const { name, id } = state
                                  return (
                                    <option key={id} value={id}>
                                      {name}
                                    </option>
                                  )
                                })}
                          </select>
                        </div>
                        <div className="col-md-6 mb-4">
                          <label htmlFor="lga" className="fw-bold text-green">
                            Local Government
                          </label>
                          <select
                            required
                            type="text"
                            className={`${
                              error?.state_id ? "border-danger" : ""
                            } form-select shadow-none`}
                            value={details.lga}
                            onChange={e =>
                              setDetails({ ...details, lga: e.target.value })
                            }
                          >
                            <option value="">Select LGA</option>
                            {lgas?.map(lga => {
                              const { name, id } = lga
                              return (
                                <option key={id} value={id}>
                                  {name}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                        <div className="col-md-6 mb-4">
                          <label htmlFor="state" className="fw-bold text-green">
                            Location
                          </label>
                          <select
                            required
                            type="text"
                            value={details.location}
                            className="form-select shadow-none"
                            onChange={e => {
                              setDetails({
                                ...details,
                                location: e.target.value,
                              })
                            }}
                          >
                            <option value="">Select Location</option>
                            {loading
                              ? "Loading..."
                              : locations?.map(location => {
                                  const { name, uuid } = location
                                  return (
                                    <option key={uuid} value={uuid}>
                                      {name}
                                    </option>
                                  )
                                })}
                          </select>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <h6 className="text-orange">Contact Person</h6>
                        <hr />
                        <div className="col-md-12 mb-4">
                          <label
                            htmlFor="firstName"
                            className="fw-bold text-green"
                          >
                            Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter your name"
                            value={contact.name}
                            onChange={e =>
                              setContact({
                                ...details,
                                name: e.target.value,
                              })
                            }
                            required
                          />
                          <small className="text-danger">
                            {errors?.contact_person_name}
                          </small>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <button
                          type="submit"
                          className="send mt-4"
                          disabled={disableButton}
                        >
                          {disableButton
                            ? "Creating Center..."
                            : "Create Center"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CreateCenters
