import accessToken from "../../helpers/jwt-token-access/accessToken"
import { useEffect, useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import baseURL from "../../utils"
import { Autocomplete } from "@react-google-maps/api"
import Dropzone from "./Dropzone"

import { FaSpinner } from "react-icons/fa6"
import { FaChevronLeft } from "react-icons/fa"
import { Link } from "react-router-dom"
import ReactPaginate from "react-paginate"

const CreateHubs = () => {
  const [details, setDetails] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    name: "",
    stateId: "",
    lga: "",
    bdoName: "",
    referral: "",
  })
  const [contact, setContact] = useState({
    name: "",
    phone: "",
    email: "",
    address: "",
  })
  const [states, setStates] = useState([])
  const [lgas, setLgas] = useState([])
  const [loading, setLoading] = useState(true)
  const [disableButton, setDisableButton] = useState(false)
  const [errors, setErrors] = useState({})
  const [error, setError] = useState("")
  const [address, setAddress] = useState("")
  const [address2, setAddress2] = useState("")
  const [available, setAvailable] = useState(null)
  const [coordinates, setCoordinates] = useState("")
  const [banks, setBanks] = useState([])
  const [bankCode, setBankCode] = useState("")
  const [number, setNumber] = useState("")
  const [name, setName] = useState("")
  const [showName, setShowName] = useState(false)
  const [spinner, setSpinner] = useState(false)
  const [spinners, setSpinners] = useState(false)
  const [bankName, setBankName] = useState("bankName")
  const [centers, setCenters] = useState([])
  const [centerId, setCenterId] = useState("")
  const [busStop, setBusStop] = useState("")
  const [landmark, setLandmark] = useState("")
  const [addressNote, setAddressNote] = useState("")
  const [city, setCity] = useState("")
  const [files, setFiles] = useState([])
  const [selectedDays, setSelectedDays] = useState([])
  const [openingTime, setOpeningTime] = useState("")
  const [closingTime, setClosingTime] = useState("")

  const [autocomplete, setAutocomplete] = useState(null)
  const [token, setToken] = useState("")
  const [image, setImage] = useState(null)
  const [imageId, setImageId] = useState([])
  const [perPage, setPerPage] = useState(50)
  const [next, setNext] = useState(1)
  const [lastPage, setLastPage] = useState({})

  const onLoad = auto => {
    setAutocomplete(auto)
    // Set the country restriction to Nigeria
    auto.setComponentRestrictions({ country: "NG" })
  }

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const address = autocomplete.getPlace()

      if (address.geometry) {
        const lat = address.geometry.location.lat()
        const lng = address.geometry.location.lng()

        setAddress(`${address.name}, ${address.formatted_address}`)

        let Coord = `${lat}, ${lng}`
        setCoordinates(Coord)

        let state =
          address.formatted_address.split(",")[
            address.formatted_address.split(",").length - 2
          ]

        checkLocation(state, Coord)
      }
    } else {
      console.log("Autocomplete is not loaded yet!")
    }
  }
  // Fetch states
  useEffect(() => {
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    }
    fetch(`${baseURL}/api/v1/misc/states`, options)
      .then(data => data.json())
      .then(response => {
        setLoading(false)
        console.log(response.data)
        setStates(response.data)
      })
      .catch(error => {
        console.log(error.message)
        setLoading(false)
      })
  }, [])

  // Fetch LGA
  const fetchLgas = async id => {
    setLoading(true)
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    }
    await fetch(`${baseURL}/api/v1/misc/local-governments/${id}`, options)
      .then(data => data.json())
      .then(response => {
        setLoading(false)
        setLgas(response.data)
      })
      .catch(error => {
        console.log(error.message)
        setLoading(false)
      })
  }

  // check pickup location availability
  const checkLocation = (state, coordinates) => {
    setAvailable("")
    const options = {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
      body: JSON.stringify({
        state,
        coordinates,
      }),
    }
    fetch(`${baseURL}/api/v1/misc/confirm-location`, options)
      .then(data => data.json())
      .then(response => {
        if (response.status !== "success") {
          toast.error(response.message)
          setAvailable(response.message)
        } else {
        }
      })
      .catch(error => {
        console.log(error.message)
      })
  }

  // fetch center
  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/centers?perPage=${perPage}&page=${next}`,
          options
        )
        const centers = await response.json()
        console.log(centers.data)
        setCenters(centers.data.centers)
        setLastPage(centers.data.meta)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error)
        toast.error(error.message)
      }
    }

    fetchData()
  }, [next, perPage])

  const handlePageClick = data => {
    setNext(data.selected + 1)
  }

  const handleSelect = uuid => {
    setCenterId(uuid)
  }

  // Fetch supported banks
  useEffect(() => {
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    }
    fetch(`${baseURL}/api/v1/misc/banks?country=nigeria`, options)
      .then(data => data.json())
      .then(response => {
        console.log(response.data, "banks")
        setBanks(response.data)
        setLoading(false)
      })
      .catch(error => {
        console.log(error.message)
      })
  }, [])

  // Resolve Account

  const resolveAccount = async code => {
    setSpinner(true)
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    }
    fetch(
      `${baseURL}/api/v1/misc/resolve-bank-account?bank_code=${code}&account_number=${number}`,
      options
    )
      .then(data => data.json())
      .then(response => {
        setSpinner(false)
        if (response.status === true && code && number) {
          setName(response.data.account_name)
          console.log(response)
          setShowName(true)
        } else if (response.status === "error") {
          setShowName(false)
          setError(response.message)
          console.log(response.message)
        }
      })
      .catch(error => {
        console.log(error.message)
        setSpinner(false)
      })
  }

  // Function to handle day selection
  const handleDaySelect = day => {
    if (selectedDays.includes(day)) {
      setSelectedDays(selectedDays.filter(selectedDay => selectedDay !== day))
    } else {
      setSelectedDays([...selectedDays, day])
    }
  }

  // Function to handle time change
  const handleTimeChange = (e, type) => {
    const value = e.target.value
    if (type === "opening") {
      setOpeningTime(value)
    } else if (type === "closing") {
      setClosingTime(value)
    }
  }

  // Fetch access token
  useEffect(() => {
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    }
    fetch(`${baseURL}/api/v1/fetch-access-token`, options)
      .then(data => data.json())
      .then(response => {
        setToken(response.data.access_token)
      })
      .catch(error => {
        console.log(error.message)
      })
  }, [])

  // Create hub
  const handleSubmit = async e => {
    e.preventDefault()

    // Function to save opening hours
    let openingHours = {
      Monday: selectedDays.includes("Monday")
        ? { open: openingTime, close: closingTime }
        : null,
      Tuesday: selectedDays.includes("Tuesday")
        ? { open: openingTime, close: closingTime }
        : null,
      Wednesday: selectedDays.includes("Wednesday")
        ? { open: openingTime, close: closingTime }
        : null,
      Thursday: selectedDays.includes("Thursday")
        ? { open: openingTime, close: closingTime }
        : null,
      Friday: selectedDays.includes("Friday")
        ? { open: openingTime, close: closingTime }
        : null,
      Saturday: selectedDays.includes("Saturday")
        ? { open: openingTime, close: closingTime }
        : null,
      Sunday: selectedDays.includes("Sunday")
        ? { open: openingTime, close: closingTime }
        : null,
    }
    console.log(openingHours)
    // do stuff
    setDisableButton(true)
    setErrors({})
    try {
      const options = {
        method: "POST",
        headers: new Headers({
          Authorization: accessToken,
          "Content-Type": "application/json",
          accept: "application/json",
        }),
        body: JSON.stringify({
          contact_person_name: contact.name,
          contact_person_email: details.email,
          contact_person_phone: details.phone,
          contact_person_address: address,
          name: details.name,
          phone: details.phone,
          email: details.email,
          state_id: details.stateId,
          local_government_id: details.lga,
          address,
          coordinates,
          account_name: name,
          account_number: number,
          bank_code: bankCode,
          bank_name: bankName,
          center_id: centerId,
          address_2: address2,
          closest_bus_stop: busStop,
          major_landmark: landmark,
          extra_address_note: addressNote,
          city,
          operational_schedule: openingHours,
          images: imageId,
          bdo_name: details.bdoName,
          referred_by: details.referral,
        }),
      }
      const response = await fetch(`${baseURL}/api/v1/admin/hubs`, options)
      const data = await response.json()
      console.log(data)
      console.log(data.errors)

      if (data.errors) {
        console.log(data.errors)
        toast.error(data["message"])
        setDisableButton(false)
        setErrors(data.errors)
      } else {
        toast.success(data["message"])
        setTimeout(() => {
          window.location.href = "/hubs"
        }, 5000)
      }
    } catch (error) {
      // do what
      console.log(error)
      setDisableButton(false)
    }
  }

  return (
    <section className="mt-5">
      <ToastContainer />
      <div className="container">
        <div className="row my-5 d-flex justify-content-center">
          <div className="col-md-11 mt-5">
            <div className="card">
              <div className="card-body">
                <div
                  className="mx-lg-5 my-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "4px",
                    padding: "7px 18px 7px 12px",
                    width: "76px",
                    borderRadius: "32px",
                    border: "1px solid #F0F0F0",
                    background: "#FFF",
                    boxShadow: "1px 1px 4px 0px rgba(0, 0, 0, 0.04)",
                    marginBottom: "8px",
                  }}
                >
                  <Link
                    to="/hubs"
                    className="text-black d-flex align-items-center"
                  >
                    <FaChevronLeft size={16} />
                    <div
                      style={{
                        color: "#000",
                        fontSize: "14px",
                        fontWeight: "600",
                        marginLeft: "4px",
                      }}
                    >
                      Back
                    </div>
                  </Link>
                </div>
                <h3 className="text-green mx-lg-5">Create Hub</h3>
                <div className="row mx-lg-5">
                  <div className="col-md-12">
                    <form onSubmit={handleSubmit}>
                      <div className="row mt-2">
                        <h6 className="text-orange">Hub Details</h6>
                        <hr />
                        <div className="col-md-6 mb-4">
                          <label
                            htmlFor="hubName"
                            className="fw-bold text-green"
                          >
                            Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter hub name"
                            value={details.name}
                            onChange={e =>
                              setDetails({
                                ...details,
                                name: e.target.value,
                              })
                            }
                            required
                          />
                          <small className="text-danger">{errors?.name}</small>
                        </div>
                        <div className="col-md-6 mb-4">
                          <label
                            htmlFor="hubName"
                            className="fw-bold text-green"
                          >
                            Email
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter hub email"
                            value={details.email}
                            onChange={e =>
                              setDetails({
                                ...details,
                                email: e.target.value,
                              })
                            }
                            required
                          />
                          <small className="text-danger">{errors?.email}</small>
                        </div>
                        <div className="col-md-6 mb-4">
                          <label
                            htmlFor="hubName"
                            className="fw-bold text-green"
                          >
                            Phone
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter phone"
                            value={details.phone}
                            onChange={e =>
                              setDetails({
                                ...details,
                                phone: e.target.value,
                              })
                            }
                            required
                          />
                          <small className="text-danger">{errors?.phone}</small>
                        </div>
                        <div className="col-md-6 mb-4">
                          <label
                            htmlFor="address"
                            className="fw-bold text-green"
                          >
                            Address
                          </label>
                          <Autocomplete
                            onLoad={onLoad}
                            onPlaceChanged={onPlaceChanged}
                          >
                            <input
                              type="text"
                              placeholder="Enter Address"
                              onChange={setAddress}
                            />
                          </Autocomplete>
                          <small className="text-danger">{available}</small>
                        </div>

                        <div className="col-md-6 mb-4">
                          <label
                            htmlFor="hubName"
                            className="fw-bold text-green"
                          >
                            Closest bus stop
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter bus stop"
                            value={busStop}
                            onChange={e => setBusStop(e.target.value)}
                          />
                          <small className="text-danger">{errors?.phone}</small>
                        </div>
                        <div className="col-md-6 mb-4">
                          <label
                            htmlFor="hubName"
                            className="fw-bold text-green"
                          >
                            Major landmark
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter landmark"
                            value={landmark}
                            onChange={e => setLandmark(e.target.value)}
                          />
                          <small className="text-danger">{errors?.phone}</small>
                        </div>
                        <div className="col-md-6 mb-4">
                          <label
                            htmlFor="hubName"
                            className="fw-bold text-green"
                          >
                            Address Note
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Address Note"
                            value={addressNote}
                            onChange={e => setAddressNote(e.target.value)}
                          />
                          <small className="text-danger">{errors?.phone}</small>
                        </div>
                        <div className="col-md-6 mb-4">
                          <label
                            htmlFor="hubName"
                            className="fw-bold text-green"
                          >
                            City
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter city"
                            value={city}
                            onChange={e => setCity(e.target.value)}
                            required
                          />
                          <small className="text-danger">{errors?.phone}</small>
                        </div>
                        <div className="col-md-6 mb-4">
                          <label htmlFor="state" className="fw-bold text-green">
                            State
                          </label>
                          <select
                            required
                            type="text"
                            value={details.stateId}
                            className={`${
                              error?.state_id ? "border-danger" : ""
                            } form-select shadow-none`}
                            onChange={e => {
                              setDetails({
                                ...details,
                                stateId: e.target.value,
                              })
                              // setSelectedState(e.target.selectedIndex)
                              fetchLgas(e.target.value)
                            }}
                          >
                            <option value="">select State</option>
                            {loading
                              ? "Loading..."
                              : states?.map(state => {
                                  const { name, id } = state
                                  return (
                                    <option key={id} value={id}>
                                      {name}
                                    </option>
                                  )
                                })}
                          </select>
                        </div>
                        <div className="col-md-6 mb-4">
                          <label htmlFor="lga" className="fw-bold text-green">
                            Local Government
                          </label>
                          <select
                            required
                            type="text"
                            className={`${
                              error?.state_id ? "border-danger" : ""
                            } form-select shadow-none`}
                            value={details.lga}
                            onChange={e =>
                              setDetails({ ...details, lga: e.target.value })
                            }
                          >
                            <option value="">Select LGA</option>
                            {lgas?.map(lga => {
                              const { name, id } = lga
                              return (
                                <option key={id} value={id}>
                                  {name}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                        <div className="col-md-6 mb-4">
                          <label
                            htmlFor="hubName"
                            className="fw-bold text-green"
                          >
                            Address 2
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Address2"
                            value={address2}
                            onChange={e => setAddress2(e.target.value)}
                            required
                          />
                          <small className="text-danger">{errors?.phone}</small>
                        </div>
                        <div className="col-md-6 mb-4">
                          <label
                            htmlFor="coordinates"
                            className="fw-bold text-green"
                          >
                            Coordinates
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter coordinates"
                            value={coordinates}
                            onChange={e => setCoordinates(e.target.value)}
                            required
                          />
                        </div>
                        <div className="col-md-6 mb-4">
                          <label
                            htmlFor="bdoName"
                            className="fw-bold text-green"
                          >
                            BDO Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter business developer officer name"
                            value={details.bdoName}
                            onChange={e =>
                              setDetails({
                                ...details,
                                bdoName: e.target.value,
                              })
                            }
                            required
                          />
                          <small className="text-danger">
                            {errors?.bdo_name}
                          </small>
                        </div>
                        <div className="col-md-6 mb-4">
                          <label
                            htmlFor="referer"
                            className="fw-bold text-green"
                          >
                            Referral Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter referer name"
                            value={details.referral}
                            onChange={e =>
                              setDetails({
                                ...details,
                                referral: e.target.value,
                              })
                            }
                          />
                          <small className="text-danger">
                            {errors?.bdo_name}
                          </small>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <h6 className="text-orange">Contact Person</h6>
                        <hr />
                        <div className="col-md-12 mb-4">
                          <label
                            htmlFor="firstName"
                            className="fw-bold text-green"
                          >
                            Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter your name"
                            value={contact.name}
                            onChange={e =>
                              setContact({
                                ...details,
                                name: e.target.value,
                              })
                            }
                            required
                          />
                          <small className="text-danger">
                            {errors?.contact_person_name}
                          </small>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <h6 className="text-orange">Images</h6>
                        <hr />
                        <div className="row mt-4">
                          <Dropzone
                            files={files}
                            setFiles={setFiles}
                            token={token}
                            imageId={imageId}
                          />
                        </div>
                      </div>
                      <div className="row mt-4">
                        <h6 className="text-orange">Bank Account</h6>
                        <hr />
                        <div className="row mt-4">
                          <div className="col-md-6">
                            <label>Account Number</label>
                            <input
                              type="text"
                              className="form-control shadow-none"
                              placeholder="Enter Account Number"
                              maxLength={10}
                              value={number}
                              onChange={e => {
                                const re = /^[0-9\b]+$/
                                if (
                                  e.target.value === "" ||
                                  re.test(e.target.value)
                                ) {
                                  setNumber(e.target.value)
                                }
                              }}
                              required
                              onKeyUp={e => {
                                if (bankCode && number.length === 10) {
                                  resolveAccount(bankCode)
                                }
                              }}
                            />
                          </div>
                          <div className="col-md-6">
                            <label>Select Bank</label>
                            <select
                              className="form-select form-control shadow-none"
                              required
                              value={bankCode}
                              onChange={e => {
                                setBankCode(e.target.value)
                                let code = e.target.value
                                resolveAccount(code)
                              }}
                            >
                              <option value="">--Select---</option>
                              {banks?.map(bank => {
                                const { id, code, name } = bank
                                return (
                                  <option key={id} value={code}>
                                    {name}
                                  </option>
                                )
                              })}
                            </select>
                          </div>
                          {spinner ? (
                            <div
                              className="spinner-border text-green mt-1"
                              role="status"
                              style={{
                                fontSize: "10px",
                              }}
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          ) : showName ? (
                            <div className="col-md-6">
                              <small className="mb-4 text-green fw-bold">
                                {name}
                              </small>
                            </div>
                          ) : (
                            <small className="text-danger">{error}</small>
                          )}
                        </div>
                      </div>
                      <div className="row mt-4">
                        <h6 className="text-orange">Center</h6>
                        <hr />
                        <div className="row mt-4">
                          <div className="col-md-12">
                            <label>Select Center</label>
                            <select
                              className="form-select form-control shadow-none"
                              required
                              value={centerId}
                              onChange={e => {
                                setCenterId(e.target.value)
                              }}
                            >
                              <option value="">--Select---</option>
                              {centers?.map(center => {
                                const { uuid, name, location } = center
                                return (
                                  <option key={uuid} value={uuid}>
                                    {name}, {location.name}
                                  </option>
                                )
                              })}
                            </select>
                            <div className="mt-3">
                              <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                pageCount={Math.ceil(
                                  lastPage?.total / lastPage?.per_page
                                )}
                                marginPagesDisplayed={4}
                                pageRangeDisplayed={6}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <h6 className="text-orange">Opening Hours</h6>
                        <hr />
                        <div className="row mt-4">
                          <div>
                            <div>
                              <p>Select days:</p>
                              {[
                                "Monday",
                                "Tuesday",
                                "Wednesday",
                                "Thursday",
                                "Friday",
                                "Saturday",
                                "Sunday",
                              ].map(day => (
                                <div>
                                  <label key={day}>
                                    <input
                                      type="checkbox"
                                      checked={selectedDays.includes(day)}
                                      onChange={() => handleDaySelect(day)}
                                    />{" "}
                                    {day}
                                  </label>
                                </div>
                              ))}
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <p>Opening Time:</p>
                                <input
                                  type="time"
                                  value={openingTime}
                                  onChange={e => handleTimeChange(e, "opening")}
                                  required
                                />
                              </div>
                              <div className="col-md-6">
                                <p>Closing Time:</p>
                                <input
                                  type="time"
                                  value={closingTime}
                                  onChange={e => handleTimeChange(e, "closing")}
                                  required
                                />
                              </div>
                            </div>
                            <div></div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <button
                          type="submit"
                          className="send mt-4"
                          disabled={disableButton}
                        >
                          {disableButton ? "Creating Hub..." : "Create Hub"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CreateHubs
