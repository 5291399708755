import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Row, Col, Card, CardBody } from "reactstrap"

import accessToken from "../../helpers/jwt-token-access/accessToken"

import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Link } from "react-router-dom"
import { FaChevronLeft } from "react-icons/fa"
import baseURL from "../../utils"
import { Autocomplete } from "@react-google-maps/api"

const CorporateClientDetails = props => {
  const [profile, setProfile] = useState({
    contact_person: {
      full_name: "",
      email: "",
      phone: "",
      last_name: "",
      contact_person_name: "",
    },
  })
  const [loading, setLoading] = useState(true)
  const [tiers, setTiers] = useState([])
  const [address, setAddress] = useState("")
  const [autocomplete, setAutocomplete] = useState(null)
  const [available, setAvailable] = useState(null)
  const [coordinates, setCoordinates] = useState("")
  const [stateId, setStateId] = useState("")
  const [disableButton, setDisableButton] = useState(false)
  const [showUpdate, setShowUpdate] = useState(false)
  const [states, setStates] = useState([])
  const [tierId, setTierId] = useState("")

  // Corporate Client Details
  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/corporates/${props?.match?.params?.id}`,
          options
        )
        const profile = await response.json()
        console.log(profile.data)
        setLoading(false)
        setProfile(profile.data)
        setAddress(profile.data.address)
        setCoordinates(profile.data.address_coordinates)
        setStateId(profile.data.state.id)
        setTierId(profile.data.tier.uuid)
      } catch (error) {
        console.log("error", error)
        toast.error(error.message)
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  // Fetch tiers
  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(`${baseURL}/api/v1/admin/tiers`, options)
        const tiers = await response.json()
        console.log(tiers.data)
        setTiers(tiers.data)
        console.log(tiers.data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error)
        toast.error(error.message)
      }
    }

    fetchData()
  }, [])

  // Fetch states
  useEffect(() => {
    const options = {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
      }),
    }
    fetch(`${baseURL}/api/v1/misc/states`, options)
      .then(data => data.json())
      .then(response => {
        setLoading(false)
        console.log(response.data)
        setStates(response.data)
      })
      .catch(error => {
        console.log(error.message)
        setLoading(false)
      })
  }, [])

  const onLoad = auto => {
    setAutocomplete(auto)
    // Set the country restriction to Nigeria
    auto.setComponentRestrictions({ country: "NG" })
  }

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const address = autocomplete.getPlace()

      if (address.geometry) {
        const lat = address.geometry.location.lat()
        const lng = address.geometry.location.lng()

        setAddress(`${address.name}, ${address.formatted_address}`)

        let Coord = `${lat}, ${lng}`
        setCoordinates(Coord)

        let state =
          address.formatted_address.split(",")[
            address.formatted_address.split(",").length - 2
          ]
        console.log(state.toLowerCase())

        for (let i = 0; i < states.length; i++) {
          if (
            states[i].name.trim().toLowerCase() === state.trim().toLowerCase()
          ) {
            setStateId(states[i].id)
            console.log(states[i].id)
          }
        }

        checkLocation(state, Coord)
      }
    } else {
      console.log("Autocomplete is not loaded yet!")
    }
  }

  // check pickup location availability
  const checkLocation = (state, coordinates) => {
    setAvailable("")
    const options = {
      method: "POST",
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
      body: JSON.stringify({
        state,
        coordinates,
      }),
    }
    fetch(`${baseURL}/api/v1/misc/confirm-location`, options)
      .then(data => data.json())
      .then(response => {
        if (response.status !== "success") {
          toast.error(response.message)
          setAvailable(response.message)
        } else {
        }
      })
      .catch(error => {
        console.log(error.message)
      })
  }

  // Update Corporate Details
  const updateCorporate = async e => {
    e.preventDefault()
    setDisableButton(true)
    try {
      const options = {
        method: "PUT",
        headers: new Headers({
          Authorization: accessToken,
          "Content-Type": "application/json",
          accept: "application/json",
        }),
        body: JSON.stringify({
          name: profile.full_name,
          email: profile.email,
          phone: profile.phone,
          tier_id: tierId,
          contact_person_name: profile.contact_person_name,
          address_coordinates: coordinates,
          address,
          state_id: stateId,
        }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/corporates/${props?.match?.params?.id}`,
        options
      )
      const data = await response.json()
      console.log(data)
      console.log(data.errors)

      if (data.errors) {
        console.log(data.errors)
        toast.error(data["message"])
        setDisableButton(false)
        setErrors(data.errors)
      } else {
        toast.success(data["message"])
        setTimeout(() => {
          window.location.reload()
        }, 3000)
      }
    } catch (error) {
      console.log(error)
      setDisableButton(false)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Corporate Client Details | Dilivva Admin</title>
        </MetaTags>
        <div className="container-fluid">
          <ToastContainer draggable={false} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "4px",
              padding: "7px 18px 7px 12px",
              width: "76px",
              borderRadius: "32px",
              border: "1px solid #F0F0F0",
              background: "#FFF",
              boxShadow: "1px 1px 4px 0px rgba(0, 0, 0, 0.04)",
              marginBottom: "8px",
            }}
          >
            <Link
              to="/corporates"
              className="text-black d-flex align-items-center"
            >
              <FaChevronLeft size={16} />{" "}
              <div
                style={{
                  color: "#000",
                  fontSize: "14px",
                  fontWeight: "600",
                  marginLeft: "4px",
                }}
              >
                Back
              </div>
            </Link>
          </div>
          <h5 className="mb-4">Corporate Client Details</h5>
          {loading ? (
            <Row className="d-flex justify-content-center">
              <Col lg={11}>
                <Row>
                  <Col md={8}>
                    <Card className="profile-card">
                      <CardBody>
                        <Col md={12}>
                          <Row className="">
                            <Col md={10} className="mx-3">
                              <div className="col-md-8 skeleton skeleton-head"></div>
                              <div className="col-md-10 skeleton skeleton-p"></div>
                              <div className="col-md-7 skeleton skeleton-p"></div>
                              <div className="col-md-6 skeleton skeleton-p"></div>
                            </Col>
                          </Row>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <CardBody>
                        <Col md={12}>
                          <Row className="p-2">
                            <Col>
                              <div className="col-md-2 skeleton skeleton-p mb-4"></div>
                              <div className="col-md-7 skeleton skeleton-head p-1"></div>
                              <div className="col-md-8 skeleton skeleton-head"></div>
                            </Col>
                          </Row>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <Row className="d-flex justify-content-center">
              <Col lg={12}>
                <Row>
                  <Col lg={12}>
                    <Card className="profile-card">
                      <CardBody>
                        <div className="row">
                          <div className="col-md-12">
                            <form onSubmit={updateCorporate}>
                              <div className="row mt-2">
                                <h6 className="text-orange">Tier Details</h6>
                                <hr />
                                <div className="col-md-6 mb-4">
                                  <label
                                    htmlFor="centerName"
                                    className="fw-bold text-green"
                                  >
                                    Full Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Tier name"
                                    value={profile?.full_name}
                                    onChange={e => {
                                      setShowUpdate(true)
                                      setProfile({
                                        ...profile,
                                        full_name: e.target.value,
                                      })
                                    }}
                                    required
                                  />
                                </div>
                                <div className="col-md-6 mb-4">
                                  <label
                                    htmlFor="email"
                                    className="fw-bold text-green"
                                  >
                                    Email
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter email"
                                    value={profile.email}
                                    onChange={e => {
                                      setShowUpdate(true)
                                      setProfile({
                                        ...profile,
                                        email: e.target.value,
                                      })
                                    }}
                                    required
                                  />
                                </div>
                                <div className="col-md-6 mb-4">
                                  <label
                                    htmlFor="CenterPhone"
                                    className="fw-bold text-green"
                                  >
                                    Phone
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={profile.phone}
                                    onChange={e => {
                                      setShowUpdate(true)
                                      setProfile({
                                        ...profile,
                                        phone: e.target.value,
                                      })
                                    }}
                                    required
                                  />
                                </div>
                                <div className="col-md-6 mb-4">
                                  <label
                                    htmlFor="CenterPhone"
                                    className="fw-bold text-green"
                                  >
                                    Contact Person
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={profile?.contact_person_name}
                                    onChange={e => {
                                      setShowUpdate(true)
                                      setProfile({
                                        ...profile,
                                        contact_person_name: e.target.value,
                                      })
                                    }}
                                    required
                                  />
                                </div>
                                <div className="col-md-6 mb-4">
                                  <label
                                    htmlFor="address"
                                    className="fw-bold text-green"
                                  >
                                    Address
                                  </label>
                                  <Autocomplete
                                    onLoad={onLoad}
                                    onPlaceChanged={onPlaceChanged}
                                  >
                                    <input
                                      type="text"
                                      value={address}
                                      placeholder="Enter Address"
                                      onChange={e => {
                                        setShowUpdate(true)
                                        setAddress(e.target.value)
                                      }}
                                    />
                                  </Autocomplete>
                                  <small className="text-danger">
                                    {available}
                                  </small>
                                </div>
                                <div className="col-md-6 mb-4">
                                  <label
                                    htmlFor="tier"
                                    className="fw-bold text-green"
                                  >
                                    Tier
                                  </label>
                                  <select
                                    className="form-control pb-2 pt-3"
                                    value={tierId}
                                    onChange={e => {
                                      setShowUpdate(true)
                                      setTierId(e.target.value)
                                    }}
                                  >
                                    {tiers?.map(tier => {
                                      const { name, id, uuid } = tier
                                      return (
                                        <>
                                          <option value={uuid} key={id}>
                                            {name}
                                          </option>
                                        </>
                                      )
                                    })}
                                  </select>
                                </div>
                              </div>
                              {!showUpdate && (
                                <div className="details-row mx-0">
                                  <div className="row">
                                    <div className="col-md-3">
                                      <h6 className="details-name">Limit</h6>
                                      <h4 className="details-info">
                                        ₦ {profile?.tier?.limit}
                                      </h4>
                                    </div>

                                    <div className="col-md-3">
                                      <h6 className="details-name">
                                        Description
                                      </h6>
                                      <h4 className="details-info">
                                        {profile?.tier?.description}
                                      </h4>
                                    </div>
                                    <div className="col-md-3">
                                      <h6 className="details-name">Wallet</h6>
                                      <h4 className="details-info">
                                        ₦{profile?.wallet?.balance}
                                      </h4>
                                    </div>
                                    <div className="col-md-3">
                                      <h6 className="details-name">
                                        Accrued Amount
                                      </h6>
                                      <h4 className="details-info">
                                        ₦{profile?.accrued_amount}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {showUpdate && (
                                <div className="d-flex justify-content-end">
                                  <button
                                    type="submit"
                                    className="send mt-4"
                                    disabled={disableButton}
                                  >
                                    {disableButton
                                      ? "Updating Corporate Client..."
                                      : "Update Corporate Client"}
                                  </button>
                                </div>
                              )}
                            </form>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default CorporateClientDetails
