import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Row, Col, Card, CardBody, Table } from "reactstrap"

import accessToken from "../../helpers/jwt-token-access/accessToken"

import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Link } from "react-router-dom"
import { FaChevronLeft } from "react-icons/fa"
import baseURL from "../../utils"
import hub from "../../assets/images/delivery-hub.jpg"
import emptyState from "../../assets/images/empty-state.png"
import { MdEdit } from "react-icons/md"
import Address from "./Address"

const HubDetails = props => {
  const [profile, setProfile] = useState({
    name: "",
    phone: "",
    email: "",
    address: "",
    address_2: "",
    center: {
      uuid: "",
    },
    contact_person: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
    },
    coordinates: "",
  })

  const [loading, setLoading] = useState(true)
  const [loadingOrders, setLoadingOrders] = useState(true)
  const [disableButton, setDisableButton] = useState(false)
  const [orders, setOrders] = useState([])
  const [centers, setCenters] = useState([])
  const [orderType, setOrderType] = useState("shelved")
  const [fileId, setFileId] = useState("")
  const [fileId1, setFileId1] = useState("")
  const [fileId2, setFileId2] = useState("")
  const [showEdit, setShowEdit] = useState(true)
  const [location, setLocation] = useState("")
  const [coordinates, setCoordinates] = useState("")

  // Hub Details
  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/hubs/${props?.match?.params?.id}`,
          options
        )
        const profile = await response.json()
        console.log(profile.data)
        setLoading(false)
        setProfile(profile.data)

        // Extract the file ID using a regular expression
        const fileIdRegex = /id=([^&]+)/
        const match = profile?.data?.images[0]?.match(fileIdRegex)
        const match1 = profile?.data?.images[1]?.match(fileIdRegex)
        const match2 = profile?.data?.images[2]?.match(fileIdRegex)
        console.log(match, match1, match2)

        if (match && match[1]) {
          // Set the extracted file ID to the state
          setFileId(match[1])
        }
        if (match1 && match[1]) {
          // Set the extracted file ID to the state
          setFileId1(match1[1])
        }
        if (match2 && match[1]) {
          // Set the extracted file ID to the state
          setFileId2(match2[1])
        }
      } catch (error) {
        console.log("error", error)
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  // Toggle hub status
  const toggleStatus = async () => {
    setDisableButton(true)
    try {
      const options = {
        method: "PUT",
        headers: new Headers({ Authorization: accessToken }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/hubs/${props?.match?.params?.id}/toggle-status`,
        options
      )
      const profile = await response.json()
      setDisableButton(false)
      setProfile(profile.data)
    } catch (error) {
      console.log("error", error)
      toast.error(error.message)
      setDisableButton(false)
    }
  }

  // Fetch hub orders
  useEffect(() => {
    setLoadingOrders(true)
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/hubs/${props?.match?.params?.id}/orders?type=${orderType}`,
          options
        )
        const orders = await response.json()
        console.log(orders.data.orders)
        setOrders(orders.data.orders)
        setLoadingOrders(false)

        setLoading(false)
      } catch (error) {
        setLoading(false)
        toast.error(error.message)
        setLoadingOrders(false)
      }
    }

    fetchData()
  }, [orderType])

  // Fetch centers
  useEffect(() => {
    const fetchCenter = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(`${baseURL}/api/v1/admin/centers`, options)
        const centers = await response.json()
        console.log(centers.data)
        setCenters(centers.data.centers)
        console.log(centers)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error)
        toast.error(error.message)
      }
    }

    fetchCenter()
  }, [])

  // Update hub details
  const updateHub = async () => {
    console.log(location, coordinates)
    // do stuff
    setDisableButton(true)
    try {
      const options = {
        method: "PUT",
        headers: new Headers({
          Authorization: accessToken,
          "Content-Type": "application/json",
          accept: "application/json",
        }),
        body: JSON.stringify({
          name: profile.name,
          email: profile.email,
          phone: profile.phone,
          address: profile.address,
          coordinates: profile.coordinates,
          contact_person_name: profile.contact_person.name,
          contact_person_phone: profile.contact_person.phone,
          contact_person_email: profile.contact_person.email,
          contact_person_address: profile.contact_person.address,
          state_id: profile.state_id,
          local_government_id: profile.local_government_id,
          center_id: profile.center.uuid,
          account_name: profile.bank_account.account_name,
          account_number: profile.bank_account.account_number,
          bank_code: profile.bank_account.bank_code,
          bank_name: profile.bank_account.bank_name,
          address_2: profile?.address_2,
          closest_bus_stop: profile.closest_bus_stop,
          major_landmark: profile.major_landmark,
          extra_address_note: profile.extra_address_note,
          city: profile.city,
          operational_schedule: {
            Monday:
              profile?.operational_schedule?.Monday?.open &&
              profile?.operational_schedule?.Monday?.close
                ? {
                    open: profile?.operational_schedule?.Monday?.open,
                    close: profile?.operational_schedule?.Monday?.close,
                  }
                : null,
            Tuesday:
              profile?.operational_schedule?.Tuesday?.open &&
              profile?.operational_schedule?.Tuesday?.close
                ? {
                    open: profile?.operational_schedule?.Tuesday?.open,
                    close: profile?.operational_schedule?.Tuesday?.close,
                  }
                : null,
            Wednesday:
              profile?.operational_schedule?.Wednesday?.open &&
              profile?.operational_schedule?.Wednesday?.close
                ? {
                    open: profile?.operational_schedule?.Wednesday?.open,
                    close: profile?.operational_schedule?.Wednesday?.close,
                  }
                : null,
            Thursday:
              profile?.operational_schedule?.Thursday?.open &&
              profile?.operational_schedule?.Thursday?.close
                ? {
                    open: profile?.operational_schedule?.Thursday?.open,
                    close: profile?.operational_schedule?.Thursday?.close,
                  }
                : null,
            Friday:
              profile?.operational_schedule?.Friday?.open &&
              profile?.operational_schedule?.Friday?.close
                ? {
                    open: profile?.operational_schedule?.Friday?.open,
                    close: profile?.operational_schedule?.Friday?.close,
                  }
                : null,
            Saturday:
              profile?.operational_schedule?.Saturday?.open &&
              profile?.operational_schedule?.Saturday?.close
                ? {
                    open: profile?.operational_schedule?.Saturday?.open,
                    close: profile?.operational_schedule?.Saturday?.close,
                  }
                : null,
            Sunday:
              profile?.operational_schedule?.Sunday?.open &&
              profile?.operational_schedule?.Sunday?.close
                ? {
                    open: profile?.operational_schedule?.Sunday?.open,
                    close: profile?.operational_schedule?.Sunday?.close,
                  }
                : null,
          },
          images: [fileId],
          bdo_name: profile.bdo_name,
        }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/hubs/${props?.match?.params?.id}`,
        options
      )
      const data = await response.json()
      console.log(data)
      console.log(data.message)
      if (data.status === "success") {
        toast.success(data["message"])
      } else {
        toast.error(data.message)
      }
      setDisableButton(false)
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    } catch (error) {
      // do what
      toast.error(error["message"])
      setDisableButton(false)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Hub Details | Dilivva Admin</title>
        </MetaTags>
        <div className="container-fluid">
          <ToastContainer draggable={false} />
          {showEdit ? (
            <>
              <div
                className="mb-4 d-flex justify-content-center align-items-center"
                style={{
                  gap: "4px",
                  padding: "7px 18px 7px 12px",
                  width: "76px",
                  borderRadius: "32px",
                  border: "1px solid #F0F0F0",
                  background: "#FFF",
                  boxShadow: "1px 1px 4px 0px rgba(0, 0, 0, 0.04)",
                  marginBottom: "8px",
                }}
              >
                <Link
                  to="/hubs"
                  className="text-black d-flex align-items-center"
                >
                  <FaChevronLeft size={16} />
                  <div
                    style={{
                      color: "#000",
                      fontSize: "14px",
                      fontWeight: "600",
                      marginLeft: "4px",
                    }}
                  >
                    Back
                  </div>
                </Link>
              </div>
              <h2 className="profile-head mb-4">Hub Details</h2>
              {loading ? (
                <Row className="d-flex justify-content-center">
                  <Col lg={12}>
                    <Row>
                      <Col md={8}>
                        <Card className="profile-card">
                          <CardBody>
                            <Col md={12}>
                              <Row className="">
                                <Col md={3}>
                                  <div className="skel">
                                    <div className="col-md-8 skeleton skeleton-circle"></div>
                                  </div>
                                </Col>
                                <Col md={7} className="mx-3">
                                  <div className="col-md-8 skeleton skeleton-head"></div>
                                  <div className="col-md-10 skeleton skeleton-p"></div>
                                  <div className="col-md-7 skeleton skeleton-p"></div>
                                  <div className="col-md-6 skeleton skeleton-p"></div>
                                </Col>
                              </Row>
                            </Col>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col>
                        <Card>
                          <CardBody>
                            <Col md={12}>
                              <Row className="p-2">
                                <Col>
                                  <div className="col-md-2 skeleton skeleton-p mb-4"></div>
                                  <div className="col-md-7 skeleton skeleton-head p-1"></div>
                                  <div className="col-md-8 skeleton skeleton-head"></div>
                                </Col>
                              </Row>
                            </Col>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : (
                <Row className="d-flex justify-content-center">
                  <Col lg={12}>
                    <Row>
                      <Col lg={12}>
                        <Card className="profile-card">
                          <CardBody>
                            <div className="d-flex justify-content-between align-items-center mb-0">
                              <h5>Basic Profile</h5>
                              <button
                                className="edit"
                                onClick={() => setShowEdit(false)}
                              >
                                {" "}
                                <MdEdit /> Edit
                              </button>
                            </div>
                            <hr />
                            <Row>
                              <Col md={10}>
                                <Row className="">
                                  <Col md={2}>
                                    <div className="profile">
                                      <img
                                        src={
                                          fileId !== ""
                                            ? `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media&key=AIzaSyB7_h2Tg1Eoq2v5IXxtJpdd3giUNP6t_Vc&supportsAllDrives=True`
                                            : hub
                                        }
                                        className="img-fluid"
                                        alt={profile?.name}
                                      />
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <h3 className="profile-name text-green">
                                      {profile?.name}
                                    </h3>
                                    <h6 className="cont">{profile?.address}</h6>
                                    <h6 className="cont">
                                      {profile?.local_government?.name}
                                    </h6>

                                    <h6 className="cont">
                                      {profile?.extra_address_note}
                                    </h6>
                                  </Col>
                                  <Col md={3} className="mt-4">
                                    <h6 className="cont">
                                      {profile?.local_government},{" "}
                                      {profile?.city}
                                    </h6>
                                    <h6 className="cont">
                                      {profile?.major_landmark}
                                    </h6>
                                    <h6 className="cont">{profile?.email}</h6>
                                  </Col>
                                </Row>
                              </Col>
                              <Col md={2}>
                                <div>
                                  <button
                                    disabled={disableButton}
                                    className="change"
                                    onClick={toggleStatus}
                                  >
                                    Toggle status
                                  </button>
                                </div>
                                <div className="price-head mt-2 d-flex justify-content-center">
                                  {profile?.is_active ? (
                                    <span className="text-green">Active</span>
                                  ) : (
                                    <span className="text-orange">
                                      Inactive
                                    </span>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={8}>
                        <Card className="profile-card">
                          <CardBody>
                            <h6>Center</h6>
                            <hr />
                            <Row>
                              <Col lg={12}>
                                <small>Address</small>
                                <h6>{profile?.center?.address}</h6>
                              </Col>
                              <Col lg={3}>
                                <small>Center Name</small>
                                <h6>{profile?.center?.name}</h6>
                              </Col>
                              <Col lg={4}>
                                <small>Contact Person</small>
                                <h6>{profile?.center?.contact_person?.name}</h6>
                              </Col>
                              <Col lg={5}>
                                <small>Email</small>
                                <h6>
                                  {profile?.center?.contact_person?.email}
                                </h6>
                              </Col>
                              <Col lg={3}>
                                <small>Phone</small>
                                <h6>
                                  {profile?.center?.contact_person?.phone}
                                </h6>
                              </Col>
                              <Col lg={4}>
                                <small>City</small>
                                <h6>
                                  {profile?.city}, {profile?.city}
                                </h6>
                              </Col>
                              <Col lg={5}>
                                <small>Date Created</small>
                                <h6>{profile?.created_at}</h6>
                              </Col>
                              <Col lg={5}>
                                <small>Business Development Officer</small>
                                <h6>
                                  {profile?.bdo_name
                                    ? profile?.bdo_name
                                    : "No name"}
                                </h6>
                              </Col>
                            </Row>
                            <h6 className="mt-5">Bank Account</h6>
                            <hr className="p-0 m-0" />
                            <Row className="mt-3">
                              <Col lg={6}>
                                <small>Account Name</small>
                                <h6>{profile?.bank_account?.account_name}</h6>
                              </Col>
                              <Col lg={3}>
                                <small>Account Number</small>
                                <h6>{profile?.bank_account?.account_number}</h6>
                              </Col>
                              <Col lg={3}>
                                <small>Bank Name</small>
                                <h6>{profile?.bank_account?.bank_name}</h6>
                              </Col>
                              <Col lg={3}>
                                <small>Wallet Balance</small>
                                <h6>₦{profile?.wallet?.balance}</h6>
                              </Col>
                            </Row>
                            {profile?.images && (
                              <>
                                <h6 className="mt-5">Images</h6>
                                <hr className="p-0 m-0" />
                                <Row className="mt-3">
                                  <Col lg={4}>
                                    <img
                                      src={
                                        fileId !== ""
                                          ? `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media&key=AIzaSyB7_h2Tg1Eoq2v5IXxtJpdd3giUNP6t_Vc&supportsAllDrives=True`
                                          : hub
                                      }
                                      alt="images"
                                      width="100%"
                                      height={200}
                                      className="rounded my-2"
                                      style={{
                                        objectFit: "contain",
                                      }}
                                    />
                                  </Col>
                                  {profile?.images[1] && (
                                    <Col lg={4}>
                                      <img
                                        src={
                                          fileId1 !== ""
                                            ? `https://www.googleapis.com/drive/v3/files/${fileId1}?alt=media&key=AIzaSyB7_h2Tg1Eoq2v5IXxtJpdd3giUNP6t_Vc&supportsAllDrives=True`
                                            : hub
                                        }
                                        alt="images"
                                        width="100%"
                                        height={200}
                                        className="rounded my-2"
                                        style={{
                                          objectFit: "contain",
                                        }}
                                      />
                                    </Col>
                                  )}
                                  {profile?.images[2] && (
                                    <Col lg={4}>
                                      <img
                                        src={
                                          fileId2 !== ""
                                            ? `https://www.googleapis.com/drive/v3/files/${fileId2}?alt=media&key=AIzaSyB7_h2Tg1Eoq2v5IXxtJpdd3giUNP6t_Vc&supportsAllDrives=True`
                                            : hub
                                        }
                                        alt="images"
                                        width="100%"
                                        height={200}
                                        className="rounded my-2"
                                        style={{
                                          objectFit: "contain",
                                        }}
                                      />
                                    </Col>
                                  )}
                                </Row>
                              </>
                            )}
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg={4}>
                        <Card className="profile-card">
                          <CardBody>
                            <Col md={12}>
                              <Row className="p-2">
                                <h6>Hub</h6>
                                <hr />
                                <small>Contact Person</small>
                                <h6>{profile?.contact_person?.name}</h6>
                                <small>Email</small>
                                <h6>{profile?.contact_person?.email}</h6>
                                <small>Phone</small>
                                <h6>{profile?.contact_person?.phone}</h6>
                              </Row>
                              {profile?.operational_schedule && (
                                <Row className="p-2 mt-3">
                                  <h6>Opening Hours</h6>
                                  <hr />
                                  <div>
                                    <div className="col-10 d-flex justify-content-between items-center">
                                      <small>Monday</small>
                                      <h6>
                                        {
                                          profile?.operational_schedule?.Monday
                                            ?.open
                                        }{" "}
                                        -{" "}
                                        {
                                          profile?.operational_schedule?.Monday
                                            ?.close
                                        }
                                      </h6>
                                    </div>
                                    <div className="col-10 d-flex justify-content-between items-center">
                                      <small>Tuesday</small>
                                      <h6>
                                        {
                                          profile?.operational_schedule?.Tuesday
                                            ?.open
                                        }{" "}
                                        -{" "}
                                        {
                                          profile?.operational_schedule?.Tuesday
                                            ?.close
                                        }
                                      </h6>
                                    </div>
                                    <div className="col-10 d-flex justify-content-between items-center">
                                      <small>Wednesday</small>
                                      <h6>
                                        {
                                          profile?.operational_schedule
                                            ?.Wednesday?.open
                                        }{" "}
                                        -{" "}
                                        {
                                          profile?.operational_schedule
                                            ?.Wednesday?.close
                                        }
                                      </h6>
                                    </div>
                                    <div className="col-10 d-flex justify-content-between items-center">
                                      <small>Thursday</small>
                                      <h6>
                                        {
                                          profile?.operational_schedule
                                            ?.Thursday?.open
                                        }{" "}
                                        -{" "}
                                        {
                                          profile?.operational_schedule
                                            ?.Thursday?.close
                                        }
                                      </h6>
                                    </div>
                                    <div className="col-10 d-flex justify-content-between items-center">
                                      <small>Friday</small>
                                      <h6>
                                        {
                                          profile?.operational_schedule?.Friday
                                            ?.open
                                        }{" "}
                                        -{" "}
                                        {
                                          profile?.operational_schedule?.Friday
                                            ?.close
                                        }
                                      </h6>
                                    </div>
                                    <div className="col-10 d-flex justify-content-between items-center">
                                      <small>Saturday</small>
                                      <h6>
                                        {profile?.operational_schedule?.Saturday
                                          ?.open ? (
                                          <span>
                                            {
                                              profile?.operational_schedule
                                                ?.Saturday?.open
                                            }{" "}
                                            -{" "}
                                            {
                                              profile?.operational_schedule
                                                ?.Saturday?.close
                                            }
                                          </span>
                                        ) : (
                                          "Closed"
                                        )}
                                      </h6>
                                    </div>
                                    <div className="col-10 d-flex justify-content-between items-center">
                                      <small>Sunday</small>
                                      <h6>
                                        {profile?.operational_schedule?.Sunday
                                          ?.open ? (
                                          <span>
                                            {
                                              profile?.operational_schedule
                                                ?.Sunday?.open
                                            }{" "}
                                            -{" "}
                                            {
                                              profile?.operational_schedule
                                                ?.Sunday?.close
                                            }
                                          </span>
                                        ) : (
                                          "Closed"
                                        )}
                                      </h6>
                                    </div>
                                  </div>
                                </Row>
                              )}
                            </Col>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <Card className="profile-card">
                          <CardBody>
                            <h6 className="">Orders</h6>
                            <hr />
                            <div className="row feture-tabs" data-aos="fade-up">
                              <div className="col-lg-12">
                                <ul className="nav nav-pills my-3">
                                  <li onClick={() => setOrderType("shelved")}>
                                    <a
                                      className="nav-link active"
                                      data-bs-toggle="pill"
                                      href="#tab1"
                                    >
                                      Shelved Orders
                                    </a>
                                  </li>
                                  <li onClick={() => setOrderType("sweepable")}>
                                    <a
                                      className="nav-link"
                                      data-bs-toggle="pill"
                                      href="#tab2"
                                    >
                                      Sweepable Orders
                                    </a>
                                  </li>
                                </ul>
                                <div className="tab-content">
                                  <div
                                    className="tab-pane fade show active"
                                    id="tab1"
                                  >
                                    <div className="container-fluid">
                                      <Row>
                                        <Col lg={12}>
                                          {loadingOrders ? (
                                            <div className="container">
                                              <div className="row">
                                                <div className="col-md-12 skeleton skeleton-search"></div>
                                                <div className="col-md-12 skeleton skeleton-search"></div>
                                                <div className="col-md-12 skeleton skeleton-search"></div>
                                                <div className="col-md-12 skeleton skeleton-search"></div>
                                                <div className="col-md-12 skeleton skeleton-search"></div>
                                                <div className="col-md-12 skeleton skeleton-search"></div>
                                                <div className="col-md-12 skeleton skeleton-search"></div>
                                                <div className="col-md-12 skeleton skeleton-search"></div>
                                                <div className="col-md-12 skeleton skeleton-search"></div>
                                              </div>
                                            </div>
                                          ) : (
                                            <div className="table-responsive">
                                              <div className="table-responsive">
                                                {orders.length !== 0 &&
                                                orderType === "shelved" ? (
                                                  <>
                                                    <Table className="table-hover mt-2 mb-0">
                                                      <thead>
                                                        <tr>
                                                          <th>Sender</th>
                                                          <th>Label</th>
                                                          <th>Price</th>
                                                          <th>
                                                            Current Location
                                                          </th>
                                                          <th>
                                                            Payment Status
                                                          </th>
                                                          <th>Created</th>
                                                          <th>
                                                            Delivery Status
                                                          </th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {orders?.map(
                                                          (order, index) => {
                                                            const {
                                                              uuid,
                                                              price,
                                                              payment_status,
                                                              status,
                                                              created_at,
                                                              pickup_time,
                                                              current_location_type,
                                                              current_phase,
                                                              user,
                                                            } = order
                                                            return (
                                                              <tr
                                                                key={uuid}
                                                                onClick={() =>
                                                                  setRedirect(
                                                                    "/order/" +
                                                                      uuid
                                                                  )
                                                                }
                                                                className="table-row"
                                                              >
                                                                <td>
                                                                  {
                                                                    user?.full_name
                                                                  }{" "}
                                                                  <br />
                                                                  <small className="text-green">
                                                                    {
                                                                      user?.email
                                                                    }
                                                                  </small>
                                                                </td>
                                                                <td
                                                                  style={{
                                                                    whiteSpace:
                                                                      "nowrap",
                                                                  }}
                                                                >
                                                                  <div>
                                                                    {order
                                                                      ?.package
                                                                      ?.images
                                                                      .length !==
                                                                      0 && (
                                                                      <img
                                                                        src={`${
                                                                          process
                                                                            .env
                                                                            .REACT_APP_IMAGE_URL
                                                                        }/download/${encodeURIComponent(
                                                                          order
                                                                            ?.package
                                                                            ?.images[0]
                                                                        )}`}
                                                                        className="img-fluid rounded"
                                                                        width={
                                                                          40
                                                                        }
                                                                        alt={
                                                                          order
                                                                            ?.package
                                                                            ?.label
                                                                        }
                                                                      />
                                                                    )}
                                                                  </div>
                                                                  <small className="small">
                                                                    {
                                                                      order
                                                                        ?.package
                                                                        ?.label
                                                                    }
                                                                  </small>
                                                                </td>
                                                                <td>
                                                                  ₦{price}
                                                                </td>
                                                                <td>
                                                                  {current_location_type ===
                                                                    "Sender" ||
                                                                  current_location_type ===
                                                                    "Recipient"
                                                                    ? current_location_type
                                                                    : current_phase ===
                                                                        "center_to_center" ||
                                                                      current_phase ===
                                                                        "hub_to_sweeper" ||
                                                                      current_phase ===
                                                                        "partner_to_center" ||
                                                                      current_phase ===
                                                                        "sweeper_to_center"
                                                                    ? `Origin ${current_location_type}`
                                                                    : current_phase ===
                                                                        "center_to_partner" ||
                                                                      current_phase ===
                                                                        "center_to_sweeper" ||
                                                                      current_phase ===
                                                                        "hub_to_recipient" ||
                                                                      current_phase ===
                                                                        "parner_to_recipient" ||
                                                                      current_phase ===
                                                                        "sweeper_to_hub"
                                                                    ? `Destination ${current_location_type}`
                                                                    : ""}
                                                                </td>
                                                                <td
                                                                  style={{
                                                                    color:
                                                                      payment_status ===
                                                                      "completed"
                                                                        ? "#29CC97"
                                                                        : payment_status ===
                                                                          "cancelled"
                                                                        ? "#DC1515"
                                                                        : "#FEC400",
                                                                  }}
                                                                >
                                                                  {
                                                                    payment_status
                                                                  }
                                                                </td>
                                                                <td>
                                                                  {created_at}
                                                                </td>
                                                                <td
                                                                  style={{
                                                                    color:
                                                                      status ===
                                                                      "completed"
                                                                        ? "#29CC97"
                                                                        : status ===
                                                                          "cancelled"
                                                                        ? "#DC1515"
                                                                        : status ===
                                                                          "accepted"
                                                                        ? "#86F599"
                                                                        : status ===
                                                                          "transit"
                                                                        ? "#C9DA1D"
                                                                        : "#FEC400",
                                                                  }}
                                                                >
                                                                  {status}
                                                                </td>
                                                              </tr>
                                                            )
                                                          }
                                                        )}
                                                      </tbody>
                                                    </Table>
                                                  </>
                                                ) : (
                                                  <div className="d-flex flex-column justify-content-center align-items-center">
                                                    <img
                                                      src={emptyState}
                                                      alt="empty state"
                                                      width={206}
                                                      height={206}
                                                    />
                                                    <p
                                                      style={{
                                                        maxWidth: "185px",
                                                        textAlign: "center",
                                                        color: "#8D8D8D",
                                                        fontSize: "14px",
                                                        fontWeight: "400",
                                                        lineHeight: "21px",
                                                      }}
                                                    >
                                                      No orders to be shelved
                                                    </p>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>

                                  <div className="tab-pane fade show" id="tab2">
                                    <Row>
                                      <Col lg={12}>
                                        {loadingOrders ? (
                                          <div className="container">
                                            <div className="row">
                                              <div className="col-md-12 skeleton skeleton-search"></div>
                                              <div className="col-md-12 skeleton skeleton-search"></div>
                                              <div className="col-md-12 skeleton skeleton-search"></div>
                                              <div className="col-md-12 skeleton skeleton-search"></div>
                                              <div className="col-md-12 skeleton skeleton-search"></div>
                                              <div className="col-md-12 skeleton skeleton-search"></div>
                                              <div className="col-md-12 skeleton skeleton-search"></div>
                                              <div className="col-md-12 skeleton skeleton-search"></div>
                                              <div className="col-md-12 skeleton skeleton-search"></div>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="table-responsive">
                                            <div className="table-responsive">
                                              {orders.length !== 0 &&
                                              orderType === "sweepable" ? (
                                                <>
                                                  <Table className="table-hover mt-2 mb-0">
                                                    <thead>
                                                      <tr>
                                                        <th>Sender</th>
                                                        <th>Label</th>
                                                        <th>Price</th>
                                                        <th>
                                                          Current Location
                                                        </th>
                                                        <th>Payment Status</th>
                                                        <th>Created</th>
                                                        <th>Delivery Status</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {orders?.map(
                                                        (order, index) => {
                                                          const {
                                                            uuid,
                                                            price,
                                                            payment_status,
                                                            status,
                                                            created_at,
                                                            pickup_time,
                                                            current_location_type,
                                                            current_phase,
                                                            user,
                                                          } = order
                                                          return (
                                                            <tr
                                                              key={uuid}
                                                              onClick={() =>
                                                                setRedirect(
                                                                  "/order/" +
                                                                    uuid
                                                                )
                                                              }
                                                              className="table-row"
                                                            >
                                                              <td>
                                                                {
                                                                  user?.full_name
                                                                }{" "}
                                                                <br />
                                                                <small className="text-green">
                                                                  {user?.email}
                                                                </small>
                                                              </td>
                                                              <td
                                                                style={{
                                                                  whiteSpace:
                                                                    "nowrap",
                                                                }}
                                                              >
                                                                <div>
                                                                  {order
                                                                    ?.package
                                                                    ?.images
                                                                    .length !==
                                                                    0 && (
                                                                    <img
                                                                      src={`${
                                                                        process
                                                                          .env
                                                                          .REACT_APP_IMAGE_URL
                                                                      }/download/${encodeURIComponent(
                                                                        order
                                                                          ?.package
                                                                          ?.images[0]
                                                                      )}`}
                                                                      className="img-fluid rounded"
                                                                      width={40}
                                                                      alt={
                                                                        order
                                                                          ?.package
                                                                          ?.label
                                                                      }
                                                                    />
                                                                  )}
                                                                </div>
                                                                <small className="small">
                                                                  {
                                                                    order
                                                                      ?.package
                                                                      ?.label
                                                                  }
                                                                </small>
                                                              </td>
                                                              <td>₦{price}</td>
                                                              <td>
                                                                {current_location_type ===
                                                                  "Sender" ||
                                                                current_location_type ===
                                                                  "Recipient"
                                                                  ? current_location_type
                                                                  : current_phase ===
                                                                      "center_to_center" ||
                                                                    current_phase ===
                                                                      "hub_to_sweeper" ||
                                                                    current_phase ===
                                                                      "partner_to_center" ||
                                                                    current_phase ===
                                                                      "sweeper_to_center"
                                                                  ? `Origin ${current_location_type}`
                                                                  : current_phase ===
                                                                      "center_to_partner" ||
                                                                    current_phase ===
                                                                      "center_to_sweeper" ||
                                                                    current_phase ===
                                                                      "hub_to_recipient" ||
                                                                    current_phase ===
                                                                      "parner_to_recipient" ||
                                                                    current_phase ===
                                                                      "sweeper_to_hub"
                                                                  ? `Destination ${current_location_type}`
                                                                  : ""}
                                                              </td>
                                                              <td
                                                                style={{
                                                                  color:
                                                                    payment_status ===
                                                                    "completed"
                                                                      ? "#29CC97"
                                                                      : payment_status ===
                                                                        "cancelled"
                                                                      ? "#DC1515"
                                                                      : "#FEC400",
                                                                }}
                                                              >
                                                                {payment_status}
                                                              </td>
                                                              <td>
                                                                {created_at}
                                                              </td>
                                                              <td
                                                                style={{
                                                                  color:
                                                                    status ===
                                                                    "completed"
                                                                      ? "#29CC97"
                                                                      : status ===
                                                                        "cancelled"
                                                                      ? "#DC1515"
                                                                      : status ===
                                                                        "accepted"
                                                                      ? "#86F599"
                                                                      : status ===
                                                                        "transit"
                                                                      ? "#C9DA1D"
                                                                      : "#FEC400",
                                                                }}
                                                              >
                                                                {status}
                                                              </td>
                                                            </tr>
                                                          )
                                                        }
                                                      )}
                                                    </tbody>
                                                  </Table>
                                                </>
                                              ) : (
                                                <div className="d-flex flex-column justify-content-center align-items-center">
                                                  <img
                                                    src={emptyState}
                                                    alt="empty state"
                                                    width={206}
                                                    height={206}
                                                  />
                                                  <p
                                                    style={{
                                                      maxWidth: "185px",
                                                      textAlign: "center",
                                                      color: "#8D8D8D",
                                                      fontSize: "14px",
                                                      fontWeight: "400",
                                                      lineHeight: "21px",
                                                    }}
                                                  >
                                                    No orders to be shelved
                                                  </p>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        )}
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </>
          ) : (
            <>
              <div
                className="mb-4 d-flex justify-content-center align-items-center"
                style={{
                  gap: "4px",
                  padding: "7px 18px 7px 12px",
                  width: "76px",
                  borderRadius: "32px",
                  border: "1px solid #F0F0F0",
                  background: "#FFF",
                  boxShadow: "1px 1px 4px 0px rgba(0, 0, 0, 0.04)",
                  marginBottom: "8px",
                }}
              >
                <div
                  onClick={() => setShowEdit(false)}
                  className="text-black d-flex align-items-center"
                >
                  <FaChevronLeft size={16} />
                  <div
                    style={{
                      color: "#000",
                      fontSize: "14px",
                      fontWeight: "600",
                      marginLeft: "4px",
                    }}
                  >
                    Back
                  </div>
                </div>
              </div>
              <div className="bg-white card p-3">
                <div>
                  <h5>Edit hub information</h5>
                  <hr />
                </div>
                <div>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6 mb-4">
                        <div>
                          <label htmlFor="name">Name</label>
                          <input
                            type="text"
                            value={profile?.name}
                            onChange={e =>
                              setProfile({ ...profile, name: e.target.value })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <div>
                          <label htmlFor="name">Phone</label>
                          <input
                            type="text"
                            value={profile?.phone}
                            onChange={e =>
                              setProfile({ ...profile, phone: e.target.value })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <div>
                          <label htmlFor="name">Email</label>
                          <input
                            type="text"
                            value={profile?.email}
                            onChange={e =>
                              setProfile({ ...profile, email: e.target.value })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <Address
                          setLocation={setLocation}
                          location={profile.address}
                          coordinates={profile.coordinates}
                          setCoordinates={setCoordinates}
                          center={profile?.center?.name}
                        />
                      </div>
                      <div className="col-md-6 mb-4">
                        <label htmlFor="state">Location</label>
                        <select
                          required
                          type="text"
                          value={profile?.center?.uuid}
                          className="form-select shadow-none"
                          onChange={e => {
                            setProfile({
                              ...profile,
                              center: {
                                ...profile.center,
                                uuid: e.target.value,
                              },
                            })
                          }}
                        >
                          <option value="">Select center</option>
                          {loading
                            ? "Loading..."
                            : centers?.map(center => {
                                const { name, uuid } = center
                                return (
                                  <option key={uuid} value={uuid}>
                                    {name}
                                  </option>
                                )
                              })}
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div>
                        <button
                          className="send"
                          disabled={disableButton}
                          onClick={updateHub}
                        >
                          {disableButton ? "Updating..." : "Update"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default HubDetails
