import { useCallback, useState } from "react"
import { useDropzone } from "react-dropzone"
import uploadImg from "../../assets/images/upload.png"
import uploadSvg from "../../assets/images/upload.svg"
import "./hubs.css"
import { FaUpload } from "react-icons/fa"

const Dropzone = ({ files, setFiles, token, imageId }) => {
  const [showFile, setShowFile] = useState(false)

  const onDrop = useCallback(
    async acceptedFiles => {
      // Do something with the files
      console.log(acceptedFiles)
      if (acceptedFiles?.length) {
        setFiles(previousFiles => [
          ...previousFiles,
          ...acceptedFiles.map(file =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          ),
        ])
      }
      setShowFile(true)
      for (const file of acceptedFiles) {
        await uploadImage(file)
      }
    },
    [token]
  )
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  // Upload image to google drive
  const uploadImage = async image => {
    console.log("upload image to google drive")
    const file = image
    const fr = new FileReader()
    fr.readAsArrayBuffer(file)
    fr.onload = f => {
      const fileMetadata = {
        name: file.name,
        parents: ["13lMB0X5aIfc4vFcklI3k2gH17sNwNoZ4"],
      }
      const form = new FormData()
      form.append(
        "metadata",
        new Blob([JSON.stringify(fileMetadata)], { type: "application/json" })
      )
      form.append(
        "file",
        new Blob([new Uint8Array(f.target.result)], { type: file.type })
      )
      const options = {
        method: "POST",
        headers: new Headers({ Authorization: `Bearer ${token}` }),
        "Content-Type": "multipart/form-data",
        body: form,
      }
      fetch(
        "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart",
        options
      )
        .then(response => response.json())
        .then(response => {
          console.log(response.id)
          imageId.push(response.id)
          console.log(imageId)

          console.log(imageId)
        })
        .catch(error => console.log(error, "error message"))
    }
  }

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />

      {!showFile ? (
        <div className="file">
          <div className="file-svg">
            <img
              src={uploadSvg}
              alt="upload"
              width={40}
              height={40}
              className="img-svg"
            />
            {isDragActive ? (
              <p className="drop-text">Drop the files here ...</p>
            ) : (
              <p className="text-sm font-medium leading-5 mt-3 text-[]">
                Click to upload{" "}
                <span className="upload-text">or drag and drop</span>
              </p>
            )}
            <p className="type-text">SVG, PNG, JPG or GIF (max. 800x400px)</p>
          </div>
        </div>
      ) : (
        files?.map(file => (
          <div className="main-content" key={file.name}>
            <div className="">
              {file.type.startsWith("image/") ? (
                <img
                  src={file.preview}
                  alt={file.name}
                  width={100}
                  height={100}
                  className="rounded"
                />
              ) : (
                <img
                  src={uploadImg}
                  alt={file.name}
                  width={100}
                  height={100}
                  className="rounded"
                />
              )}

              <div>
                <p
                  className="text-sm font-medium leading-5 text-[#314155]"
                  key={file.name}
                >
                  {file.name}
                </p>
              </div>
            </div>
          </div>
        ))
      )}

      {showFile && (
        <button className="btn btn-success">
          Upload <FaUpload />
        </button>
      )}
    </div>
  )
}

export default Dropzone
