import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Row, Col, Card, CardBody } from "reactstrap"

import accessToken from "../../helpers/jwt-token-access/accessToken"

import { FaCheck } from "react-icons/fa"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import baseURL from "../../utils"

const Onboarding = props => {
  const [partners, setPartners] = useState([])
  const [loading, setLoading] = useState(true)
  const [banks, setBanks] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/partners/${props?.match?.params?.id}/onboarding-data`,
          options
        )
        const partners = await response.json()
        console.log(partners["data"])
        setPartners(partners.data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        toast.error(error.message)
      }
    }

    fetchData()
  }, [])

  // Fetch bank details
  // useEffect(() => {
  //   const fetchBanks = async () => {
  //     try {
  //       const options = {
  //         method: "GET",
  //         headers: new Headers({ Authorization: accessToken }),
  //       }
  //       const response = await fetch(
  //         `${baseURL}/api/v1/misc/banks?country=nigeria`,
  //         options
  //       )
  //       const banks = await response.json()
  //       console.log(banks["data"], 'banks')
  //       setBanks(banks.data)
  //       setLoading(false)
  //     } catch (error) {
  //       setLoading(false)
  //       toast.error(error.message)
  //     }
  //   }

  //   fetchBanks()
  // }, [])

  // update vehicle documents
  const updateVehicle = async () => {
    // do stuff
    try {
      const options = {
        method: "PUT",
        headers: new Headers({
          Authorization: accessToken,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          drivers_license: profile.first_name,
          vehicle_registration: profile.last_name,
          vehicle_insurance: profile.email,
        }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/partners/${props?.match?.params?.id}/onboarding-data`,
        options
      )
      const data = await response.json()
      console.log(data)
      console.log(data.message)
      if (data.status == "success") {
        toast.success(data["message"])
        setbuttonLoadBan(false)
        setDisableButton(false)
        setTimeout(() => {
          location.reload(1)
        }, 5000)
      } else if (data.status == "error") {
        toast.error(data["message"])
        setbuttonLoadBan(false)
        setDisableButton(false)
      }
    } catch (error) {
      // do what
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>partner Onboarding | Dilivva Admin</title>
        </MetaTags>
        <div className="container-fluid">
          <ToastContainer />
          <Row>
            {loading ? (
              <div className="spinner-border text-green mx-3" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <Col lg={12}>
                <h4 className="mb-4">Onboarding data</h4>
                {partners?.identification && (
                  <Card>
                    <CardBody>
                      <h4 className="text-green mb-3">Identification</h4>

                      <img
                        src={partners?.identification?.document}
                        className="img-fluid rounded mb-3"
                        width="300"
                        alt="identification"
                      />
                      <h6>
                        <strong>Type:</strong> {partners?.identification?.type}
                      </h6>
                      <h6>
                        <strong>Number:</strong>{" "}
                        {partners?.identification?.number}
                      </h6>
                      <div className="float-end">
                        <Col
                          md={2}
                          className="d-flex justify-content-center align-items-center my-4"
                        >
                          {/* <button
                                className="send"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                              >
                                Update
                              </button> */}
                          <div
                            className="modal fade"
                            id="exampleModal"
                            tabIndex="-1"
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5
                                    className="modal-title"
                                    id="exampleModalLabel"
                                  >
                                    Update Identification
                                  </h5>
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <div className="modal-body">
                                  <div className="container">
                                    <div className="row">
                                      <div className="col-md-6 mb-4">
                                        <span>Driver's License</span>
                                        <input
                                          type="text"
                                          value="fj"
                                          onChange={e =>
                                            setProfile({
                                              ...profile,
                                              first_name: e.target.value,
                                            })
                                          }
                                        />
                                      </div>
                                      <div className="col-md-6 mb-4">
                                        <span>Vehicle Insurance</span>
                                        <input
                                          type="text"
                                          value={
                                            partners?.identification?.number
                                          }
                                          onChange={e =>
                                            setProfile({
                                              ...profile,
                                              last_name: e.target.value,
                                            })
                                          }
                                        />
                                      </div>
                                      <div className="col-md-6 mb-4">
                                        <span>Vehicle Registeration</span>
                                        <input
                                          type="text"
                                          value="fj"
                                          onChange={e =>
                                            setProfile({
                                              ...profile,
                                              first_name: e.target.value,
                                            })
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                  {/* <button
                                        type="submit"
                                        className="send"
                                        onClick={() => updateUser()}
                                      >
                                        Update
                                      </button> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </div>
                    </CardBody>
                  </Card>
                )}

                {partners?.vehicleDocument && (
                  <Card>
                    <CardBody>
                      <h4 className="text-green mb-5">Vehicle Document</h4>
                      <Row>
                        <Col lg={6}>
                          <img
                            src={partners?.vehicleDocument?.drivers_license}
                            className="img-fluid rounded mb-3"
                            width="300"
                            alt="identification"
                          />
                          <h6>Driver's License</h6>
                        </Col>
                        <Col lg={6}>
                          <img
                            src={partners?.vehicleDocument?.vehicle_insurance}
                            className="img-fluid rounded mb-3"
                            width="300"
                            alt="identification"
                          />
                          <h6 className="mb-5">Vehicle Insurance</h6>
                        </Col>
                        <Col lg={6}>
                          <img
                            src={
                              partners?.vehicleDocument?.vehicle_registration
                            }
                            className="img-fluid rounded mb-3"
                            width="300"
                            alt="identification"
                          />
                          <h6 className="mb-5">Vehicle registeration</h6>
                        </Col>
                      </Row>
                      <div className="float-end">
                        <Col
                          md={2}
                          className="d-flex justify-content-center align-items-center my-4"
                        >
                          {/* <button
                                className="send"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal2"
                              >
                                Update
                              </button> */}
                          <div
                            className="modal fade"
                            id="exampleModal2"
                            tabIndex="-1"
                            aria-labelledby="exampleModalLabel2"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5
                                    className="modal-title"
                                    id="exampleModalLabel2"
                                  >
                                    Update User Details
                                  </h5>
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <div className="modal-body">
                                  <div className="container">
                                    <div className="row">
                                      <div className="col-md-6 mb-4">
                                        <span>First Name</span>
                                        <input
                                          type="text"
                                          value="reri"
                                          onChange={e =>
                                            setProfile({
                                              ...profile,
                                              first_name: e.target.value,
                                            })
                                          }
                                        />
                                      </div>
                                      <div className="col-md-6 mb-4">
                                        <span>Last Name</span>
                                        <input
                                          type="text"
                                          value="drjr"
                                          onChange={e =>
                                            setProfile({
                                              ...profile,
                                              last_name: e.target.value,
                                            })
                                          }
                                        />
                                      </div>
                                      <div className="col-md-6 mb-4">
                                        <span>Email</span>
                                        <input
                                          type="text"
                                          value="drjkr"
                                          onChange={e =>
                                            setProfile({
                                              ...profile,
                                              email: e.target.value,
                                            })
                                          }
                                        />
                                      </div>
                                      <div className="col-md-6 mb-4">
                                        <span>Phone</span>
                                        <input
                                          type="text"
                                          value="djr"
                                          onChange={e =>
                                            setProfile({
                                              ...profile,
                                              phone: e.target.value,
                                            })
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                  <button
                                    type="submit"
                                    className="send"
                                    onClick={() => updateUser()}
                                  >
                                    Update
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </div>
                    </CardBody>
                  </Card>
                )}

                {partners?.bankAccount && (
                  <Card>
                    <CardBody>
                      <Row>
                        <Col lg={6}>
                          <h4 className="text-green mb-3">Bank Account</h4>
                          <h6>
                            <strong>Account Name:</strong>{" "}
                            {partners?.bankAccount?.account_name}
                          </h6>
                          <hr />
                          <h6>
                            <strong>Account Number:</strong>{" "}
                            {partners?.bankAccount?.account_number}
                          </h6>
                          <hr />
                          <h6>
                            <strong>Bank Name:</strong>{" "}
                            {partners?.bankAccount?.bank_name}
                          </h6>
                          <hr />
                          <h6>
                            <strong>BVN:</strong> {partners?.bankAccount?.bvn}
                          </h6>
                          <hr />
                        </Col>
                      </Row>
                      <div className="float-end">
                        <Col
                          md={2}
                          className="d-flex justify-content-center align-items-center my-4"
                        >
                          {/* <button
                                className="send"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal1"
                              >
                                Update
                              </button> */}
                          <div
                            className="modal fade"
                            id="exampleModal1"
                            tabIndex="-1"
                            aria-labelledby="exampleModalLabel1"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5
                                    className="modal-title"
                                    id="exampleModalLabel1"
                                  >
                                    Update Bank Account
                                  </h5>
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <div className="modal-body">
                                  <div className="container">
                                    <div className="row">
                                      <div className="col-md-6 mb-4">
                                        <span>Account Name</span>
                                        <input
                                          type="text"
                                          value={
                                            partners?.bankAccount?.account_name
                                          }
                                          onChange={e =>
                                            setProfile({
                                              ...profile,
                                              first_name: e.target.value,
                                            })
                                          }
                                        />
                                      </div>
                                      <div className="col-md-6 mb-4">
                                        <span>Account Number</span>
                                        <input
                                          type="text"
                                          value={
                                            partners?.bankAccount
                                              ?.account_number
                                          }
                                          onChange={e =>
                                            setProfile({
                                              ...profile,
                                              last_name: e.target.value,
                                            })
                                          }
                                        />
                                      </div>
                                      <div className="col-md-6 mb-4">
                                        <span>Bank Name</span>
                                        <input
                                          type="text"
                                          value={
                                            partners?.bankAccount?.bank_name
                                          }
                                          onChange={e =>
                                            setProfile({
                                              ...profile,
                                              email: e.target.value,
                                            })
                                          }
                                        />
                                        {/* <select name="" className="form-control">
                                              {banks?.map(bank => {
                                                const {name, id, code} = bank
                                                return (
                                                  <>
                                              <option value={code} key={id}>{name}</option>

                                                  </>
                                                )
                                              })}
                                            </select> */}
                                      </div>
                                      <div className="col-md-6 mb-4">
                                        <span>BVN</span>
                                        <input
                                          type="text"
                                          value={partners?.bankAccount?.bvn}
                                          onChange={e =>
                                            setProfile({
                                              ...profile,
                                              phone: e.target.value,
                                            })
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                  <button
                                    type="submit"
                                    className="send"
                                    onClick={() => updateUser()}
                                  >
                                    Update
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </div>
                    </CardBody>
                  </Card>
                )}
                <Card>
                  <CardBody>
                    <h4 className="text-green mb-3">Onboarding Test</h4>
                    {partners?.questions?.length !== 0
                      ? partners?.questions?.map((question, index) => (
                          <div key={question?.id}>
                            <h6>
                              {index + 1}. {question?.content}
                            </h6>
                            {question?.answers?.map(answer => (
                              <div key={answer?.id}>
                                <p>
                                  {answer?.content}{" "}
                                  {question?.submission?.answer_id ===
                                    answer?.uuid && (
                                    <FaCheck className="text-green mx-1" />
                                  )}
                                </p>
                              </div>
                            ))}
                          </div>
                        ))
                      : "No Onboarding Test"}
                  </CardBody>
                </Card>
                {/* <Card>
                <CardBody>
                  <button className="send">Update onboarding</button>
                  
                </CardBody>
              </Card> */}
              </Col>
            )}
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Onboarding
