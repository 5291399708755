import { ToastContainer, toast } from "react-toastify"
import accessToken from "../../helpers/jwt-token-access/accessToken"
import React, { useEffect, useState } from "react"
import baseURL from "../../utils"

const VirtualAccount = ({ userId }) => {
  const [account, setAccount] = useState([])
  const [loading, setLoading] = useState(false)
  const [disableButton, setDisableButton] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/users/${userId}/virtual-account`,
          options
        )
        const account = await response.json()
        console.log(account)
        setAccount(account.data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    }

    fetchData()
  }, [])

  // CreateVirtualAccount
  const createVirtualAccount = async e => {
    e.preventDefault()
    // do stuff
    setDisableButton(true)
    try {
      const options = {
        method: "POST",
        headers: new Headers({
          Authorization: accessToken,
          "Content-Type": "application/json",
        }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/users/${userId}/virtual-account`,
        options
      )
      const data = await response.json()
      console.log(data)
      console.log(data.message)
      if (data.status == "success") {
        toast.success(data["message"])
        setTimeout(() => {
          location.reload()
        }, 5000)
      } else if (data.status == "error") {
        toast.error(data["message"])
      }
    } catch (error) {
      // do what
    }
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <ToastContainer />
          <h4 className="my-5">Sender Virtual Account</h4>
          {account?.account_name ? (
            <div>
              <div className="row">
                <div className="col-md-6 mb-4">
                  <label>Account Name</label>
                  <input type="text" value={account?.account_name} readOnly />
                </div>
                <div className="col-md-6 mb-4">
                  <label>Account Nunmber</label>
                  <input type="text" value={account?.account_number} readOnly />
                </div>
                <div className="col-md-6 mb-4">
                  <label>Bank Name</label>
                  <input type="text" value={account?.bank_name} readOnly />
                </div>
                <div className="col-md-6 mb-4">
                  <label>Customer ID</label>
                  <input type="text" value={account?.customer_id} readOnly />
                </div>
              </div>
            </div>
          ) : (
            <div className="my-5">
              <p>{account?.message}</p>
              <button className="send" onClick={createVirtualAccount} disabled={disableButton}>Create Virtual Account</button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default VirtualAccount
