import accessToken from "../../helpers/jwt-token-access/accessToken"
import { useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import baseURL from "../../utils"
import sender from '../../assets/images/craete-sender.svg'

const CreateUser = () => {
  const [details, setDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  })
  const [disableButton, setDisableButton] = useState(false)
  const [errors, setErrors] = useState({})

  // Create a new user
  const handleSubmit = async e => {
    e.preventDefault()
    // do stuff
    setDisableButton(true)
    setErrors({})
    try {
      const options = {
        method: "POST",
        headers: new Headers({
          Authorization: accessToken,
          "Content-Type": "application/json",
          accept: "application/json",
        }),
        body: JSON.stringify({
          first_name: details.firstName,
          last_name: details.lastName,
          email: details.email,
          phone: details.phone,
        }),
      }
      const response = await fetch(`${baseURL}/api/v1/admin/users`, options)
      const data = await response.json()
      console.log(data)

      if (data.errors) {
        toast.error(data["message"])
        setDisableButton(false)
        setErrors(data.errors)
      } else {
        toast.success(data["message"])
        setTimeout(() => {
          window.location.href = "/senders"
        }, 5000)
      }
    } catch (error) {
      // do what
      console.log(error)
      setDisableButton(false)
    }
  }

  return (
    <section className="mt-5">
      <ToastContainer />
      <div className="">
        <div className="container mt-5">
          <div className="row my-5 d-flex justify-content-center">
            <div className="col-md-11 mt-5">
              <div className="card">
                <div className="card-body">
                  <h2 className="text-green text-center mt-4">
                    Create A New User Account
                  </h2>
                  <div className="row m-lg-5">
                    <div className="col-md-6">
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-12 mb-4">
                            <label
                              htmlFor="firstName"
                              className="fw-bold text-green"
                            >
                              First Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Ayodele"
                              value={details.firstName}
                              onChange={e =>
                                setDetails({
                                  ...details,
                                  firstName: e.target.value,
                                })
                              }
                              required
                            />
                          </div>
                          <div className="col-md-12 mb-4">
                            <label
                              htmlFor="lastName"
                              className="fw-bold text-green"
                            >
                              Last Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Olusola"
                              value={details.lastName}
                              onChange={e =>
                                setDetails({
                                  ...details,
                                  lastName: e.target.value,
                                })
                              }
                              required
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 mb-4">
                            <label
                              htmlFor="email"
                              className="fw-bold text-green"
                            >
                              Email Address
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="email@sender.com"
                              value={details.email}
                              onChange={e =>
                                setDetails({
                                  ...details,
                                  email: e.target.value,
                                })
                              }
                              required
                            />
                            <small className="text-danger">
                              {errors?.email}
                            </small>
                          </div>
                          <div className="col-md-12 mb-4">
                            <label
                              htmlFor="phone"
                              className="fw-bold text-green"
                            >
                              Phone Number
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="+23427125201"
                              value={details?.phone}
                              onChange={e =>
                                setDetails({
                                  ...details,
                                  phone: e.target.value,
                                })
                              }
                              required
                            />
                            <small className="text-danger">
                              {errors.phone}
                            </small>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end">
                          <button
                            type="submit"
                            className="send w-100 mt-4"
                            disabled={disableButton}
                          >
                            {disableButton ? "Creating User..." : "Create User"}
                          </button>
                        </div>
                      </form>
                    </div>
                    <div className="col-md-6 my-5 d-none d-sm-block">
                      <img src={sender} alt="create sender" className="img-fluid mt-5" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CreateUser
