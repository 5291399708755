import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Table, Row, Col, Card, CardBody } from "reactstrap"

import accessToken from "../../helpers/jwt-token-access/accessToken"

import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import ReactPaginate from "react-paginate"
import { Link, Redirect } from "react-router-dom"
import { FaArrowDown, FaArrowLeft, FaArrowUp, FaFilter } from "react-icons/fa"
import baseURL from "../../utils"

const CourierDetails = props => {
  const [profile, setProfile] = useState({
    name: "",
    last_name: "",
    email: "",
    phone: "",
    address: "",
    delivery_method_id: "",
    state_id: "",
    local_government_id: "",
    photo: "",
    date_of_birth: "",
    signature: "",
  })
  const [loading, setLoading] = useState(true)
  const [transactions, setTransactions] = useState([])
  const [orders, setOrders] = useState([])
  const [cancellations, setCancellations] = useState([])
  const [next, setNext] = useState(1)
  const [page, setPage] = useState({})
  const [subject, setSubject] = useState("")
  const [body, setBody] = useState("")
  const [attachment, setAttachment] = useState([])
  const [disableButton, setDisableButton] = useState(false)
  const [buttonLoadEmail, setbuttonLoadEmail] = useState(false)
  const [comment, setComment] = useState("")
  const [expire, setExpire] = useState("")
  const [buttonLoadBan, setbuttonLoadBan] = useState(false)
  const [buttonLoad, setButtonLoad] = useState(false)
  const [buttonLoadUnban, setButtonLoadUnban] = useState(false)
  const [singleOrder, setSingleOrder] = useState({})
  const [redirect, setRedirect] = useState("")
  const [reason, setReason] = useState("reason")
  const [perPage, setPerPage] = useState(50)
  const [sort, setSort] = useState("asc")

  const handlePageClick = data => {
    setNext(data.selected + 1)
  }

  const showModal = singleOrder => {
    setSingleOrder(singleOrder)
  }

  // Courier Details
  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/couriers/${props?.match?.params?.id}`,
          options
        )
        const profile = await response.json()
        console.log(profile.data)
        setLoading(false)
        setProfile(profile.data)
      } catch (error) {
        console.log("error", error)
        toast.error(error.message)
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  // Ban Courier
  const banCourier = async e => {
    e.preventDefault()
    // do stuff
    setbuttonLoadBan(true)
    setDisableButton(true)
    try {
      const options = {
        method: "POST",
        headers: new Headers({
          Authorization: accessToken,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          comment,
          expired_at: expire,
        }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/Couriers/${props?.match?.params?.id}/ban`,
        options
      )
      const data = await response.json()
      if (data.status == "success") {
        toast.success(data["message"])
        setbuttonLoadBan(false)
        setTimeout(() => {
          location.reload()
        }, 5000)
      } else if (data.status == "error") {
        toast.error(data["message"])
        setbuttonLoadBan(false)
        setDisableButton(false)
      }
    } catch (error) {
      // do what
    }
  }

  // Unban Courier
  const unbanCourier = async () => {
    // do stuff
    setButtonLoadUnban(true)
    setDisableButton(true)
    try {
      const options = {
        method: "POST",
        headers: new Headers({
          Authorization: accessToken,
          "Content-Type": "application/json",
        }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/Couriers/${props?.match?.params?.id}/unban`,
        options
      )
      const data = await response.json()
      console.log(data)
      console.log(data.message)
      if (data.status == "success") {
        toast.success(data["message"])
        setButtonLoadUnban(false)
        setTimeout(() => {
          location.reload()
        }, 5000)
      } else if (data.status == "error") {
        toast.error(data["message"])
        setButtonLoadUnban(false)
        setDisableButton(false)
      }
    } catch (error) {
      // do what
    }
  }

  // Send Email
  const sendEmail = async e => {
    e.preventDefault()
    // do stuff
    setbuttonLoadEmail(true)
    setDisableButton(true)
    const formData = new FormData()
    formData.append("body", body)
    formData.append("subject", subject)
    formData.append("attachments", attachment)
    console.log(formData, "data inputed")

    const options = {
      method: "POST",
      headers: new Headers({
        Authorization: accessToken,
        // "Content-Type": "multipart/form-data",
      }),
    }
    const response = await fetch(
      `${baseURL}/api/v1/admin/Couriers/${props?.match?.params?.id}/send-email`,
      formData,
      options
    )
    const data = await response.json()
    console.log(data)
    if (data.status == "success") {
      toast.success(data["message"])
      setbuttonLoadEmail(false)
      setTimeout(() => {
        location.reload()
      }, 5000)
    } else if (data.status == "error") {
      toast.error(data["message"])
      setbuttonLoadEmail(false)
      setDisableButton(false)
    }
  }

  // Verify Courier
  const verifyCourier = async verification_status => {
    // do stuff
    setButtonLoad(true)
    setDisableButton(true)
    try {
      const options = {
        method: "PUT",
        headers: new Headers({
          Authorization: accessToken,
          accept: "application/json",
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          verification_status,
          reason,
        }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/couriers/${props?.match?.params?.id}/update-verification`,
        options
      )
      const data = await response.json()
      console.log(data)
      console.log(data.message)
      setButtonLoad(false)
      setDisableButton(false)
      if (data.status == "success") {
        toast.success(data["message"])
        setButtonLoad(false)
        setDisableButton(true)
        setTimeout(() => {
          location.reload(1)
        }, 5000)
      } else if (data.status == "error") {
        toast.error(data["message"])
        setButtonLoad(false)
        setDisableButton(false)
      } else {
        toast.warning(data.message)
        setButtonLoad(false)
        setDisableButton(false)
      }
    } catch (error) {
      // do what
    }
  }

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Courier Details | Dilivva Admin</title>
        </MetaTags>
        <div className="container-fluid">
          <ToastContainer draggable={false} />
          <div>
            <Link to="/couriers" className="mb-4 btn">
              <FaArrowLeft /> <span className="mx-2">back to couriers</span>
            </Link>
          </div>
          <h2 className="profile-head mb-4">Courier Details</h2>
          {loading ? (
            <Row className="d-flex justify-content-center">
              <Col lg={11}>
                <Row>
                  <Col>
                    <Card className="profile-card">
                      <CardBody>
                        <Col md={12}>
                          <Row className="">
                            <Col md={3}>
                              <div className="skel">
                                <div className="col-md-8 skeleton skeleton-circle"></div>
                              </div>
                            </Col>
                            <Col md={7} className="mx-3">
                              <div className="col-md-8 skeleton skeleton-head"></div>
                              <div className="col-md-10 skeleton skeleton-p"></div>
                              <div className="col-md-7 skeleton skeleton-p"></div>
                              <div className="col-md-6 skeleton skeleton-p"></div>
                            </Col>
                          </Row>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="profile-wallet">
                      <CardBody>
                        <Col md={12}>
                          <Row className="p-2">
                            <Col>
                              <div className="col-md-2 skeleton skeleton-p mb-4"></div>
                              <div className="col-md-4 skeleton skeleton-head p-3"></div>
                            </Col>
                          </Row>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card className="profile-card">
                      <CardBody>
                        <Row>
                          <Col md={9}>
                            <div className="col-md-12 skeleton skeleton-p"></div>
                            <div className="col-md-6 skeleton skeleton-p"></div>
                            <div className="col-md-6 skeleton skeleton-p"></div>
                            <div className="col-md-5 skeleton skeleton-p"></div>
                            <div className="price-head">
                              {profile?.updated_at}
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div className="skel">
                              <div className="col-md-3 skeleton skeleton-circle"></div>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card className="profile-wallet">
                      <CardBody>
                        <Col>
                          <Row className="p-2">
                            <Col lg={8}>
                              <div className="col-md-7 skeleton skeleton-p"></div>
                              <div className="skel">
                                <div className="col-md-8 skeleton skeleton-circle"></div>
                              </div>
                            </Col>
                            <Col lg={3}>
                              <div className="col-md-12 skeleton skeleton-head py-3 px-5"></div>
                            </Col>
                          </Row>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <Row className="d-flex justify-content-center">
              <Col lg={11}>
                <Row>
                  <Col lg={8}>
                    <Card className="profile-card">
                      <CardBody>
                        <Col md={12}>
                          <Row className="">
                            <Col md={3}>
                              {profile.photo ? (
                                <div className="profile">
                                  <img
                                    className="img-fluid"
                                    src={profile?.photo}
                                    alt="profile"
                                  />
                                </div>
                              ) : (
                                <img
                                  src="https://cdn.iconscout.com/icon/free/png-256/avatar-370-456322.png"
                                  className="img-fluid mb-2"
                                  alt={profile?.name}
                                />
                              )}
                            </Col>
                            <Col md={7} className="mx-3">
                              <h3 className="profile-name">{profile?.name}</h3>
                              <h6 className="details">{profile?.email}</h6>
                              <h6 className="details">{profile?.phone}</h6>
                              <h6 className="details">{profile?.address}</h6>
                              <h6
                                className="price-head"
                                style={{
                                  color:
                                    profile?.verification_status === "approved"
                                      ? "#059669"
                                      : profile?.verification_status ===
                                        "pending"
                                      ? "#FEC400"
                                      : profile?.verification_status ===
                                        "rejected"
                                      ? "#DC1515"
                                      : "#031736",
                                }}
                              >
                                {profile?.verification_status
                                  ? profile?.verification_status
                                  : "null"}
                              </h6>
                              <div className="price-head">
                                {profile?.updated_at}
                              </div>
                            </Col>
                            <Col>
                              <div className="d-flex justify-content-end mt-4">
                                <div className="dropdown">
                                  <a
                                    className="dropdown-toggle change"
                                    href="#"
                                    role="button"
                                    id="dropdownMenuLink"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    disabled={disableButton}
                                  >
                                    Verification Status
                                    {buttonLoad && (
                                      <div
                                        className="spinner-border text-light mx-3"
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                        }}
                                        role="status"
                                      >
                                        <span className="visually-hidden">
                                          Loading...
                                        </span>
                                      </div>
                                    )}
                                  </a>

                                  <ul
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuLink"
                                  >
                                    <li>
                                      <a
                                        className="dropdown-item text-green"
                                        onClick={() => {
                                          verifyCourier("approved")
                                        }}
                                      >
                                        {profile?.verification_status !==
                                        "approved"
                                          ? "Approve"
                                          : ""}
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item text-red"
                                        data-bs-toggle="modal"
                                        data-bs-target="#exampleModal3"
                                      >
                                        {profile?.verification_status !==
                                        "rejected"
                                          ? "Reject"
                                          : ""}
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div
                                className="modal fade"
                                id="exampleModal3"
                                tabIndex="-1"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true"
                              >
                                <div className="modal-dialog">
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <h1
                                        className="modal-title fs-5"
                                        id="exampleModalLabel"
                                      >
                                        Modal title
                                      </h1>
                                      <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                    <div className="modal-body">
                                      <textarea
                                        type="text"
                                        className="form-control"
                                        placeholder="Add reason for rejection"
                                        value={reason}
                                        onChange={e =>
                                          setReason(e.target.value)
                                        }
                                      />
                                    </div>
                                    <div className="modal-footer">
                                      <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                      >
                                        Close
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-danger"
                                        onClick={() => {
                                          verifyCourier("rejected")
                                        }}
                                      >
                                        Reject
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                  {profile?.wallet && (
                    <Col lg={4}>
                      <Card className="profile-wallet">
                        <CardBody>
                          <Col md={12}>
                            <Row className="p-2">
                              <Col>
                                <p className="wallet">Wallet</p>
                                <h2 className="balance">
                                  ₦ {profile?.wallet?.balance}
                                </h2>
                              </Col>
                            </Row>
                          </Col>
                        </CardBody>
                      </Card>
                    </Col>
                  )}
                </Row>
                {profile?.contact_person?.name && (
                  <Row>
                    <h2 className="profile-head mb-4">Contact Person</h2>
                    <Col>
                      <Card className="profile-card">
                        <CardBody>
                          <Row>
                            <Col md={6}>
                              <h3 className="profile-name">
                                {profile?.contact_person?.name}
                              </h3>
                              <p className="details">
                                {profile?.contact_person?.email}
                              </p>
                              <p className="details">
                                {profile?.contact_person?.phone}
                              </p>
                              <p className="details">
                                {profile?.contact_person?.address}
                              </p>
                              <div className="price-head">
                                {profile?.contact_person?.position}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <img
                                src={profile?.contact_person?.identification}
                                className="img-fluid my-4 rounded"
                                alt={profile?.name}
                                style={{
                                  maxHeight: "350px",
                                  width: "100%",
                                  objectFit: "contain",
                                }}
                              />
                              <div className="price-head text-green mb-4">
                                {profile?.contact_person?.identification_type}
                              </div>
                            </Col>
                            <Col md={4}>
                              <img
                                src={profile?.contact_person?.utility_bill}
                                className="img-fluid my-4 rounded"
                                alt={profile?.name}
                                style={{
                                  maxHeight: "350px",
                                  width: "100%",
                                  objectFit: "contain",
                                }}
                              />
                              <div className="price-head text-green">
                                Utility Bill
                              </div>
                            </Col>
                            <Col md={4}>
                              <img
                                src={profile?.identification_document}
                                className="img-fluid my-4 rounded"
                                alt={profile?.name}
                                style={{
                                  maxHeight: "350px",
                                  width: "100%",
                                  objectFit: "contain",
                                }}
                              />
                              <div className="price-head text-green">
                                Identification Document
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          )}
          {/* <Row>
            <Col>
              <Card>
                <CardBody>
                  <h4 className="profile-head mb-4">Send Email</h4>
                  <Row>
                    <Col md={6}>
                      <form onSubmit={sendEmail}>
                        <div className="mb-3">
                          <input
                            type="text"
                            placeholder="Subject"
                            required
                            value={subject}
                            onChange={e => setSubject(e.target.value)}
                          />
                        </div>
                        <div className="mb-3">
                          <textarea
                            className="form-control"
                            rows={5}
                            type="textarea"
                            placeholder="Body"
                            required
                            value={body}
                            onChange={e => setBody(e.target.value)}
                          />
                        </div>
                        <div className="mb-3">
                          <input
                            type="file"
                            name="file"
                            onChange={e => setAttachment(e.target.files[0])}
                            multiple
                          />
                        </div>
                        <button
                          type="submit"
                          disabled={disableButton}
                          className="send"
                        >
                          Send{" "}
                          {buttonLoadEmail && (
                            <div
                              className="spinner-border text-light mx-3"
                              style={{
                                width: "20px",
                                height: "20px",
                              }}
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          )}
                        </button>
                      </form>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <h4 className="profile-head mb-4">Ban User</h4>
                      {profile?.is_banned === true ? (
                        <div>
                          <h6 style={{ color: "#D97706" }}>Reasons For Ban</h6>
                          {profile?.ban_history.map(history => {
                            const {
                              banned_at,
                              banned_by,
                              comment,
                              expired_at,
                            } = history
                            return (
                              <div key={banned_at}>
                                <p>
                                  <strong>Banned At:</strong> {banned_at}
                                </p>
                                <p>
                                  <strong>Banned By:</strong>{" "}
                                  {banned_by ? banned_by : "Not specified"}
                                </p>
                                <p>
                                  Comment:{" "}
                                  <em>{comment ? comment : "No comment"}</em>
                                </p>
                                <p>{expired_at}</p>
                              </div>
                            )
                          })}
                          <button
                            disabled={disableButton}
                            className="change"
                            onClick={() => unbanCourier()}
                          >
                            Unban User{" "}
                            {buttonLoadUnban && (
                              <div
                                className="spinner-border text-light mx-3"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                }}
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            )}
                          </button>
                        </div>
                      ) : (
                        <form onSubmit={banCourier}>
                          <div className="mb-3">
                            <textarea
                              className="form-control"
                              rows={5}
                              type="textarea"
                              placeholder="Reason for ban"
                              required
                              value={comment}
                              onChange={e => setComment(e.target.value)}
                            />
                          </div>
                          <div className="mb-3">
                            <input
                              type="date"
                              placeholder="Expiry Date"
                              value={expire}
                              onChange={e => setExpire(e.target.value)}
                            />
                          </div>
                          <button
                            type="submit"
                            disabled={disableButton}
                            className="change"
                          >
                            Ban User{" "}
                            {buttonLoadBan && (
                              <div
                                className="spinner-border text-light mx-3"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                }}
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            )}
                          </button>
                        </form>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row> */}
        </div>
      </div>
    </React.Fragment>
  )
}

export default CourierDetails
