import React, { useMemo } from "react"
import { useState } from "react"
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  Autocomplete,
  DirectionsRenderer,
} from "@react-google-maps/api"
import { FaLocationArrow, FaTimes } from "react-icons/fa"
import { useRef } from "react"
import packages from "../../assets/images/package.png"
import { Link } from "react-router-dom"

const center = {
  lat: 7.7307739,
  lng: 5.1301063,
}

const OrderMap = ({ orderLocation }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API,
    libraries: ["places"],
  })

  const [map, setMap] = useState(/** @type google.maps.Map */ null)
  const [directions, setDirections] = useState(null)
  const [distance, setDistance] = useState("")
  const [duration, setDuration] = useState("")
  const [coordinates, setCoordinates] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [singleOrder, setSingleOrder] = useState({})

  const showOrder = singleOrder => {
    setSingleOrder(singleOrder)
  }

  const originRef = useRef()

  const destinationRef = useRef()
  const options = useMemo(
    () => ({
      // mapId: "b181cac70f27f5e6",
      disableDefaultUI: true,
      clickableIcons: false,
    }),
    []
  )

  if (!isLoaded) {
    return "loading...."
  }

  async function calculateRoute() {
    if (originRef.current.value === "" || destinationRef.current.value === "") {
      return
    }
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService()
    const results = await directionsService.route({
      origin: originRef.current.value,
      destination: destinationRef.current.value,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    })
    setDirections(results)
    const lat = results.routes[0].legs[0].start_location.lat()
    const lng = results.routes[0].legs[0].start_location.lng()
    setCoordinates(`${lat}, ${lng}`)
    setDistance(results.routes[0].legs[0].distance.text)
    setDuration(results.routes[0].legs[0].duration.text)
  }

  return (
    <div className="container-fluid">
      <h5>Packages In Transit</h5>
      <div className="row mb-5 mt-2">
        <div className="map" style={{ height: "100vh", width: "100vw" }}>
          <GoogleMap
            center={center}
            zoom={8}
            mapContainerStyle={{ width: "100%", height: "100%" }}
            onLoad={map => setMap(map)}
            options={options}
          >
            {orderLocation?.map(order => {
              const { pickup_coordinates, uuid, pickup_address } = order
              return (
                <>
                  <Marker
                    key={uuid}
                    position={{
                      lat: +pickup_coordinates.split(",")[0],
                      lng: +pickup_coordinates.split(",")[1],
                    }}
                    icon={packages}
                    onClick={() => {
                      setShowModal(true)
                      showOrder(order)
                    }}
                  />
                  <div className="row">
                    {showModal && (
                      <div
                        className="col-md-12 py-2 rounded"
                        style={{
                          display: "flex",
                          transform: "translateY(350px)",
                          transition: "all 700ms ease-in-out",
                          transform: "translate(250px, 250px)",
                          zIndex: "10000",
                        }}
                      >
                        {uuid === singleOrder?.uuid && (
                          <div className="bg-white p-3 rounded">
                            <span
                              className="d-flex justify-content-end"
                              onClick={() => {
                                setShowModal(false)
                              }}
                            >
                              <button
                                className="btn btn-close"
                                style={{
                                  fontSize: "9px",
                                }}
                              ></button>
                            </span>
                            <hr />
                            <span>
                              {singleOrder?.package?.label} (
                              {singleOrder?.package?.size})
                            </span>
                            <br />
                            <small className="fw-bold">pickup</small> <br />
                            <span>{singleOrder?.pickup_address}</span> <br />
                            <small className="fw-bold">dropoff</small> <br />
                            <span>
                              {singleOrder?.destination_local_government},{" "}
                              {singleOrder?.destination_state}
                            </span>{" "}
                            <br />
                            <div className="d-flex justify-content-end">
                              <Link
                                to={`order/${uuid}`}
                                className="btn btn-success"
                              >
                                More details
                              </Link>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </>
              )
            })}
            {directions && <DirectionsRenderer directions={directions} />}
          </GoogleMap>
        </div>
      </div>
    </div>
  )
}

export default OrderMap
