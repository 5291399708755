import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Table, Row, Col, Card, CardBody } from "reactstrap"

import "../senders/datatables.scss"
import accessToken from "../../helpers/jwt-token-access/accessToken"
import { Redirect } from "react-router-dom"

import ReactPaginate from "react-paginate"
import { FaArrowDown, FaArrowUp, FaFilter } from "react-icons/fa"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import { DateRangePicker } from "react-date-range"
import { addDays } from "date-fns"
import baseURL from "../../utils"

const AllTransactions = () => {
  const [transactions, setTransactions] = useState([])
  const [loading, setLoading] = useState(true)
  const [redirect, setRedirect] = useState("")
  const [perPage, setPerPage] = useState(50)
  const [next, setNext] = useState(1)
  const [lastPage, setLastPage] = useState({})
  const [sort, setSort] = useState("desc")
  const [search, setSearch] = useState("")
  const [searchResult, setSearchResult] = useState([])
  const [showDate, setShowDate] = useState(false)
  const [dateRange, setDateRange] = useState([
    {
      startDate: addDays(new Date("2022-01-01"), 0),
      endDate: addDays(new Date(), 1),
      key: "selection",
    },
  ])

  const minDate = dateRange[0].startDate.toISOString().split("T")[0]
  const maxDate = dateRange[0].endDate.toISOString().split("T")[0]

  const handlePageClick = data => {
    setNext(data.selected + 1)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/transactions?perPage=${perPage}&page=${next}&sort=${sort}&status=pending,failed,success&date_range=${minDate},${maxDate}`,
          options
        )
        const transactions = await response.json()
        console.log(transactions.data)
        setTransactions(transactions.data.transactions)
        setLoading(false)
        setLastPage(transactions.data.meta)
      } catch (error) {
        setLoading(false)
        toast.error(error.message)
      }
    }

    fetchData()
  }, [next, sort, perPage, minDate, maxDate])

  if (redirect) {
    return <Redirect to={redirect} />
  }

  // Search transactions
  const searchTransaction = async () => {
    console.log(search)
    try {
      const options = {
        method: "POST",
        headers: new Headers({
          Authorization: accessToken,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          search,
        }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/transactions/search`,
        options
      )
      const data = await response.json()
      console.log(data.data)
      setSearchResult(data["data"]["transactions"])
      if (data.status == "success") {
        console.log(data["message"])
      } else if (data.data === []) {
        setSearchResult([])
      } else if (data.status == "error") {
        toast.error(data["message"])
      }
    } catch (error) {
      // do what
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>All Transactions | Dilivva Admin</title>
        </MetaTags>
        <Row className="mx-1 my-4">
          <Col md={6}>
            <h4 className="dashboard-head">Transactions</h4>
          </Col>
          <Col md={6}>
            <input
              type="text"
              placeholder="Search transactions by name or reference"
              value={search}
              onChange={e => setSearch(e.target.value)}
              onKeyUp={() => searchTransaction()}
            />
            <div className="list-group rounded">
              {searchResult.map(search => {
                const { reference, uuid } = search
                return (
                  <button
                    type="button"
                    className="list-group-item list-group-item-action"
                    onClick={() => setRedirect("/transaction/" + uuid)}
                  >
                    {reference}
                  </button>
                )
              })}
            </div>
          </Col>
        </Row>
        <div className="container-fluid">
          <ToastContainer />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {loading ? (
                    <div className="container">
                      <div className="row">
                        <div className="col-md-2 mb-5 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                      </div>
                    </div>
                  ) : (
                    <div className="table-responsive">
                      <Row className="mt-2">
                        <Col md={9}>
                          <h4 className="title-header">All Transactions</h4>
                        </Col>
                        <Col md={1} xs={4}>
                          <select
                            className="form-select form-select-sm"
                            aria-label=".form-select-sm example"
                            value={perPage}
                            onChange={e => {
                              setLoading(true)
                              setPerPage(e.target.value)
                            }}
                          >
                            <option defaultValue>50</option>
                            <option defaultValue="25">25</option>
                            <option defaultValue="10">10</option>
                            <option defaultValue="5">5</option>
                          </select>
                        </Col>
                        <Col md={2} xs={8}>
                          <Row className="">
                            <Col>
                              <FaArrowUp
                                className="sort"
                                onClick={() => setSort("asc")}
                              />{" "}
                              <FaArrowDown
                                className="sort"
                                onClick={() => setSort("desc")}
                              />{" "}
                              Sort
                            </Col>
                            <Col
                              className="sort"
                              onClick={() => setShowDate(!showDate)}
                            >
                              <FaFilter /> Filter
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {showDate && (
                            <DateRangePicker
                              onChange={item => setDateRange([item.selection])}
                              showSelectionPreview={true}
                              moveRangeOnFirstSelection={false}
                              months={1}
                              ranges={dateRange}
                              direction="horizontal"
                            />
                          )}
                        </Col>
                      </Row>
                      <div className="table-responsive">
                        <Table className="table responsive table-hover mt-2 mb-0">
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Name</th>
                              <th>Reference</th>
                              <th>Type</th>
                              <th>Amount</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {transactions?.map((transaction, index) => {
                              const {
                                uuid,
                                amount,
                                created_at,
                                status,
                                type,
                                reference,
                                user,
                                partner,
                              } = transaction
                              return (
                                <tr
                                  key={uuid}
                                  onClick={() =>
                                    setRedirect("/transaction/" + uuid)
                                  }
                                  className="table-row"
                                >
                                  <td>{created_at}</td>
                                  <td>
                                    {user?.name} <br />
                                    <span className="text-green">
                                      {user?.email}
                                    </span>
                                    {partner?.name} <br />
                                    <span className="text-green">
                                      {partner?.email}
                                    </span>
                                  </td>
                                  <td>{reference}</td>
                                  <td
                                    style={{
                                      color:
                                        type === "credit"
                                          ? "#29CC97"
                                          : type === "debit"
                                          ? "#DC1515"
                                          : "#FEC400",
                                    }}
                                  >
                                    {type}
                                  </td>
                                  <td>₦{amount}</td>
                                  <td
                                    style={{
                                      color:
                                        status === "success"
                                          ? "#29CC97"
                                          : status === "pending"
                                          ? "#FEC400"
                                          : "#DC1515",
                                    }}
                                  >
                                    {status}
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </Table>
                        <div className="float-end mt-3">
                          <ReactPaginate
                            previousLabel={"Prev"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={Math.ceil(
                              lastPage?.total / lastPage?.per_page
                            )}
                            marginPagesDisplayed={4}
                            pageRangeDisplayed={6}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AllTransactions
