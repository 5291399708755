let baseURL = process.env.REACT_APP_LIVEURL
console.log(process.env.REACT_APP_LIVEURL)
console.log(process.env.REACT_APP_TESTURL)
console.log(process.env.REACT_APP_ENVIRONMENT)
console.log(process.env.REACT_APP_BASEURL)

if (process.env.REACT_APP_ENVIRONMENT_TEST == "development") {
 baseURL = process.env.REACT_APP_TESTURL
} else if (process.env.REACT_APP_ENVIRONMENT_LIVE == "production") {
  baseURL = process.env.REACT_APP_LIVEURL
}

console.log(baseURL)

export default baseURL
