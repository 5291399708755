import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Row, Col, Card, CardBody } from "reactstrap"

import accessToken from "../../helpers/jwt-token-access/accessToken"

import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Link } from "react-router-dom"
import { FaChevronLeft } from "react-icons/fa"
import baseURL from "../../utils"

const TierDetails = props => {
  const [profile, setProfile] = useState({
    name: "",
    description: "",
    limit: "",
  })
  const [loading, setLoading] = useState(true)
  const [disableButton, setDisableButton] = useState(false)
  const [showUpdate, setShowUpdate] = useState(false)

  // Tier Details
  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/tiers/${props?.match?.params?.id}`,
          options
        )
        const profile = await response.json()
        console.log(profile.data)
        setLoading(false)
        setProfile(profile.data)
      } catch (error) {
        console.log("error", error)
        toast.error(error.message)
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  // Update Tier
  const updateTier = async e => {
    e.preventDefault()
    setDisableButton(true)
    try {
      const options = {
        method: "PUT",
        headers: new Headers({
          Authorization: accessToken,
          "Content-Type": "application/json",
          accept: "application/json",
        }),
        body: JSON.stringify({
          name: profile.name,
          description: profile.description,
          limit: profile.limit.split(".")[0].replace(",", ""),
        }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/tiers/${props?.match?.params?.id}`,
        options
      )
      const data = await response.json()
      console.log(data)
      console.log(data.errors)

      if (data.errors) {
        console.log(data.errors)
        toast.error(data["message"])
        setDisableButton(false)
        setErrors(data.errors)
      } else {
        toast.success(data["message"])
        setTimeout(() => {
          window.location.reload()
        }, 3000)
      }
    } catch (error) {
      console.log(error)
      setDisableButton(false)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Tier Details | Dilivva Admin</title>
        </MetaTags>
        <div className="container-fluid">
          <ToastContainer draggable={false} />
          <div
            className="mb-4 d-flex justify-content-center align-items-center"
            style={{
              gap: "4px",
              padding: "7px 18px 7px 12px",
              width: "76px",
              borderRadius: "32px",
              border: "1px solid #F0F0F0",
              background: "#FFF",
              boxShadow: "1px 1px 4px 0px rgba(0, 0, 0, 0.04)",
              marginBottom: "8px",
            }}
          >
            <Link
              to="/tiers"
              className="text-black d-flex align-items-center"
            >
              <FaChevronLeft size={16} />
              <div
                style={{
                  color: "#000",
                  fontSize: "14px",
                  fontWeight: "600",
                  marginLeft: "4px",
                }}
              >
                Back
              </div>
            </Link>
          </div>
          <h5 className="mb-4">Tier Details</h5>
          {loading ? (
            <Row className="d-flex justify-content-center">
              <Col lg={11}>
                <Row>
                  <Col md={8}>
                    <Card className="profile-card">
                      <CardBody>
                        <Col md={12}>
                          <Row className="">
                            <Col md={10} className="mx-3">
                              <div className="col-md-8 skeleton skeleton-head"></div>
                              <div className="col-md-10 skeleton skeleton-p"></div>
                              <div className="col-md-7 skeleton skeleton-p"></div>
                              <div className="col-md-6 skeleton skeleton-p"></div>
                            </Col>
                          </Row>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col>
                    <Card>
                      <CardBody>
                        <Col md={12}>
                          <Row className="p-2">
                            <Col>
                              <div className="col-md-2 skeleton skeleton-p mb-4"></div>
                              <div className="col-md-7 skeleton skeleton-head p-1"></div>
                              <div className="col-md-8 skeleton skeleton-head"></div>
                            </Col>
                          </Row>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <Row className="d-flex justify-content-center">
              <Col lg={12}>
                <Row>
                  <Col lg={12}>
                    <Card className="profile-card">
                      <CardBody>
                        <h3 className="text-green mx-lg-5 pt-4">
                          Tier Details
                        </h3>
                        <div className="row">
                          <div className="col-md-12">
                            <form onSubmit={updateTier}>
                              <div className="row mt-2">
                                <h6 className="text-orange">Tier Details</h6>
                                <hr />
                                <div className="col-md-6 mb-4">
                                  <label
                                    htmlFor="centerName"
                                    className="fw-bold text-green"
                                  >
                                    Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Tier name"
                                    value={profile.name}
                                    onChange={e => {
                                      setShowUpdate(true)
                                      setProfile({
                                        ...profile,
                                        name: e.target.value,
                                      })
                                    }}
                                    required
                                  />
                                </div>
                                <div className="col-md-6 mb-4">
                                  <label
                                    htmlFor="description"
                                    className="fw-bold text-green"
                                  >
                                    Description
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter description"
                                    value={profile.description}
                                    onChange={e => {
                                      setShowUpdate(true)
                                      setProfile({
                                        ...profile,
                                        description: e.target.value,
                                      })
                                    }}
                                    required
                                  />
                                </div>
                                <div className="col-md-6 mb-4">
                                  <label
                                    htmlFor="CenterPhone"
                                    className="fw-bold text-green"
                                  >
                                    Limit
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter limit"
                                    value={profile.limit}
                                    onChange={e => {
                                      setShowUpdate(true)

                                      setProfile({
                                        ...profile,
                                        limit: e.target.value,
                                      })
                                    }}
                                    required
                                  />
                                </div>
                              </div>

                              {showUpdate && (
                                <div className="d-flex justify-content-end">
                                  <button
                                    type="submit"
                                    className="send mt-4"
                                    disabled={disableButton}
                                  >
                                    {disableButton
                                      ? "Updating Tier..."
                                      : "Update Tier"}
                                  </button>
                                </div>
                              )}
                            </form>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default TierDetails
