import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Table, Row, Col, Card, CardBody } from "reactstrap"

import "../senders/datatables.scss"
import accessToken from "../../helpers/jwt-token-access/accessToken"
import { Link, Redirect } from "react-router-dom"

import ReactPaginate from "react-paginate"
import { FaArrowDown, FaArrowUp, FaFilter } from "react-icons/fa"
import { IoFilter } from "react-icons/io5"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import emptyState from "../../assets/images/empty-state.png"

import { DateRangePicker } from "react-date-range"
import { addDays } from "date-fns"
import baseURL from "../../utils"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import { set } from "lodash"
import { MdRefresh } from "react-icons/md"

const AllOrders = () => {
  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(true)
  const [redirect, setRedirect] = useState("")
  const [search, setSearch] = useState("")
  const [orderCount, setOrderCount] = useState({})
  const [orderStatus, setOrderStatus] = useState(
    "pending,transit,confirmed,cancelled,accepted,transferred,completed"
  )
  const [next, setNext] = useState(1)
  const [lastPage, setLastPage] = useState({})
  const [sort, setSort] = useState("desc")
  const [searchResult, setSearchResult] = useState([])
  const [perPage, setPerPage] = useState(50)
  const [showDate, setShowDate] = useState(false)
  const [searchData, setSearchData] = useState([])
  const [show, setShow] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [buttonLoad, setButtonLoad] = useState(false)
  const [showSearch, setShowSearch] = useState(false)
  const [details, setDetails] = useState({
    originHub: null,
    destinationHub: null,
    currentLocation: null,
    variant: null,
    paymentStatus: null,
    packageName: null,
    orderId: null,
    reference: null,
  })

  const [dateRange, setDateRange] = useState([
    {
      startDate: addDays(new Date("2022-01-01"), 0),
      endDate: addDays(new Date(), 1),
      key: "selection",
    },
  ])

  const minDate = dateRange[0].startDate.toISOString().split("T")[0]
  const maxDate = dateRange[0].endDate.toISOString().split("T")[0]

  const handlePageClick = data => {
    setNext(data.selected + 1)
  }

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  // Order Count
  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/orders/status-counts?date_range=${minDate},${maxDate}`,
          options
        )
        const orderCount = await response.json()
        setOrderCount(orderCount.data)
      } catch (error) {
        setLoading(false)
        toast.error(error.message)
      }
    }

    fetchData()
  }, [minDate, maxDate])

  // Fetch all orders
  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/orders?perPage=${perPage}&page=${next}&sort=${sort}&status=${orderStatus}&date_range=${minDate},${maxDate}`,
          options
        )
        const orders = await response.json()
        console.log(orders.data.orders)
        setOrders(orders.data.orders)

        setLoading(false)
        setLastPage(orders.data.meta)
      } catch (error) {
        setLoading(false)
        toast.error(error.message)
      }
    }

    fetchData()
  }, [perPage, next, sort, orderStatus, minDate, maxDate])

  // Search orders
  const searchOrder = async () => {
    console.log(search)
    try {
      const options = {
        method: "POST",
        headers: new Headers({
          Authorization: accessToken,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          search,
        }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/orders/search`,
        options
      )
      const data = await response.json()
      console.log(data.data)
      setSearchResult(data["data"]["orders"])
      if (data.status == "success") {
        console.log(data["message"])
      } else if (data.status == "error") {
        toast.error(data["message"])
      }
    } catch (error) {
      // do what
    }
  }

  // Find orders
  const findOrders = async () => {
    setLoading(true)
    setButtonLoad(true)
    try {
      const options = {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: accessToken,
        }),
        body: JSON.stringify({
          data: [
            {
              param: "origin_hub",
              value: details.originHub,
            },
            {
              param: "destination_hub",
              value: details.destinationHub,
            },
            {
              param: "current_location",
              value: details.currentLocation,
            },
            {
              param: "variant",
              value: details.variant,
            },
            {
              param: "payment_status",
              value: details.paymentStatus,
            },
            {
              param: "package_name",
              value: details.packageName,
            },
            {
              param: "order_id",
              value: details.orderId,
            },
            {
              param: "reference",
              value: details.reference,
            },
          ],
        }),
      }
      const response = await fetch(
        `${baseURL}/api/v1/admin/orders/find`,
        options
      )
      const data = await response.json()
      console.log(data)
      if (data.errors) {
        toast.error(data["message"])
        setButtonLoad(false)
        setDisabled(false)
      } else {
        setSearchData(data["data"])
        setShowSearch(true)
        setButtonLoad(false)
        setLoading(false)
        handleClose()
      }
    } catch (error) {
      console.log(error)
      setSearchResult(false)
      setShowSearch(false)
    }
  }

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>All Orders | Dilivva Admin</title>
        </MetaTags>
        {/* <Row className="mx-1 my-4">
          <Col md={6}>
            <h4 className="dashboard-head">Packages</h4>
          </Col>
          <Col md={6}>
            <div className="d-flex justify-content-center align-items-center gap-2">
              <input
                type="text"
                placeholder="Search orders by name or reference"
                value={search}
                onChange={e => setSearch(e.target.value)}
                onKeyUp={() => searchOrder()}
              />
              <div class="dropdown">
                <button
                  className="d-flex justify-content-center align-items-center gap-2  btn shadow-none bg-transparent border pt-3 pb-2"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  <IoFilter />
                  <span>Filter By</span>
                </button>
                <div
                  class="dropdown-menu border-0"
                  style={{
                    position: "relative",
                    zIndex: "2000",
                    width: "160px",
                    padding: "8px 12px",
                    borderRadius: "10px",
                    boxShadow: "8px 12px 24px 0px rgba(90, 107, 133, 0.10)",
                  }}
                >
                  <div className="d-flex justify-content-center align-items-center px-3"></div>
                  <div
                    className="d-flex align-items-center gap-2 m-2"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    onClick={() => {
                      setSingleItem(price)
                      setCommodityId(price?.uuid)
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12px",
                        color: "#6D8198",
                        fontWeight: "500",
                        lineHeight: "18px",
                      }}
                    >
                      Edit
                    </span>
                  </div>
                  <div
                    className="d-flex align-items-center gap-2 m-2"
                    onClick={() => deletePrice(price?.uuid)}
                  >
                    <span
                      style={{
                        fontSize: "12px",
                        color: "#6D8198",
                        fontWeight: "500",
                        lineHeight: "18px",
                      }}
                    >
                      Delete
                    </span>
                  </div>
                </div>
              </div>
              <button
                className="connect-btn save-btn px-4"
                style={{
                  width: "fit-content",
                }}
              >
                Search
              </button>
            </div>
            <div className="list-group rounded">
              {searchResult.map(search => {
                const { reference, uuid } = search
                return (
                  <button
                    type="button"
                    className="list-group-item list-group-item-action"
                    onClick={() => setRedirect("/order/" + uuid)}
                  >
                    {reference}
                  </button>
                )
              })}
            </div>
          </Col>
        </Row> */}
        <Row className="my-3">
          <Col md={12} className="d-flex justify-content-end gap-2">
            <>
              <Button
                variant="connect-btn save-btn"
                onClick={handleShow}
                style={{
                  width: "fit-content",
                  fontSize: "12px",
                  whiteSpace: "nowrap",
                  padding: "8.352px 15.034px",
                  borderRadius: "10px",
                  height: "47px",
                  borderRadius: "6.7px",
                  border: "1px solid #F5F5F5",
                  backgroundColor: "#D97706",
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: "600",
                  lineHeight: "160%",
                }}
              >
                Search for orders
              </Button>

              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <h5
                    className="modal-title"
                    id="itemModalLabel"
                    style={{
                      color: "#0D172B",
                      fontSize: "15px",
                    }}
                  >
                    Search for Orders
                  </h5>
                </Modal.Header>
                <div className="modal-body bg-white">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12 mb-4">
                        <label className="connect-label">Origin Hub</label>
                        <input
                          type="text"
                          placeholder="Enter origin hub name"
                          value={details.originHub}
                          onChange={e => {
                            setDisabled(false)
                            setDetails({
                              ...details,
                              originHub: e.target.value,
                            })
                          }}
                        />
                        <small className="text-danger"></small>
                      </div>
                      <div className="col-md-12 mb-4">
                        <label className="connect-label">Destination Hub</label>
                        <input
                          type="text"
                          placeholder="Enter destination hub name"
                          value={details.destinationHub}
                          onChange={e => {
                            setDisabled(false)
                            setDetails({
                              ...details,
                              destinationHub: e.target.value,
                            })
                          }}
                        />
                        <small className="text-danger"></small>
                      </div>
                      <div className="col-md-12 mb-4">
                        <label className="connect-label">
                          Current location
                        </label>
                        <select
                          className="form-select form-select-sm shadow-none"
                          aria-label=".form-select-sm example"
                          value={details.currentLocation}
                          onChange={e => {
                            let value =
                              e.target.value === "" ? null : e.target.value
                            setDisabled(false)
                            setDetails({
                              ...details,
                              currentLocation: e.target.value,
                            })
                          }}
                        >
                          <option value="">Select current location</option>
                          <option value="hub">Hub</option>
                          <option value="sender">Sender</option>
                          <option value="recipient">Recipient</option>
                          <option value="sweeper">Sweeper</option>
                          <option value="center">Center</option>
                          <option value="partner">Partner</option>
                        </select>
                        <small className="text-danger"></small>
                      </div>
                      <div className="col-md-12 mb-4">
                        <label className="connect-label">Variant</label>
                        <select
                          className="form-select form-select-sm shadow-none"
                          aria-label=".form-select-sm example"
                          value={details.variant}
                          onChange={e => {
                            let value =
                              e.target.value === "" ? null : e.target.value
                            setDisabled(false)
                            setDetails({
                              ...details,
                              variant: value,
                            })
                          }}
                        >
                          <option value="">Select Variant</option>
                          <option value="HUB_TO_HUB">Hub to hub</option>
                          <option value="HUB_TO_DOOR">Hub to door</option>
                          <option value="DOOR_TO_HUB">Door to hub</option>
                          <option value="DOOR_TO_DOOR">Door to door</option>
                        </select>
                        <small className="text-danger"></small>
                      </div>
                      <div className="col-md-12 mb-4">
                        <label className="connect-label">Payment status</label>
                        <select
                          className="form-select form-select-sm shadow-none"
                          aria-label=".form-select-sm example"
                          value={details.paymentStatus}
                          onChange={e => {
                            let value =
                              e.target.value === "" ? null : e.target.value
                            setDisabled(false)
                            setDetails({
                              ...details,
                              paymentStatus: e.target.value,
                            })
                          }}
                        >
                          <option value="">Select Payment status</option>
                          <option value="completed">completed</option>
                          <option value="incompleted">incompleted</option>
                        </select>
                        <small className="text-danger"></small>
                      </div>
                      <div className="col-md-12 mb-4">
                        <label className="connect-label">Package Name</label>
                        <input
                          type="text"
                          placeholder="Enter package name"
                          value={details.packageName}
                          onChange={e => {
                            setDisabled(false)
                            setDetails({
                              ...details,
                              packageName: e.target.value,
                            })
                          }}
                        />
                        <small className="text-danger"></small>
                      </div>
                      <div className="col-md-12 mb-4">
                        <label className="connect-label">Order ID</label>
                        <input
                          type="text"
                          placeholder="Enter order ID"
                          value={details.orderId}
                          onChange={e => {
                            setDisabled(false)
                            setDetails({
                              ...details,
                              orderId: e.target.value,
                            })
                          }}
                        />
                        <small className="text-danger"></small>
                      </div>
                      <div className="col-md-12 mb-4">
                        <label className="connect-label">Reference</label>
                        <input
                          type="text"
                          placeholder="Enter package reference"
                          value={details.reference}
                          onChange={e => {
                            setDisabled(false)
                            setDetails({
                              ...details,
                              reference: e.target.value,
                            })
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="connect-footer">
                  <button
                    type="button"
                    className="connect-btn"
                    onClick={() => setShow(false)}
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    disabled={disabled}
                    className={`connect-btn save-btn ${
                      buttonLoad && "opacity-50"
                    }`}
                    onClick={findOrders}
                  >
                    {buttonLoad ? (
                      <div
                        className="spinner-border text-light mx-3"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      "Search"
                    )}
                  </button>
                </div>
              </Modal>
            </>
          </Col>
        </Row>
        <div className="container-fluid">
          <ToastContainer />
          {loading ? (
            <Row>
              <Col>
                <Card body className="card-height">
                  <Link to="/all-orders" className="text-center">
                    <div className="col skeleton skeleton-p text-center p-2 my-2"></div>
                    <h2 className="user-number">
                      <div className="col skeleton skeleton-head p-4 mx-4 mt-3"></div>
                    </h2>
                  </Link>
                </Card>
              </Col>
              <Col>
                <Card body className="card-height">
                  <Link to="/all-orders" className="text-center">
                    <div className="col skeleton skeleton-p text-center p-2 my-2"></div>
                    <h2 className="user-number">
                      <div className="col skeleton skeleton-head p-4 mx-4 mt-3 mb-2"></div>
                    </h2>
                  </Link>
                </Card>
              </Col>
              <Col>
                <Card body className="card-height">
                  <Link to="/all-orders" className="text-center">
                    <div className="col skeleton skeleton-p text-center p-2 my-2"></div>
                    <h2 className="user-number">
                      <div className="col skeleton skeleton-head p-4 mx-4 mt-3 mb-2"></div>
                    </h2>
                  </Link>
                </Card>
              </Col>
              <Col>
                <Card body className="card-height">
                  <Link to="/all-orders" className="text-center">
                    <div className="col skeleton skeleton-p text-center p-2 my-2"></div>
                    <h2 className="user-number">
                      <div className="col skeleton skeleton-head p-4 mx-4 mt-3 mb-2"></div>
                    </h2>
                  </Link>
                </Card>
              </Col>
              <Col>
                <Card body className="card-height">
                  <Link to="/all-orders" className="text-center">
                    <div className="col skeleton skeleton-p text-center p-2 my-2"></div>
                    <h2 className="user-number">
                      <div className="col skeleton skeleton-head p-4 mx-4 mt-3 mb-2"></div>
                    </h2>
                  </Link>
                </Card>
              </Col>
              <Col>
                <Card body className="card-height">
                  <Link to="/all-orders" className="text-center">
                    <div className="col skeleton skeleton-p text-center p-2 my-2"></div>
                    <h2 className="user-number">
                      <div className="col skeleton skeleton-head p-4 mx-4 mt-3 mb-2"></div>
                    </h2>
                  </Link>
                </Card>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col>
                <Card body className="card-height">
                  <div
                    className="text-center"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setLoading(true)
                      setOrderStatus(
                        "pending,transit,confirmed,cancelled,accepted,transferred,completed"
                      )
                    }}
                  >
                    <p className="head">Total</p>
                    <h2 className="user-number">{orderCount?.total}</h2>
                  </div>
                </Card>
              </Col>
              <Col>
                <Card body className="card-height">
                  <div
                    className="text-center"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setLoading(true)
                      setOrderStatus("completed")
                    }}
                  >
                    <p className="head text-green">Completed</p>
                    <h2 className="user-number">{orderCount?.completed}</h2>
                  </div>
                </Card>
              </Col>
              <Col>
                <Card body className="card-height">
                  <div
                    className="text-center"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setLoading(true)
                      setOrderStatus("pending")
                    }}
                  >
                    <p className="head text-orange">Pending</p>
                    <h2 className="user-number">{orderCount?.pending}</h2>
                  </div>
                </Card>
              </Col>
              <Col>
                <Card body className="card-height">
                  <div
                    className="text-center"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setLoading(true)
                      setOrderStatus("accepted")
                    }}
                  >
                    <p className="head" style={{ color: "#86F599" }}>
                      Accepted
                    </p>
                    <h2 className="user-number">{orderCount?.accepted}</h2>
                  </div>
                </Card>
              </Col>
              <Col>
                <Card body className="card-height">
                  <div
                    className="text-center"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setLoading(true)
                      setOrderStatus("transit")
                    }}
                  >
                    <p className="head" style={{ color: "#C9DA1D" }}>
                      Transit
                    </p>
                    <h2 className="user-number">{orderCount?.transit}</h2>
                  </div>
                </Card>
              </Col>
              <Col>
                <Card body className="card-height">
                  <div
                    className="text-center"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setLoading(true)
                      setOrderStatus("cancelled")
                    }}
                  >
                    <p className="head text-red">Cancelled</p>
                    <h2 className="user-number">{orderCount?.cancelled}</h2>
                  </div>
                </Card>
              </Col>
            </Row>
          )}

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {loading ? (
                    <div className="container">
                      <div className="row">
                        <div className="col-md-2 mb-5 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {showSearch ? (
                        <div>
                          {searchData?.length === 0 ? (
                            <div className="d-flex flex-column justify-content-center align-items-center">
                              <img
                                src={emptyState}
                                alt="empty state"
                                width={206}
                                height={206}
                              />
                              <p
                                style={{
                                  maxWidth: "185px",
                                  textAlign: "center",
                                  color: "#8D8D8D",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  lineHeight: "21px",
                                }}
                              >
                                No results found
                              </p>
                              <button
                                className="connect-btn save-btn"
                                style={{
                                  width: "fit-content",
                                  padding: "2px 10px",
                                  paddingRight: "14px",
                                }}
                                onClick={() => setShowSearch(false)}
                              >
                                <MdRefresh size={18} className="mx-2" />
                                Clear Search
                              </button>
                            </div>
                          ) : (
                            <div className="">
                              <button
                                className="connect-btn save-btn"
                                style={{
                                  width: "fit-content",
                                  padding: "2px 10px",
                                  paddingRight: "14px",
                                }}
                                onClick={() => setShowSearch(false)}
                              >
                                <MdRefresh size={18} className="mx-2" />
                                Clear Search
                              </button>
                              {searchData?.length !== 0 ? (
                                <div className="table-responsive">
                                  <Table className="table-hover mt-2 mb-0">
                                    <thead>
                                      <tr>
                                        <th>Sender</th>
                                        <th>Label</th>
                                        <th>Price</th>
                                        <th>Current Location</th>
                                        <th>Payment Status</th>
                                        <th>Created</th>
                                        <th>Delivery Status</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {searchData?.map(order => {
                                        const {
                                          uuid,
                                          price,
                                          payment_status,
                                          status,
                                          created_at,
                                          pickup_time,
                                          current_location_type,
                                          current_phase,
                                          user,
                                        } = order
                                        return (
                                          <tr
                                            key={uuid}
                                            onClick={() =>
                                              setRedirect("/order/" + uuid)
                                            }
                                            className="table-row"
                                          >
                                            <td>
                                              {user?.full_name} <br />
                                              <small className="text-green">
                                                {user?.email}
                                              </small>
                                            </td>
                                            <td
                                              style={{ whiteSpace: "nowrap" }}
                                            >
                                              <div>
                                                {order?.package?.images
                                                  .length !== 0 && (
                                                  <img
                                                    src={`${
                                                      process.env
                                                        .REACT_APP_IMAGE_URL
                                                    }/download/${encodeURIComponent(
                                                      order?.package?.images[0]
                                                    )}`}
                                                    className="img-fluid rounded"
                                                    width={40}
                                                    alt={order?.package?.label}
                                                  />
                                                )}
                                              </div>
                                              <small className="small">
                                                {order?.package?.label}
                                              </small>
                                            </td>
                                            <td>₦{price}</td>
                                            <td>
                                              {current_location_type ===
                                                "Sender" ||
                                              current_location_type ===
                                                "Recipient"
                                                ? current_location_type
                                                : current_phase ===
                                                    "center_to_center" ||
                                                  current_phase ===
                                                    "hub_to_sweeper" ||
                                                  current_phase ===
                                                    "partner_to_center" ||
                                                  current_phase ===
                                                    "sweeper_to_center"
                                                ? `Origin ${current_location_type}`
                                                : current_phase ===
                                                    "center_to_partner" ||
                                                  current_phase ===
                                                    "center_to_sweeper" ||
                                                  current_phase ===
                                                    "hub_to_recipient" ||
                                                  current_phase ===
                                                    "parner_to_recipient" ||
                                                  current_phase ===
                                                    "sweeper_to_hub"
                                                ? `Destination ${current_location_type}`
                                                : ""}
                                            </td>
                                            <td
                                              style={{
                                                color:
                                                  payment_status === "completed"
                                                    ? "#29CC97"
                                                    : payment_status ===
                                                      "cancelled"
                                                    ? "#DC1515"
                                                    : "#FEC400",
                                              }}
                                            >
                                              {payment_status}
                                            </td>
                                            <td>{created_at}</td>
                                            <td
                                              style={{
                                                color:
                                                  status === "completed"
                                                    ? "#29CC97"
                                                    : status === "cancelled"
                                                    ? "#DC1515"
                                                    : status === "accepted"
                                                    ? "#86F599"
                                                    : status === "transit"
                                                    ? "#C9DA1D"
                                                    : "#FEC400",
                                              }}
                                            >
                                              {status}
                                            </td>
                                          </tr>
                                        )
                                      })}
                                    </tbody>
                                  </Table>
                                </div>
                              ) : (
                                <div className="d-flex justify-content-center align-items-center my-4">
                                  <h3 className="fw-bold">
                                    Oops, No Connection Fee
                                  </h3>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="table-responsive">
                          <Row className="mt-2">
                            <div className="d-flex justify-content-end align-items-center gap-lg-4 w-full">
                              <Col md={1} xs={4}>
                                <select
                                  className="form-select form-select-sm"
                                  aria-label=".form-select-sm example"
                                  value={perPage}
                                  onChange={e => {
                                    setLoading(true)
                                    setPerPage(e.target.value)
                                  }}
                                >
                                  <option defaultValue>50</option>
                                  <option value="25">25</option>
                                  <option value="10">10</option>
                                  <option value="5">5</option>
                                </select>
                              </Col>
                              <Col md={2} xs={7}>
                                <Row className="">
                                  <Col>
                                    <FaArrowUp
                                      className="sort"
                                      onClick={() => setSort("asc")}
                                    />{" "}
                                    <FaArrowDown
                                      className="sort"
                                      onClick={() => setSort("desc")}
                                    />{" "}
                                    Sort
                                  </Col>
                                  <Col
                                    className="sort"
                                    onClick={() => setShowDate(!showDate)}
                                  >
                                    <FaFilter /> Filter
                                  </Col>
                                </Row>
                              </Col>
                            </div>
                          </Row>
                          <Row>
                            <Col>
                              {showDate && (
                                <DateRangePicker
                                  onChange={item =>
                                    setDateRange([item.selection])
                                  }
                                  showSelectionPreview={true}
                                  moveRangeOnFirstSelection={false}
                                  months={1}
                                  ranges={dateRange}
                                  direction="horizontal"
                                />
                              )}
                            </Col>
                          </Row>
                          <Table className="table-hover mt-2 mb-0">
                            <thead>
                              <tr>
                                <th>Sender</th>
                                <th>Label</th>
                                <th>Price</th>
                                <th>Current Location</th>
                                <th>Payment Status</th>
                                <th>Created</th>
                                <th>Delivery Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {orders?.map((order, index) => {
                                const {
                                  uuid,
                                  price,
                                  payment_status,
                                  status,
                                  created_at,
                                  pickup_time,
                                  current_location_type,
                                  current_phase,
                                  user,
                                } = order
                                return (
                                  <tr
                                    key={uuid}
                                    onClick={() =>
                                      setRedirect("/order/" + uuid)
                                    }
                                    className="table-row"
                                  >
                                    <td>
                                      {user?.full_name} <br />
                                      <small className="text-green">
                                        {user?.email}
                                      </small>
                                    </td>
                                    <td style={{ whiteSpace: "nowrap" }}>
                                      <div>
                                        {order?.package?.images.length !==
                                          0 && (
                                          <img
                                            src={`${
                                              process.env.REACT_APP_IMAGE_URL
                                            }/download/${encodeURIComponent(
                                              order?.package?.images[0]
                                            )}`}
                                            className="img-fluid rounded"
                                            width={40}
                                            alt={order?.package?.label}
                                          />
                                        )}
                                      </div>
                                      <small className="small">
                                        {order?.package?.label}
                                      </small>
                                    </td>
                                    <td>₦{price}</td>
                                    <td>
                                      {current_location_type === "Sender" ||
                                      current_location_type === "Recipient"
                                        ? current_location_type
                                        : current_phase ===
                                            "center_to_center" ||
                                          current_phase === "hub_to_sweeper" ||
                                          current_phase ===
                                            "partner_to_center" ||
                                          current_phase === "sweeper_to_center"
                                        ? `Origin ${current_location_type}`
                                        : current_phase ===
                                            "center_to_partner" ||
                                          current_phase ===
                                            "center_to_sweeper" ||
                                          current_phase ===
                                            "hub_to_recipient" ||
                                          current_phase ===
                                            "parner_to_recipient" ||
                                          current_phase === "sweeper_to_hub"
                                        ? `Destination ${current_location_type}`
                                        : ""}
                                    </td>
                                    <td
                                      style={{
                                        color:
                                          payment_status === "completed"
                                            ? "#29CC97"
                                            : payment_status === "cancelled"
                                            ? "#DC1515"
                                            : "#FEC400",
                                      }}
                                    >
                                      {payment_status}
                                    </td>
                                    <td>{created_at}</td>
                                    <td
                                      style={{
                                        color:
                                          status === "completed"
                                            ? "#29CC97"
                                            : status === "cancelled"
                                            ? "#DC1515"
                                            : status === "accepted"
                                            ? "#86F599"
                                            : status === "transit"
                                            ? "#C9DA1D"
                                            : "#FEC400",
                                      }}
                                    >
                                      {status}
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </Table>
                          <div className="float-end mt-3">
                            <ReactPaginate
                              previousLabel={"Prev"}
                              nextLabel={"Next"}
                              breakLabel={"..."}
                              pageCount={Math.ceil(
                                lastPage?.total / lastPage?.per_page
                              )}
                              marginPagesDisplayed={4}
                              pageRangeDisplayed={6}
                              onPageChange={handlePageClick}
                              containerClassName={"pagination"}
                              pageClassName={"page-item"}
                              pageLinkClassName={"page-link"}
                              previousClassName={"page-item"}
                              previousLinkClassName={"page-link"}
                              nextClassName={"page-item"}
                              nextLinkClassName={"page-link"}
                              breakClassName={"page-item"}
                              breakLinkClassName={"page-link"}
                              activeClassName={"active"}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AllOrders
