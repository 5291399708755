import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Table, Row, Col, Card, CardBody } from "reactstrap"

import "../senders/datatables.scss"
import accessToken from "../../helpers/jwt-token-access/accessToken"

import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import baseURL from "../../utils"
import { BsThreeDots } from "react-icons/bs"
import { HiOutlineTrash } from "react-icons/hi2"

const SuggestedLocations = () => {
  const [suggestedLocations, setSuggestedLocations] = useState([])
  const [loading, setLoading] = useState(true)

  // Fetch Suggested Locations
  useEffect(() => {
    const fetchSuggestedLocations = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(
          `${baseURL}/api/v1/admin/suggested-locations`,
          options
        )
        const suggestedLocations = await response.json()
        console.log(suggestedLocations.data)
        setSuggestedLocations(suggestedLocations.data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        toast.error(error.message)
      }
    }

    fetchSuggestedLocations()
  }, [])

  // Delete suggested location
  const deleteSuggestedLocation = async id => {
    if (confirm("Are you sure you want to delete this suggested location?")) {
      const options = {
        method: "DELETE",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: accessToken,
        }),
      }
      fetch(`${baseURL}/api/v1/admin/suggested-locations/${id}`, options)
        .then(data => data.json())
        .then(response => {
          console.log(response)
          if (response.errors) {
            toast.error(response.message)
          } else {
            toast.success(response.message)
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          }
        })
        .catch(error => {
          toast.error(error.message)
        })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Suggested Locations | Dilivva Admin</title>
        </MetaTags>
        <Row className="mx-1 my-4">
          <Col md={6}>
            <h4 className="dashboard-head">Suggested Locations</h4>
          </Col>
        </Row>
        <div className="container-fluid">
          <ToastContainer />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {loading ? (
                    <div className="container">
                      <div className="row">
                        <div className="col-md-2 mb-5 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                        <div className="col-md-12 skeleton skeleton-search"></div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="table-responsive">
                        <Table className="table table-hover mt-2 mb-0">
                          <thead>
                            <tr>
                              <th>City</th>
                              <th>State</th>
                              <th>Region</th>
                              <th>Count</th>
                              <th>Created</th>
                              <th>
                                <BsThreeDots size={24} />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {suggestedLocations?.map(
                              ({ city, state, created_at, id, count }) => (
                                <tr key={id}>
                                  <td>{city}</td>

                                  <td>{state?.name}</td>
                                  <td>{state?.region?.name}</td>
                                  <td>{count}</td>
                                  <td>{created_at}</td>
                                  <td>
                                    <div
                                      className="d-flex align-items-center gap-2 m-2"
                                      onClick={() =>
                                        deleteSuggestedLocation(id)
                                      }
                                    >
                                      <HiOutlineTrash
                                        size={18}
                                        style={{ color: "#718096" }}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default SuggestedLocations
